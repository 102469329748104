@import url("variables.less");
@import url("mixins.less");
// Changes to CSS chould be made in....
/* import stylesheets and hide from IE/MAC \*/
@import url("reset.less");

/*
jquery-ui-1.13.2.custom.min.css contians css that are defaulted to jquery specific only.
*/
@import url("jquery-ui-1.13.2.custom.min.less");
/*
ui.jqgrid.css contains css that are defaulted to jqgrid specific only.
*/
@import url("ui.jqgrid.less");
/*
ui.jqgrid-override.less contains css that are ui.jqgrid cssification only.
Any more overrides for jqgrid if required should be added to ui.jqgrid-override.less
*/
@import url("ui.jqgrid-override.less");
/*
yui-overrides.css contains css that are yui-customized cssification only.
Any more overrides for yui if required should be added to yui-overrides.css
*/
@import url("yui-overrides.less");
/*end import/hide*/

@import url("variables-shared.less");
@import url("pssAutoComplete.less");
@import url("components/pssStepsWizard.less");
@import url("components/careertech.less");
@import url("components/fitness.less");
@import url("components/counselor.less");
@import url("components/gradplanner.less");
@import url("components/gradPlanProgress.less");
@import url("components/healthDashboard.less");
@import url("components/healthMonitoringEvent.less");
@import url("components/healthScreenings.less");
@import url("components/scheduledEvent.less");
@import url("components/incidentAttendance.less");
@import url("components/incidentTemplate.less");
@import url("components/healthPlan.less");
@import url("components/codesets.less");
@import url("components/template.less");
@import url("components/asset.less");
@import url("components/assetInventory.less");
@import url("components/multisubmitform.less");
@import url("pds-adaptation/pds.less");
@import url("new_look.less");
@import url("components/incidentWidget.less");
@import url("components/districtPush.less");
@import url("components/changehistory.less");
@import url("components/incidentDetailed.less");
@import url("components/cicoAttendance.less");
@import url("components/healthOfficeVisit.less");
@import url("components/standards.less");
@import url("components/gradeCalculationFormulas.less");
@import url("components/admin-seating-chart.less");
@import url("components/pagepermissions.less");
@import url("pds-adaptation/pds-override.less");
@import url("components/regionalCalendarToggle.less");
@import url("components/updateCourseStaff.less");
@import url("components/advancedreports.less");


/* Jquery UI 1.13.2 STARTS */

//zoom in zoom out in teachers portal seating chart
.ui-icon{
    margin-top : 0px;
    vertical-align: inherit;
}

.ui-button {
	padding: 0;

}

.ui-dialog-buttonset button.ui-button:not(.ui-button-text-only){
    padding: 0.05em 0.4em ;
}

.ui-accordion .ui-accordion-header .ui-accordion-header-icon{
    position: absolute;
    left: .5em;
    margin-top: -8px;
}
.ui-accordion {
    .ui-accordion-header {
      .ui-accordion-header-icon {
        top: 50% !important;
      }
    }
}

.ui-button span.ui-button-text {
    padding: 0.05em 0.4em ;
}

.ui-widget-content li div.ui-menu-item-wrapper.ui-state-active {
    background-image: none;
    background-color: rgba(178, 208, 233, 0.5);
    border: none;
}

.ui-controlgroup{
    margin-right: 4px;
}

.ui-button-icon-only{
    width: 2.4em;
}


/* Jquery UI 1.13.2 Ends */


/*Helper Pseudo Class - Only needed for containing floating elements*/
.group:after,.ui-helper-clearfix:after,#content-main:after {
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    border: none;
}

.qa-helper-img {
    display: none;
}
.anchor_unclickable{
    pointer-events: none;
    cursor: default;
}
/* HTML Tags */
html,body {
    height: 100%;
}

body {
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5em;
    color: @text-color;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #FFF;
    //background-image: url("../img/content-back.svg");

  background-image: -webkit-gradient(
          linear,
          left top,
          right top,
          color-stop(0, #F4F7FA),
          color-stop(200px, #F4F7FA),
          color-stop(201px, #CCCCCC),
          color-stop(202px, #FFFFFF)
  );
  background-image: -o-linear-gradient(right, #F4F7FA 0, #F4F7FA 200px, #CCCCCC 201px, #FFFFFF 202px);
  background-image: -moz-linear-gradient(right, #F4F7FA 0, #F4F7FA 200px, #CCCCCC 201px, #FFFFFF 202px);
  background-image: -webkit-linear-gradient(right, #F4F7FA 0, #F4F7FA 200px, #CCCCCC 201px, #FFFFFF 202px);
  background-image: -ms-linear-gradient(right, #F4F7FA 0, #F4F7FA 200px, #CCCCCC 201px, #FFFFFF 202px);
  background-image: linear-gradient(to right, #F4F7FA 0, #F4F7FA 200px, #CCCCCC 201px, #FFFFFF 202px);
  background-repeat: repeat-y;
  background-size: 202px 10px;
  background-position: -20px top;
}

[dir=rtl] body {
  background-image: -o-linear-gradient(left, #F4F7FA 0, #F4F7FA 200px, #CCCCCC 201px, #FFFFFF 202px);
  background-image: -moz-linear-gradient(left, #F4F7FA 0, #F4F7FA 200px, #CCCCCC 201px, #FFFFFF 202px);
  background-image: -webkit-linear-gradient(left, #F4F7FA 0, #F4F7FA 200px, #CCCCCC 201px, #FFFFFF 202px);
  background-image: -ms-linear-gradient(left, #F4F7FA 0, #F4F7FA 200px, #CCCCCC 201px, #FFFFFF 202px);
  background-image: linear-gradient(to left, #F4F7FA 0, #F4F7FA 200px, #CCCCCC 201px, #FFFFFF 202px);
  background-position: right -20px top;
  background-attachment: fixed;
}

:focus {
  outline-color: @outline-color;
  outline-width: 1px;
  outline-style: solid;
}
h1 {
  font-size: 180%;
  font-weight: bold;
  margin: 25px 20px;
  line-height: 1.5em;
}

h2 {
  font-size: 128%;
  margin: 20px 20px 10px;
}

h3 {
  font-size: 114%;
  margin: 15px 0 10px 20px;
}

h4 {
  font-size: 100%;
  margin: 10px 0 10px 20px;
}

h5 {
  font-size: 85%;
  margin: 10px 0 10px 20px;
}
h2,
h3,
h4
,h5 {
  border-bottom: 1px solid @border-color;
  line-height: 1.5em;
  font-weight: bold;
  position: relative;
  padding-bottom: 5px;
}


h1 span {float: right;}
h2 span {float: right;}
h3 span {float: right;}
h4 span {float: right;}
h5 span {float: right;}

h1, h2, h3, h4, h5 {
  span {
      font-weight: normal;
      text-align: right;
      margin-right: 10px;
      label,
      select {
        vertical-align: middle;
      }
  }
  strong {
    font-weight: normal;
    font-style: normal;
  }
  small {
    font-size:100%;
    font-weight:normal;
    color:#666;
  }
  button, .button, select, input {
    font-size: 14px;
  }
  & > button, & > .button {
    bottom: 0;
    position: absolute;
    right: 0;
  }

  &.toggle > button, &.toggle > .button {
    bottom: -1px;
    right: 4px;
  }
  &.feedback-alert {
    background-color: #fcf8e3;
    max-height: 15em;
    overflow: auto;
    color: #b25900;
  }
  &.template-section{
    margin:20px 0px 10px 0px;
  }
  &.drawer-section {
    margin: 0 20px 20px;
    padding: 2px 4px 2px 30px;
  }
  /* expand collapse */
  &.expanded {
    border: @border-style;
    border-radius: @border-radius-small;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 0;
    background-image: url("../img/arrow-down-blk.svg");
    background-repeat: no-repeat;
    background-position: 0.5em 0.5em;
    padding-left: 1.5em;
    cursor: pointer;
    + * {
      border: @border-style;
      border-top: none;
      margin-top: 0;
      padding-top: 1px;
      border-radius: @border-radius-small;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      > *:first-child {
        margin-top: 10px;
      }
      &:last-child {
        margin-bottom: 10px;
      }
    }
    + div {
      margin-right: 20px;
      margin-left: 20px;
        margin-bottom: 20px;
    }
    .box-round & + div {
      margin-right: 10px;
      margin-left: 10px;
        margin-bottom: 10px;
    }
    + table.grid,
    + table.grid th,
    + table.grid td.th {
      border-top: none;
    }
  }
  [dir=rtl] &.expanded {
    background-position: right 0.5em top 0.5em;
  }
  &.notoggle,
  &.collapsed {
    border: @border-style;
    border-radius: @border-radius-small;
    padding-left: 1.5em;
    margin-bottom: 10px;
    //padding-bottom: 0; breaks things
  }

  &.collapsed {
    background-image: url("../img/arrow-right-blk.svg");
    background-repeat: no-repeat;
    background-position: 0.5em 0.5em;
    cursor: pointer;
  }
  [dir=rtl] &.collapsed {
    background-position: right 0.5em top 0.5em;
    background-image: url(../img/arrow-left-blk.svg);
  }
  &.colored-background {
    &.collapsed {
      background-color: #ededed;
    }
    &.expanded {
      background-color: #a3bfcc;
    }
  }
  //same style as a collapsed toggle, but without the arrow or behavior
  &.notoggle {
    cursor: default;
  }

}

.button-row, .button-row:nth-child(odd) {
    h1>&, h2>&, h3>&, h4>&, h5>& {
        &, .box-round & {
            right: 0px;
            position: absolute;
            margin-top: 0px;
            margin-bottom: 0px;
            display: inline;
        }
    }
}

h4.lite {
  margin-top: 10px;
    padding-bottom: 0;
  &.collapsed.caution {
    background-color: @alert-warn-background;
    &::before{
      background: @alert-warn-background url("../img/icon-caution.svg") no-repeat scroll 0 0;
      content:'';
      padding-right:1.5em;
    }
  }
  span {
    float: none;
    text-align: left;
    &.file-data-name {
      width: 75%;
    }
  }
}
h1 span {
    font-size: 80%;
}

span.nav-teacher {
    float: right;
    margin: 32px 30px;
}

label,b {
    font-weight: bold;
}

caption {
    font-size: 80%;
}

a {
    color: @link-color;
    text-decoration: none;
    cursor: pointer;
 }

a:hover {
    color: @link-color-hover;
}

i {
    font-style: italic;
}

p {
    margin-top: 0;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-left: 20px;
}
/*List changes verify*/
ul {
    margin-top: 0;
    margin-right: 35px;
    margin-bottom: 10px;
    margin-left: 35px;
}

ol {
    margin-top: 0;
    margin-right: 35px;
    margin-bottom: 10px;
    margin-left: 35px;
}

ul > li {
    list-style-type: disc;
}
ul ul > li {
    list-style-type: circle;
}

pds-app-nav ul > li {
    list-style-type: none;
}
pds-app-nav ul ul > li {
    list-style-type: none;
}

ol > li {
    list-style-type: decimal;
}
ol ol > li {
    list-style-type: lower-alpha;
}
pre {
    white-space: pre-wrap;
    font-family: "Courier New", Courier, monospace;
    line-height: 21px;
    word-wrap: break-word;
}

code {
    border: @border-style;
    overflow: auto;
    /*--If the Code exceeds the width, a scrolling is available--*/
    overflow-Y: hidden; /*--Hides vertical scroll created by IE--*/
    margin: 20px; /*--Left Margin--*/
    padding: 10px;
    display: block;
    line-height: 1.5em;
  /*Blue background*/
  background-color: @lightest-blue;
  /*Then create the white semi transparent overlay*/
  background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(.5, rgba(255, 255, 255, .2)), color-stop(.5, transparent), to(transparent));
  background-image: -moz-linear-gradient(rgba(255, 255, 255, .6) 50%, transparent 50%, transparent);
  background-image: -o-linear-gradient(rgba(255, 255, 255, .6) 50%, transparent 50%, transparent);
  background-image: linear-gradient(rgba(255, 255, 255, .6) 50%, transparent 50%, transparent);
  /*Adjust the background size to fit your line height *NOTE* generally this should be twice your line-height to fit correctly*/
  -webkit-background-size: 30px 3em;
  -moz-background-size: 30px 3em;
  background-size: 30px 3em;
  background-position: 0 2.2em;
  .box-round & {
    margin: 0 10px 10px 10px;
  }
}

form {
    margin: 0;
    padding: 0;
}

del {
    color: #fff;
    background-color: #990033;
}

ins {
    color: #fff;
    background-color: #009933;
}

dl {
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
  .box-round & {
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  dt {
    font-weight: bold;
    font-size: 120%;
  }

  dd {
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
    margin-left: 10px;
    border-left: 4px solid #CCC;
    padding-left: 5px;
  }
  &.faq {
    margin-top: 20px;
    dt {
      padding: 0 1.7em;
      cursor: pointer;
      color: @link-color;
      &:hover {
        background-color: #E7E7E7;
      }
      &.expanded {
        background-image: url("../img/arrow-down-blk.svg");
        background-position: 0.8em 0.25em;
        background-repeat: no-repeat;
      }
      &.collapsed {
        background-image: url("../img/arrow-right-blk.svg");
        background-position: 0.8em 0.25em;
        background-repeat: no-repeat;
        margin-bottom: 20px;
      }
    }
    dd {
      max-width: 600px;
    }
  }
}

hr {
    height: 0;
    border: none;
    border-bottom: 1px dotted  @border-color;
}

.input-xs {
    width: 100px;
}
.input-sm {
    width: 150px;
}
.input-md {
    width: 200px;
}
.input-lg {
    width: 250px;
}
.input-xl {
    width: 300px;
}
.input-xxl {
    width: 350px;
}
.input-xxxl {
    width: 400px;
}
/* end HTML Tags */
/*Basic Structure of page*/
.frames body,
body.report,
body.popUp,
body#alert,
body.powerlunch,
body#addressValidation {
    background-image: none;
}


body.popUp #content,body.popUp #content-main {
    background: none;
    margin: 0;
    box-shadow: none;
}

body.report #content-main {
    clear: both;
    margin-left: 0;
}

/*content divisions*/
#container {
    width: 100%;
}

[dir=rtl] .psteacher #container {
    height: 100%;
    overflow-y: auto;
}

#container-frame {
    margin: 0 auto 15px;
    width: 100%;
    height: 750px;
    display: table;
    background-color: #FFF;
}

body.frameMenu {
    background: #FFF url("../img/content-back.svg") repeat-y -42px top;
  background-image: -webkit-gradient(
          linear,
          left top,
          right top,
          color-stop(0, #F4F7FA),
          color-stop(207px, #F4F7FA),
          color-stop(208px, #CCCCCC),
          color-stop(209px, #FFFFFF)
  );
  background-image: -o-linear-gradient(right, #F4F7FA 0, #F4F7FA 207px, #CCCCCC 208px, #FFFFFF 209px);
  background-image: -moz-linear-gradient(right, #F4F7FA 0, #F4F7FA 207px, #CCCCCC 208px, #FFFFFF 209px);
  background-image: -webkit-linear-gradient(right, #F4F7FA 0, #F4F7FA 207px, #CCCCCC 208px, #FFFFFF 209px);
  background-image: -ms-linear-gradient(right, #F4F7FA 0, #F4F7FA 207px, #CCCCCC 208px, #FFFFFF 209px);
  background-image: linear-gradient(to right, #F4F7FA 0, #F4F7FA 207px, #CCCCCC 208px, #FFFFFF 209px);
  background-repeat: repeat-y;
  background-size: 208px 10px;
  background-position: 0 top;
}

#container-frame-menu {
    .flex-display();
      .flex-direction(column);
    width: 205px;
  height: 100%;
}

#header {
    position: relative;
    color: inherit;
    height: 70px;
    background-color: #FFF;
}

#branding-powerschool {
  background-repeat: no-repeat;
  background-position: 26px center;
  background-size: auto 45px;
  height: 70px;
  line-height: 40px;
  background-image: url("../img/powerschool.svg");

  a {
  display: block;
      width: 330px;
      height: 70px;
  }
  body#alert & {
      /* alert pages */
      margin: 0 20px;
  }
}
[dir=rtl] #branding-powerschool {
  background-position: right;

}

[dir=rtl] body .new #branding-powerschool {
  direction: ltr;
  margin-inline-end: 20px;
}

[dir=rtl] body .new #branding-powerschool pds-icon {
  margin-inline-end: 4px;
  margin-inline-start: 10px;
}

body.uiContMax {
  & #usercontext-bar {
    background-image: url("../img/powerschool-white.svg");
    background-position: 12px center;
    background-repeat: no-repeat;
    background-size: auto 1.8em;
  }
}

[dir=rtl] body.uiContMax {
  & #usercontext-bar {
    background-image: url("../img/powerschool-white.svg");
        background-position: right;
  }
}

body.uiContMax #linkBrandingHome {
    display: inline-block;
    width: 180px;
    height: 2.5em;
    vertical-align: top;
}
#linkBrandingHome {
    display: none;
}
/*user context area*/
#currentscope {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0 10px 3px 10px;
    font-weight: bold;
    display: table-cell;
}

[dir=rtl] #currentscope {
    display: flex;
}

#currentscope  a {
    font-weight: bold;
    text-align: left;
    display: inline;
    width: auto;
}

#currentscope span#schoolText {
    padding-top: 0;
    padding-right: 20px;
    padding-bottom: 0;
    padding-left: 5px;
    font-weight: normal;
    display: inline-block;
}

#currentscope span#termText {
    padding: 0 0 0 5px;
    font-weight: normal;
    display: inline-block;
}

#currentscope span a {
    margin: 0;
}

#termContextDialog select {

}

#schoolContextDialog select {
    width: 98%;
}

#schoolSearchSpan {
	display: inline-flex;
	padding-right: 20px;
	height: 26px;
}

#changeTermSpan {
	display: inline-flex;
	height: 26px;
}

.schoolSearchInput {
	width: 150px;
}

#schoolSearchField {
    padding: 0 0 0 5px;
	font-weight: normal;
	width: 150px;
}

#schoolSearchField div.angucomplete-title {
	font-size: 14px;
	font-weight: bold;
}

#schoolSearchField div.angucomplete-description {
	font-size: 11px;
}

#schoolSearchField div.angucomplete-dropdown {
	width: 330px;
	margin-left: -172px;
	max-height: 680px;
}

#schoolSearchField2 input {
	padding: 0 3px 3px 3px;
}

#usercontext-bar {
    width: 100%;
    background: @breadcrumb-background;
    color: #FFF;
    position: relative;
    display: block;
    min-height: 2.5em;
    line-height: 2.5em;
    a {
        color: @breadcrumb-link-color;
    }
    a:hover {
        color: @breadcrumb-link-hover-color;
    }
}

#tools {
    padding: 5px 0 0 0;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
}

#tools a {
    display: inline-block;
    margin: 0 10px;
}

#tools li {
    list-style-type: none;
    display: inline-block;
    vertical-align: text-top;
      @import url("helpMenu.less");
}

#tools li span#firstlast {
    margin-right: 10px;
}

#tools li span[aria-hidden="true"]:before {
    content: " | ";
    color: #CCC;
}

#tools li span#firstlast:before {
    content: "";
}

#tools2 {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    right: 5px;
}

#tools2 li {
    text-indent: 0;
    display: inline-block;
}

#tools2 li a {
    //border-radius: 4px;
    padding: 0 5px;
    display: inline-block;
    width: 2em;
    //border: 1px solid rgba(255, 255, 255, 0.3);
}

#tools2 li a:hover {
    background-color: @breadcrumb-highlight
}

#tools2 .search a {
    display: inline-block;
    background: url("../img/icon-search.png") no-repeat center center;
}

#student_detail_header {
    border-bottom: none;
    font-size: 100%;
    font-weight: normal;
}

#student_detail_header a img {
	vertical-align: middle;
}

[dir=rtl] #student_detail_header #studentNavControls,
[dir=rtl] #staff_detail_header #staffNavControls {
  .navPrevNext #navNext pds-icon, .navPrevNext #navPrev pds-icon {
    transform: scale(-1, -1);
  }
}
[dir=rtl] .back-icon {
    transform: scaleX(-1);
}
.student_detail_icon img{
     height: 23px;
     width: 23px;

}

.counselor {
    position: relative;
    top: 5px;
    left: 5px;
    > pds-icon > svg {
      height: 18px;
      width: 18px;
      fill: #0066a5;
    }
}

.student_details pds-icon {
    fill: #0075db;
    vertical-align: middle;
    > svg {
        width: 18px;
        height: 18px;
    }
}


.icon-btn-print a,#btnPrint span {
    background-image: url("../img/icon-print.svg");
    background-repeat: no-repeat;
    background-position: center center;
}

#btnPrint span {
    width: 2em;
    display: inline-block;
}

.icon-btn-download a,#btnDownload span {
    background: url("../img/icon-download.svg") no-repeat center center;
}

#icon-btn-translate a {
    background: url("../img/icon-translate-wht.png") no-repeat center center;
}

.icon-btn-mydata a {
    background: url("../img/mydata-sml.png") no-repeat center center;
}

.icon-btn-pluginLinkList a {
    background: url("../img/icon-launch.svg") no-repeat center center;
}

#btnNotifications {
    background: url("../img/icon-notifications.svg") no-repeat center center;
    position: relative;
    color: #000;
}

#btnNotifications span, #messengerLink span, .button-with-badge span {
    background-color: #C84C59;
    line-height: 1em;
    font-size: 80%;
    color: #FFFFFF;
    position: absolute;
    padding: 2px 4px;
    top: 2px;
    right: 2px;
    border-radius: 10px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

#icon-btn-report.active {
    position: relative;
}

#icon-btn-report.active a {
    display: block;
    background: url("../img/icon-reports-active.svg") no-repeat center
        center;
    padding: 0 5px;
    width: 2em;
}

#icon-btn-report.active a:after {
    content: url("../img/spinner.gif");
    top: 0;
    position: absolute;
    left: 14px;
    display: block;
      width: 10px;
     height: 10px;
}

#icon-btn-report.inactive a {
    background: url("../img/icon-reports-inactive.svg") no-repeat center
        center;
    padding: 0 5px;
    width: 2em;
}

#students-list {
    list-style: none;
    margin: 0;
    display: inline-block;
    li {
    display: inline-block;
        a {
            padding: 0 10px;
    display: inline-block;
}
        &.selected a,
        &:hover a {
            color: #FFF;
            background: @breadcrumb-highlight;
}
}
}


#breadcrumbs {
    width: -webkit-calc(~"100% - 211px");
    width: calc(~"100% - 211px");
    margin-left: 10px;
    display: inline-block;
    line-height: 1.5em;
    margin-top: 5px;
    box-sizing: unset;
    -webkit-box-sizing: unset;
    .uiContMax & {
        width: -webkit-calc(~"100% - 211px - 180px");
        width: calc(~"100% - 211px - 180px");
    }
}

#userName {
    text-align: right;
}

#userName span {
    font-weight: bold;
}

#footer {
    margin-left: 200px;
    font-size: 80%;
}

#footer p {
    color: @med-grey;
}

#legal {
    display: none;
}

#reportIssueLink {
    float: right;
    cursor: pointer;
}

#typeChoices {
    width: 60%;
    position: relative;
    margin: 0;
    padding: 0;
}

#content-lunch {
    padding: 1px 0 20px;
    position: relative;
}

#content-lunch .box-round {
    width: 985px;
    margin: 0 auto;
    vertical-align: top;
}

#content-lunch .lunchCenter {
    text-align: center;
}

#content-main {
    padding: 1px 0 10px !important;
    position: relative;
    vertical-align: top;
    margin-left: 181px;
    display: block;
}

.calc #content-main {
    width: -webkit-calc(~"100% - 202px");
    width: calc(~"100% - 202px");
    display: inline-block;
}

#content-main-no-left-margin {
    /* used in decorators only - do-not-use */
    padding: 1px 0 10px;
    position: relative;
}

#container-frame #content {
    background-image: none;
}

#container-frame #content-main {
    min-height: 400px;
    width: 100%;
    &.autoScroll {
        @media screen {
            overflow-y: auto;
        }
        @media print {
            overflow-y: visible;
        }
        min-height: unset;
    }
}

#container-frame #content-main,#content-lunch #content-main {
    margin-left: 0;
}
/*navigation styles*/
#nav-main {
    width: 180px;
    padding-bottom: 5px;
    padding-left: 0;
    position: relative;
    word-wrap: break-word;
    vertical-align: top;
    float: left;
    display: block;
}

#nav-main-frame {
    padding-bottom: 10px;
    display: block;
    word-wrap: break-word;
      overflow: auto;
}

#nav-main h2 {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 5px;
    margin-top: 16px;
    width: calc(~"100% - 20px");
    border-bottom: 1px dotted  @border-color;
    font-size: 114%;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 5px;
}

#nav-main-frame h3 {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 0;
    width: -webkit-calc(~"100% - 20px");
    width: calc(~"100% - 20px");
    border-bottom: 1px dotted  @border-color;
}

#nav-main-menu.ui-accordion,
#nav-main-frame.ui-accordion {
    border: none;
}

#nav-main-menu.ui-accordion h3:first-of-type,
#nav-main-frame.ui-accordion h3:first-of-type {
    margin-top: 5px;
}

#nav-main-menu.ui-accordion h3:last-of-type,
#nav-main-frame.ui-accordion h3:last-of-type,
#nav-main-menu.ui-accordion ul:last-of-type,
#nav-main-frame.ui-accordion ul:last-of-type {
    border-bottom: 1px solid #CCC;
}

#nav-main-menu.ui-accordion h3,
#nav-main-frame.ui-accordion h3 {
    margin-top: 0;
    margin-left: 5px;
    margin-right: 0;
    width: -webkit-calc(~"100% - 35px");
    width: calc(~"100% - 35px");
    border: @border-style;
    border-bottom: none;
}

#nav-main-menu.ui-accordion h3.ui-state-active,
#nav-main-frame.ui-accordion h3.ui-state-active    {
    background-color: #B2D0E9;
    border-bottom: none;
}

#nav-main h3 span,
#nav-main-frame h3 span {
    float: right;
    color: #666;
    padding-right: 5px;
    font-size: 80%;
}

.navABRList {
    padding: 0 5px;
}

#nav-main ul,
#nav-main-frame ul {
    padding: 0;
    margin: 5px;
}

#nav-main-menu.ui-accordion ul,
#nav-main-frame.ui-accordion ul {
    margin-top: 0;
    margin-bottom: 0;
    width: -webkit-calc(~"100% - 13px");
    width: calc(~"100% - 13px");
    padding-top: 5px;
    border-right: 1px solid #CCC;
    border-left: 1px solid #CCC;
}

#nav-main li,
#nav-main-frame li {
    list-style-type: none;
    display: block;
    float: none;
    position: relative;
    line-height: 1em;
}
/* hack for 4J rendered links from custom pages, renders them like LI's */
#nav-main-frame #student-custom-pages > a,
#nav-main-frame #tchr_custom_pages > a {
    display: block;
    line-height: 1em;
    padding: 4px;
}
#nav-main-frame #student-custom-pages > a:first-child,
#nav-main-frame #tchr_custom_pages > li + a {
    border-top: 1px dotted  @border-color;
}
#nav-main-frame #student-custom-pages > br,
#nav-main-frame #tchr_custom_pages > br {
    display: none;
}
/* end LI hack */
#nav-main li a span,
#nav-main-frame li a span {
    color: #444;
}

#nav-main li>span,
#nav-main-frame li > span {
    position: absolute;
    top: 0;
    right: 0;
}

#nav-main li a,
#nav-main-frame li a,
#nav-main-frame li span {
    padding: 4px;
    display: block;
}
#nav-main-frame ul.uniqueSpanLink li > a {
    display: inline-block;
    padding: 4px 0;
}
#nav-main-frame ul.uniqueSpanLink li > span {
   position: relative;
    display: inline-block;
    padding: 4px 0;
}
#nav-main li span a,#nav-main-frame li span a {
    background-color: #B2D0E9;
    border-radius: 4px;
}

#nav-main li span a .ui-icon,#nav-main-frame li span a .ui-icon {
    background-image: url("../img/jquery/ui-icons_222222_256x240.png");
}

#nav-main li span a:hover,#nav-main-frame li span a:hover {
    background-color: @dark-blue;
}
#nav-main li span a:hover .ui-icon,
#nav-main-frame li span a:hover .ui-icon {
    background-image: url("../img/jquery/ui-icons_FFFFFF_256x240.png");
}

#nav-main .list-inline a,
#nav-main-frame .list-inline a {
    display: inline-block;
    padding-right: 16px;
}
#nav-main .list-inline span a,
#nav-main-frame .list-inline span a {
    display: inline-block;
    padding: 0;
}

#nav-main li:hover,
#nav-main-frame li:hover,
.psHelpTOCul li:hover,
#nav-main-frame td a:hover {
    background-color: @light-med-blue;
    color: @link-color-hover;
}
#nav-main li.selected,
#nav-main-frame li.selected {
    background-color: #FFFFFF;
}
#nav-main li.selected:before,
#nav-main-frame li.selected:before {
    background-color: @med-blue;
    content: "";
    width: 5px;
    height: 100%;
    position: absolute;
    left:-5px;
}
#nav-main-menu .ui-accordion li {
    padding: 0;
}

#nav-main li a.icon {
    padding: 0;
    position: absolute;
    right: 0;
    top: 30%;
}

#nav-main li .ui-icon {
    background-image: url("../img/jquery/ui-icons_2e83ff_256x240.png");
}

#nav-main #nav-secondary {
    background-color: rgba(0, 0, 0, 0.1);
    margin: 5px;
    padding: 5px 0;
    border-radius: 4px;
    border: @border-style;
    text-align: center;
}
#nav-main-frame .button {
  display: inline-block;
  margin: 0;
}

#nav-main-frame a:hover {
    background-color: #B2D0E9;
    color: inherit;
}

#nav-main-frame hr {
    margin: 14px 0 0 10px;
    width: 100%;
}

#nav-main-frame-secondary {
  text-align:center;
  border-bottom: @border-style;
  padding: 5px;
  background-color: @nav-box;
  .flex(0 0 auto);
  &.left {
    text-align:left;
  }
  hr {
    margin: 14px 2px 8px 2px;
  }
  p {
    margin: 0;
  }

  a {
    margin: 0 0 0 0;
    display: block;
  }


  .navPrevNext {
    position: relative;
  }


  .button.prev {
    position: absolute;
    top: 0;
    left: 0;
  }

  .navPrevNext .button.next {
    position: absolute;
    top: 0;
    right: 0;
  }

  .navPrevNext a.studentSearchList {
    display: inline-block;
    max-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  .button {
    display: inline-block;
    margin: 0;
  }
}

.frameMenu td,.frameMenu tr {
    /* this should go away when we LI the navs */
    padding: 4px 0;
    width: 100%;
}

.psteacher.frameMenu td,.psteacher.frameMenu tr {
    padding: 0 10px;
}

#nav-k-frame #nav-main-frame li {
    position: relative;
}

#nav-task-frame #nav-main-frame h3 {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 23px;
    line-height: 1.5em;
    border-bottom: none;
    background-color: @dark-blue;
    color: #FFF;
    width: 95%;
}

#nav-task-frame #nav-main-frame h3 .button {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

#nav-task-frame #nav-main-frame h3 .button span {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
}

#nav-task-frame ul.checkList li {
    position: relative;
}

#nav-task-frame ul.checkList li em {
    position: absolute;
    left: 2px;
    top: 2px;
}

#nav-task-frame #nav-main-frame a {
    margin-left: 18px;
}

#nav-task-frame #nav-main-frame .button {
    padding: 1px;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
}
/* remove */
.psteacher #nav-main-frame #nav-main-frame-4dpad {
    padding: 0 0 0 5px;
}
/* remove */
.psteacher #nav-main-frame #nav-main-frame-4dpad b {
    display: block;
    font-weight: bold;
    font-size: 105%;
    margin: 10px 0 0 0;
    padding: 2px 0 0 0;
    border-bottom: 1px dotted #CCC;
    width: 100%;
}
/* remove */
.psteacher #nav-main-frame #nav-main-frame-4dpad br+br {
    display: none;
}

.psparent #nav-main li {
    padding-left: 0;
    background-position: 10px center;
    [dir=rtl] & {
        background-position: right 10px center;
    }
    background-repeat: no-repeat;
    vertical-align: middle;
}

.psparent #nav-main a {
    height: 3em;
    padding-left: 52px;
    padding-right: 20px;
    color: #000;
    text-indent: 0;
    display: table-cell;
    vertical-align: middle;
    background-repeat: no-repeat;
}

.psparent iframe {
    margin-top: 20px;
    width: 165px;
    padding-left: 15px;
}
/* parent navigation icons */
#btn-gradesAttendance {
    background-image: url("../img/icon-gradesAttendance.svg");
}

#btn-testResults {
    background-image: url("../img/icon-test-result.svg");
}

#btn-gradesHistory {
    background-image: url("../img/icon-gradeHistory.svg");
}

#btn-standardsGrades {
    background-image: url("../img/icon-standards-grades.svg");
}

#btn-analytics {
    background-image: url("../img/icon-analytics.svg");
}

#btn-attendanceHistory {
    background-image: url("../img/icon-attendanceHistory.svg");
}

#btn-emailNotification {
    background-image: url("../img/icon-email.svg");
}

#btn-teacherComments {
    background: url("../img/icon-comments.svg");
}

#btn-schoolBulletin {
    background-image: url("../img/icon-bulletin.svg");
}

#btn-classRegistration {
    background-image: url("../img/icon-registration.svg");
}

#btn-balance {
    background-image: url("../img/icon-balance.svg");
}

#btn-mySchedule {
    background-image: url("../img/icon-my-schedule.svg");
}

#btn-myCalendars {
    background-image: url("../img/icon-calendar.svg");
}

#btn-schoolInformation {
    background-image: url("../img/icon-school.svg");
}

#btn-unifiedHomePortal {
    p {
       margin: 0px;
    }
    background-size: 30px;
    background-image: url("../img/myps_favicon.svg");
}

#btn-accountPreferences {
    background-image: url("../img/icon-preferences.svg");
}

.btn-guardianHeaderStateLDS {
    background-image: url("../img/icon-analytics.svg");
}

#link-567c8deb041a3b7778868c499deeeb01 {
    background-image: url("../img/iconSchoolnet.png");
}

#link-c663abf27a1bacad0fdad2dd27fba42d {
    background-image: url("../img/iconCourses.png");
}
/* parent navigation icons */
/* end navigation styles */
@import url("pslogin.less");

.dblRainbow {
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, rgb(255, 0
        , 0)), color-stop(0.05, rgb(255, 153, 0)),
        color-stop(0.1, rgb(255, 255, 0)), color-stop(0.15, rgb(21, 255, 0)),
        color-stop(0.2, rgb(0, 21, 255)), color-stop(0.25, rgb(148, 115, 255)),
        color-stop(0.3, rgb(191, 0, 255)), color-stop(0.4, rgb(255, 255, 255)),
        color-stop(0.5, rgb(255, 255, 255)), color-stop(0.55, rgb(255, 0, 0)),
        color-stop(0.6, rgb(255, 153, 0)), color-stop(0.65, rgb(255, 255, 0)),
        color-stop(0.7, rgb(21, 255, 0)), color-stop(0.75, rgb(0, 21, 255)),
        color-stop(0.8, rgb(148, 115, 255)), color-stop(0.85, rgb(191, 0, 255)),
        color-stop(0.9, rgb(255, 255, 255))) !important;
    background-image: -moz-linear-gradient(center bottom, rgb(255, 0, 0) 0%,
        rgb(255, 153, 0) 5%, rgb(255, 255, 0) 10%, rgb(21, 255, 0) 15%,
        rgb(0, 21, 255) 20%, rgb(148, 115, 255) 25%, rgb(191, 0, 255) 30%,
        rgb(255, 255, 255) 40%, rgb(255, 255, 255) 60%, rgb(255, 0, 0) 65%,
        rgb(255, 153, 0) 70%, rgb(255, 255, 0) 75%, rgb(21, 255, 0) 80%,
        rgb(0, 21, 255) 85%, rgb(148, 115, 255) 90%, rgb(191, 0, 255) 95%,
        rgb(255, 255, 255) 100%) !important;
}

.feedbackPop .box-round {
    background: #FFF;
}

/* columns styles */
.columns {
    -webkit-column-width: 15em;
    -webkit-column-fill: balance;
    -webkit-column-gap: 2em;
    -moz-column-width: 15em;
    -moz-column-fill: balance;
    -moz-column-gap: 2em;
    column-width: 15em;
    column-fill: balance;
    column-gap: 2em;
}

#content-main ol.columns,#content-main ul.columns {
    padding: 0;
}

ol.columns li,
ul.columns li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    list-style-position: inside;
    border-right: 1px dotted #CCC;
}

.col2 {
    width: 50%;
    display: inline-block;
}

#content-main .box-round.col2 {
    width: 46%;
    margin-right: 0;
}

.col3 {
    width: 32%;
    display: inline-block;
    vertical-align: top;
}

#content-main .box-round.col3 {
    width: 32%;
    margin-right: 0;
}

.col2-3 {
    width: 64%;
    display: inline-block;
}

#content-main .box-round.col2-3 {
    width: 61%;
    margin-right: 0;
}

div.box-round.scheduleprcols {
    width: 50%;
    display: inline-block;
    vertical-align: top;
}

/* end columns styles */
p.version {
    font-size: 80%;
    position: absolute;
    top: 0;
    right: 0;
    background: #f2f6fa;
    border-radius: 4px;
    padding: 0 5px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

/* bulletin Styles */
.bulletin {
    border: 1px solid #A7C3DB;
    margin: 0 5px 5px 5px;
    border-radius: 4px;
}

.bulletin-body {
    margin: 0 10px 10px;
}

#content-main .bulletin h1,td h1 {
    margin: 15px 0;
}

.box-round .bulletin h2,.box-round .bulletin h3,td h2,td h3 {
    background: none;
    margin: 10px 0;
    padding: 0;
    text-indent: 0;
}

.box-round .bulletin h3:first-child,
td  h3 {
    padding: 2px 0 2px 5px;
    margin: 0 0 5px 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

#feedback-bulletin {
    text-align: center;
    margin: 10px 0 0 0;
}

.box-round .nextprevdate,.box-round .box-bottom {
    text-align: center;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    margin-top: 10px;
}

.public-bulletin-margin > p {
    margin-left: 10px !important;
}

/* end bulletin Styles */

@import url("forms.less");

/*Form Elements*/

.legend-success {
    background-image: url("../img/icon-check.svg");
    background-repeat: no-repeat;
    padding: 0 25px;
    [dir=rtl] & {
        background-position-x: right;
    }
}

.labeled-accordion label {
    display: inline-block;
    width: 35%;
}
.labeled-accordion .ui-accordion {
  display: inline-block;
  margin-left: 0;
  margin-right: 0;
  width: calc(~"100% - 35% - 25px");
}


.ui-dialog.ui-widget input[type="text"],
.ui-dialog.ui-widget input[type="email"],
.ui-dialog.ui-widget input[type="password"],
.ui-dialog.ui-widget select {
    font-size: 100%;
    padding: 3px;
}

/* adjust left-padding for timeEntry widget in a dialog */
.ui-dialog.ui-widget span.timeEntry_wrap input.timeEntry {
    padding-left: 20px;
    [dir=rtl] & {
        padding-left: 23px;
    }
}

.ui-dialog.ui-widget .ui-jqgrid-pager select {
    padding: 0;
}

a.btnGBlaunch:hover,a.btnPTAlaunch:hover {
    background-color: @light-med-blue;
    color: #FFF;
}

.input-note {
    margin: 0 0 0 35%;
    padding: 0 0 0 15px;
    font-size: 90%;
    display: block;
}

.input-note-email-address {
   margin-top:5px;
   padding:0px;
   font-size:90%;
   display:block;
   font-weight: normal;
}

.global-identifier-field {
    #useEmailAsGlobalIdDiv {
        margin-right:20px;
    }
}


td .input-note {
    margin: 0 0 0 0 !important;
    padding: 0 0 0 5px;
    font-size: 90%;
    display: block;
    font-weight: normal;
}

#jvm_parameters_div {
    min-width: 900px;
}

/* button rows */
div.button-row, .button-row {
  padding: 0;
  margin: 0 20px 10px;
  text-align: right;
  button,
  .button, .psMultiButton {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 10px;
  }

  .drawerPanel & {
    padding-bottom: 10px;
  }

  .drawerPanel fieldset & {
    padding-bottom: 0;
  }

  fieldset & button,
  fieldset & .button,
  .box-round & button,
  .box-round& .button {
    margin-bottom: 0;
  }

  fieldset &,
  .box-round &,
  fieldset &:nth-child(odd),
  .box-round &:nth-child(odd) {
    margin: 10px;
    background-color: transparent;
  }

  td & {
    /* check styles bad */
    padding: 5px 0;
  }

  a:first-of-type {
    margin-left: 5px;
  }

  a:not(.button):last-of-type {
    margin-right: 5px;
  }

  #guardianSearch & {
    margin-top: 10px;
  }
}

[dir=rtl] a.button.inline-right {
    display: inline-block;
}

.button-row.action-bar {
    position: fixed;
    position: sticky;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border-top: 1px solid #ccc;
    margin-right: 0;
    margin-left: 0;
    height: 20px;
    padding: 10px 20px;
}

.button-group {
  white-space: nowrap;
  display: inline-block;
}

/*buttons*/
button,
.button,
.button-inactive,
input[type="button"],
input[type="submit"],
#nav-main-frame a.button {
  .button-style;
    white-space: nowrap;
    position: relative;
    cursor: pointer;
    padding: 0.2em 0.4em;
    margin: 5px 0 5px 10px;
    font-size: 100%;
    line-height: 1.2em;
}

#btnSubmit {
    background-color: @button-color;
}
a.button.red {
    background-color: @alert-danger-foreground;
}

a.button.red:hover {
    background-color: #AA0000;
}

a.button.right {
    float: right;
}

button.mini,
a.button.mini,
#nav-main-frame button.mini,
#nav-main-frame a.button.mini {
    font-size: 80%;
    padding: 0 0.2em;
}

button.mini .ui-icon-white {
    padding-bottom: 1px;
    [dir=rtl] & {
        transform: scaleX(-1);
    }
}

button:hover,
button:focus,
.button:hover,
.button:focus,
#btnSubmit:hover,
#btnSubmit:focus,
input[type="button"]:hover,
input[type="button"]:focus,
input[type="submit"]:hover,
input[type="submit"]:focus,
#nav-main-frame a.button:hover,
.psFilePicker-focus {
    .button-style-hover
}

.button-inactive:hover,
.button-inactive:focus {
    .inactive-button-style-hover
}

td > .button {
    margin: 0;
}

.box-round h2>button,
.box-round h2>a.button,
.box-round h4>button,
.box-round h4>a.button{
    font-weight: normal;
    margin: 0 !important;
  margin-bottom: 2px;
    bottom: 0.2em;
    right: 0;
    position: absolute;
    float: none;
    font-size: 14px;
}
.box-round h4.lite>button,
.box-round h4.lite>a.button {
    bottom: 0.05em;
  right: 0.1em;
}

.box-round h2 > button.ui-datepicker-trigger {
    position: inherit;
    padding: 3px;
    margin: inherit;
    top: -2px;
    right: 0;
}

.box-round h2 > button.mini,
.box-round h2 > a.button.mini {
    font-size: 12px;
}

.confirmationReq,
.button.confirmationReq,
.ui-dialog .ui-dialog-buttonpane button.ui-button.ui-state-default.confirmationReq{
    background-color: #FFFFFF !important;
    border: 1px solid #DA2F6B;
    color: #EB1313 !important;
}

a.button.confirmationReq.initialized {
    color: #DA2F6B;
}

.ui-dialog .ui-dialog-buttonpane button.ui-button.ui-state-default.confirmationReq,
.ui-dialog .ui-dialog-buttonpane button.ui-button.ui-state-default.confirmationReq:hover,
.ui-dialog .ui-dialog-buttonpane label {
    padding: 0;
}

.confirmationReq:hover:not([disabled]),.confirmationReq:focus:not([disabled]),
.button.confirmationReq:hover:not([disabled]),.button.confirmationReq:focus:not([disabled]),
.ui-dialog .ui-dialog-buttonpane button.ui-button.ui-state-default.confirmationReq:hover {
    cursor: pointer;
    background-color: #F1F4F4;
    text-decoration: underline;
    outline: none;
}


button.prev em,.button.prev em {
    width: 10px;
    height: 16px;
    display: inline-block;
    background-image: url(../img/jquery/ui-icons_FFFFFF_256x240.png);
    background-position: -100px -16px;
    background-repeat: no-repeat;
    [dir=rtl] & {
        transform: rotateY(180deg);
    }
}

button.next em,.button.next em {
    width: 10px;
    height: 16px;
    display: inline-block;
    background-image: url(../img/jquery/ui-icons_FFFFFF_256x240.png);
    background-position: -35px -16px;
    background-repeat: no-repeat;
    [dir=rtl] & {
        transform: rotateY(180deg);
    }
}

.button-row-wizard {
    height: 2.5em;
    text-align: center;
    background-color: @light-grey;
    position: relative;
    border-top: @border-style;
  .ui-accordion-content:last-of-type > div& {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  #content-main & p,
  #content-main .box-round & p,
  & p { /* bad */
    position: absolute;
    top: 0.5em;
    left: 20%;
    right: 20%;
  }
  .prev {
    left: 10px;
    top: 5px;
    position: absolute;
    margin: 0;
  }
  .next {
    right: 10px;
    top: 5px;
    margin: 0;
    position: absolute;
  }

  .save {
    right: 100px;
    top: 5px;
    margin: 0;
    position: absolute;
  }
}

.btn-row-export
{
  text-align: right;
  #idSaveTemplate {
      position: relative;
      right: 30px;
  }

  #btnExport {
    position: relative;
  }

  #btnImport {
    position: relative;
  }
}

/*button set*/
.ps-buttonset {
    display: inline-block;
}

.ps-buttonset button {
    margin-left: 0 !important;
    margin-right: 1px;
}

.ps-buttonset button:last-of-type {
    margin-right: 0 !important;
}
.pswb .ps-buttonset button {
    margin-right: -0.3em;
}
/*end button set*/
/* multibutton styles */

.psMultiButton, .psMultiButtonMenu {
    display: inline-block;
    position: relative;
}

.psMultiButton button.menuButton {
    padding-left: 0;
    padding-right: 0;
    margin-left: 1px;
}

.psMultiButton button, .psMultiButtonMenu button {
    margin: 0;
}

.psMultiButton button .ui-icon-white,
.psMultiButtonMenu button .ui-icon-white {
/*     vertical-align: text-bottom; */
}


fieldset .psMultiButton,
fieldset .psMultiButtonMenu {
    margin: 0 5px 0 0;
}

.psMultiButton ul.multiButtonList {
    position: absolute;
    z-index: 200;
    height: auto;
    padding: 5px;
    margin: 0;
    display:none;
    text-align: left;
    border: 1px solid #aaaaaa;
    background-color: #ffffff;
    box-shadow: 0 5px 10px rgba(0,0,0,0.4);
    top:0;
    right:0;
    visibility: hidden;
    overflow-y: auto;
}

div.multi-button-panel,
.button-row div.multi-button-panel {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 5px 1px;
    text-align: left;

    a, a:last-of-type, a:first-of-type {
        padding: 0 5px 0 5px;
        margin-left: 0px;
        margin-right: 0px;
    }

    a:focus {
        background-color: #eee;
    }
}

fieldset > .info-text {
    padding: 4px 10px 4px 10px;
    margin: 0 10px 10px 10px;
}

fieldset > div > .info-text {
    padding: 4px 10px 4px 10px;
    margin: 0 10px 10px 0;
}

fieldset div > .textareaCounter, .psDialog fieldset div > .error-message, .psDialog fieldset div > p{
    margin-left: calc(~"35% + 15px");
    margin-right: 10px;
}

fieldset.noborder .noborder{
    border: none;
}

fieldset.noborderforalerts {
    border: none;
    div:nth-of-type(odd):not(.button-row, .pds-grid-widget-filter){
        background-color: @zebra-alt;
    }
}

.alertContentForAlerts {
    padding: 10px 0;
    margin-left: 50px;
    max-width: 465px;
    background-color: white;
}

.psDialog fieldset.noborder > div {
    padding-left: 5px;
    padding-right: 5px;
}

.psDialog fieldset fieldset.noborder{
  border: none;
  margin-left: 0;
  > div {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    > button {
      margin-left: 10px;
    }
  }
  > table {
    margin-left: 0;
  }
}

.psDialog fieldset div > textarea {
    width: -webkit-calc(~"100% - 35% - 20px");
    width: calc(~"100% - 35% - 20px");
}

.psDialog fieldset div {
    > p.label {
        margin-left: 0;
        margin-right: 0;
        padding-left: 10px;
        font-weight: bold;
        margin-bottom: 0;
        vertical-align: top;
    }
    > p.subtext {
        font-weight: normal;
    }
}

.psDialog fieldset div > p.label + p {
    display: inline-block;
    padding-left: 3px;
    margin-left: 0;
    margin-bottom: 0;
}
.psDialog fieldset div > p.label + span {
    padding: 0;
    margin: 0;
    white-space: normal;
    width: 60%;
}
.psDialog fieldset div > p.label + span > p {
    padding: 0 0 0 3px;
    margin: 0;
}
.psDialog fieldset div > .error-message {
    word-break: break-all;
}

.psDialog .psMultiButton ul.multiButtonList  > li,
.psMultiButton ul.multiButtonList > li,
.psMultiButton ul.multiButtonList > li,
.psMultiButton .fieldList ul > li {
    list-style-type:none;
    margin: 0;
}

ul.multiButtonList > li:hover,
.fieldList ul > li:hover,
.fieldList ul > li:nth-child(odd):hover {
    background-color: @light-med-blue;
}

.psMultiButton ul.multiButtonList > li hr {
    margin: 0;
}

.psMultiButton ul.multiButtonList li.category {
    font-weight: bold;
    background-color: @light-grey;
    padding: 0 5px;
}

.psMultiButton ul.multiButtonList li h2 {
    background-color: @light-med-blue;
    color: inherit;
    margin:0;
}

.psMultiButton ul.multiButtonList li h2:hover,
.psMultiButton ul.multiButtonList li.category:hover {
    cursor: default;
}

.psMultiButton ul.multiButtonList li.active {
    background-image: url("../img/icon-check-green.png");
    background-repeat: no-repeat;
    background-position: 2px 3px;
}
[dir=rtl] .psMultiButton ul.multiButtonList li.active {
    background-position-x: 99.5%;
}
.psMultiButton ul.multiButtonList li a {
    text-align: left;
    margin-left: 0;
    margin-right: 0;
    white-space: nowrap;
}
.fieldList ul > li:nth-child(odd) {
    /*     background-color: @zebra; */
}

ul.multiButtonList > li a,
 .fieldList ul > li a {
/*     display: list-item; */
}
.psMultiButton ul.multiButtonList li a:hover {
   background-color: transparent;
}
/* makes room for the check boxes */
.psMultiButton ul.multiButtonList.selectable li a {
    padding-left: 25px;
    display:block;
    margin-right:0;
}
/* end multibutton styles */

.btn-config {
    vertical-align: text-bottom;
    &:before {
        content: "";
        width: 16px;
        height: 16px;
        background-image: url("../img/jquery/ui-icons_FFFFFF_256x240.png");
        background-position: -192px -112px;
        background-repeat: no-repeat;
        display: block;
        overflow: hidden;
    }
}

.checkmark-icon em{
    width: 16px;
    height: 16px;
    background-image: url(../img/icon-check.svg);
    display: inline-block;
    overflow: hidden;
    background-repeat: no-repeat;
}

.checkmark-icon.checked em {
    background-position: 0 2px;
}

.ui-state-highlight td .checkmark-icon em :not(.centerContent) {
    background-position: 0 -14px;
}

.checkmark-icon warn{
    width: 32px;
    height: 32px;
    background-image: url(../img/alert_other_bare.png);
    display: inline-block;
    overflow: hidden;
    background-repeat: no-repeat;
    margin-left: 15px;
    margin-top: 2px;
}

/* check styles bad */
.accordion {
    margin: 10px 20px 10px 20px;
}
.accordionSection {
  background-color: @light-grey;
  &:hover {
    background-color: @lightest-blue;
    cursor: pointer;
  }
  &.active {
    background-color: #FFFFFF;
    border: 2px solid @alert-info-foreground;
    &:hover {
      background-color: #FFFFFF;
      cursor: default;
    }
    > h2 {
      border-bottom: 1px solid @border-color;
      padding-bottom: 5px;
    }
    .accordionContent {
      display: block;
    }
  }
  > h2 {
    border-bottom: none;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-bottom: 0;
    em {
      margin-left: 10px;
    }
  }
  .accordionContent {
    display: none;
  }
}

.ui-accordion.fixed-height .ui-accordion-content {
    overflow: hidden;
}

button::-moz-focus-inner {
    border: 0;
}

fieldset button,fieldset .button {
    margin: 0 10px 0 0;
}

fieldset .button.nomargin {
    margin: 0 0 0 0;
}

.btn-column button {
    width: 10em;
}

td.button-column a.button:first-child,td.button-column button:first-child
    {
    margin-left: 0;
}

button.search {
    margin: 0;
    height: 22px;
}


td button.search {
    margin: 0;
}

button.search em {
    display: block;
    width: 16px;
    height: 16px;
    background: url("../img/icon-search.png") no-repeat center center;
}

.searchButtons a {
    display: inline-block;
    min-width: .75em;
    text-align: center;
    border-radius: 4px;
    padding: 0 0.4em;
}
.searchButtons a:first-child {
   margin-left: 10px;
}
.searchButtons a:hover {
    background-color: #B2D0E9;
}
#teacherSearchPage #ssAutoComplete {
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 10px;
  display: inline-block;
}

#ssAutoComplete input {
    height: 18px;
}

select#staffType {
    margin: 0;
}

select#newMigrateDataFrom {
    width: 90%;
    margin: 0;
}

select#typeSelect {
    margin: 0;
}

#selectHandpick {
    margin: 0 0 0 10px;
}

#selectHandpick select {
    width: 98%;
}

#selectHandpick br {
    display: none;
}

.minusList button.minus {
    float: right;
}
.minusList span {
    float: right;
}

button, .button {
  &.plus, &.minus, &.undo, &.up, &.down {
    position: relative;
    em {
      top: .2em;
      left: .2em;
      display: inline-block;
      width: 16px;
      height: 16px;
    }
  }
  &.plus em {
    background-image: url("../img/jquery/ui-icons_FFFFFF_256x240.png");
    background-position: -30px -128px;
  }
  [dir=rtl] &.plus em {
                background-position: -35px -128px;
              }
  &.minus em {
    background-image: url("../img/jquery/ui-icons_FFFFFF_256x240.png");
    background-position: -64px -128px;
  }

  &.undo em {
    background: url("../icon_undo.png") no-repeat center center;
  }
  &.up em {
    background-image: url("../img/jquery/ui-icons_FFFFFF_256x240.png");
    background-position: 0 -16px;
  }

 &.down em {
    background-image: url("../img/jquery/ui-icons_FFFFFF_256x240.png");
    background-position: -64px -16px;
  }

  &.btnMassFill {
    margin: 0 0 0 5px;
    float: none;
    padding: 0;
    line-height: 1.5em;
    span, &:hover span, em {
      display: block;
      width: 12px;
      height: 12px;
      padding: 0 3px;
      background: url("../img/icon-massFill.png") no-repeat top center;
    }
  }

}

[dir=rtl] .button-row {
    #btnBack, #btnNew, #addGroupsBtn {
        padding-inline-end: 4px;
    }
    #ptpBtn {
        padding-inline-end: 0.4em;
    }
    .abortMessage {
        padding-inline-start: 10px;
        padding-inline-end: 10px;
    }
}

.textareaFullWidth {
    width: 100%;
    word-wrap: break-word;
}
.textareaHalfWidth {
    width: 48%;
    display: inline-block;
    word-wrap: break-word;
}

fieldset .textareaFullWidth {
    width: -webkit-calc(~"100% - 10px");
    width: calc(~"100% - 12px");
    margin-left: 5px;
    margin-right: 5px;
    height: 6em;
}

.button-sort button {
    display: inline-block;
    margin: 5px;
}

/* Styles for Document Attachments - sorting */
.sortable.sortAscending em,
.sortable.sortDescending em {
    white-space: nowrap;
    display: inline-block;
    top: 3px;
    width: 16px;
    height: 15px;
    position: relative;
    padding: 0 3px;
}

.sortable.sortAscending em {
    background-image: url("../img/jquery/ui-icons_222222_256x240.png");
    background-position: 0 -16px;
}

.sortable.sortDescending em {
    background-image: url("../img/jquery/ui-icons_222222_256x240.png");
    background-position: -64px -16px;
}

.sortable em {
    display: inline-block;
    width: 16px;
    height: 15px;
}

.sortable {
    cursor: pointer;
}

.sortable ::selection {
    background: transparent;
}

/* Styles for Document Attachments - others */
#docattachtable th:nth-child(1),#docattachtable td:nth-child(1) {
    padding: 4px;
    padding-right: 2px;
    max-width: 15px;
}

#docattachtable th {
    max-width: 250px;
    word-break: normal;
}

#docattachtable td:nth-child(3),#docattachtable td:nth-child(4),#docattachtable td:nth-child(5) {
    max-width: 250px;
    word-break: break-all;
}

#docattachtable th:nth-child(6),#docattachtable th:nth-child(7),
#docattachtable td:nth-child(6),#docattachtable td:nth-child(7) {
    min-width: 5em;
}

#docattachtable th:nth-child(8),#docattachtable td:nth-child(8) {
    width: 8em;
}

#docattachtable th:last-child, #docattachtable td:last-child {
    min-width: 76px;
    width: 76px;
}

span.comment-da {
    margin: 0 10px 10px;
}

.fileData label {
    display: block;
}

.fileData textarea,.fileData input[type="text"],#fileDataNotesUpdate {
    display: block;
    margin: 0 10px;
    padding: 2px 4px;
    width: 90%;
}

.fileData textarea,#fileDataNotesUpdate {
    height: 7em;
}

#fileStatusId {
    white-space: normal;
}

#statusTextId {
    padding-left: 5px;
    width: 450px;
    word-wrap: break-word;
}

#button-row-da {
    display: block;
    padding-right: 0;
    padding-left: 0;
}

.pagination {
    white-space: nowrap;
}

.pagination>div {
    margin: 0;
    padding: 0;
    display: inline-block;
}

#toppage.pagination>div:first-child {
    text-align: left;
    width: 50%;
}

#toppage.pagination>div:last-child {
    text-align: right;
    width: 50%;
}
/* check styles bad*/
#bottompage.pagination>div a,#bottompage.pagination>div span {
    margin: 3px;
    padding: 0 4px;
}

#bottompage.pagination>div {
    text-align: center;
    display: block;
}

.pagination a.PageLink {
    border: 1px solid #CBCBCB;
}

.pagination span.CurrentPage {
    font-weight: bold;
}

/* Styles for Navigation Collapse*/
body.uiContMax #header,body.uiContMax #footer {
    display: none;
}

body.frameMenu.uiContMax {
    border-top: none;
}

body.uiContMax #container,
body.uiContMax #container-frame {
    width: 100%;
}

body.hideAllNav #usercontext-bar {
    display: none;
}

body.uiContMax #content {
    height: 100%;
}

body.uiContMax #usercontext-bar #powerschool-logo {
    height: 10px;
}

body.uiNoNav #container-frame #btnNoNav.expanded em {
    background-position: -31px -17px;
}

[dir = rtl] body.uiNoNav #container-frame #btnNoNav.expanded em {
    background-position: -96px -17px;
}

#btnNoNav {
    position: absolute;
    top: 9px;
    height: 16px;
    width: 8px;
    background: #f4f7fa;
    z-index: 2;
    left: -1px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid  @border-color;
    border-left: none;
}

#container-frame #btnNoNav {
    left: 0;
}

#btnNoNav em {
    position: absolute;
    top: 0;
    right: 0;
    height: 14px;
    width: 14px;
    background: url("../img/jquery/ui-icons_222222_256x240.png") no-repeat
        102px 21px;
    cursor: pointer;
}

#btnNoNav:hover {
    width: 16px;
    height: 32px;
    z-index: 100;
}

#btnContMax:hover {
    width: 32px;
    height: 16px;
    z-index: 100;
    border-bottom-left-radius: 4px;
}

#btnNoNav.collapsed em {
    background-position: -96px -17px;
}

#btnNoNav.expanded em {
    background-position: -33px -17px;
}

[dir = rtl] #btnNoNav.collapsed em {
    background-position: -35px -17px;
}

[dir = rtl] #btnNoNav.expanded em {
    background-position: -98px -17px;
}
body.uiNoNav #content-main {
    margin: 0 0 0 6px;
}

.noCalc body.uiNoNav #content-main {
    margin: 0 0 0 6px;
}

body.uiNoNav #nav-main {
    display: none;
}

body.uiNoNav {
    background-position: -195px;
}

[ dir=rtl ] body.uiNoNav {
    background-position: right -195px center;
}

.psparent #content-main #btnNoNav,.psparent #content-main #btnContMax {
    margin: 0;
}

body.uiNoNav #content {
    background-position: -195px;
}

body.uiNoNav #content {
    background-position: -195px;
}

#btnContMax {
    background-color: @breadcrumb-background;
    color: #FFF;
    width: 16px;
    height: 9px;
    position: absolute;
    top: 0;
    left: -1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 4px;
    border-top: 0;
    border-left: 0;
    z-index: 1;
}

#container-frame #btnContMax,#content-lunch #btnContMax {
    left: 0;
}

#btnContMax em {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 14px;
    width: 16px;
    background: url("../img/jquery/ui-icons_FFFFFF_256x240.png") no-repeat
        102px 21px;
    cursor: pointer;
}

#btnContMax.collapsed em {
    background-position: 0 -17px;
}

#btnContMax.expanded em {
    background-position: -64px -15px;
}

/* End styles for Navigation Collapse*/
.importexportCell {
    width: 75%;
}

.bold {
    font-weight: bold;
}

button.no-float,.button.no-float {
    float: none;
    display: inline-block;
}

button.inline,.button.inline {
    float: none;
    margin: 0;
    display: inline-block;
}

label .span {
    font-size: 80%;
}

/* add 'data-note="Note to display goes here"' attribute to label to make this work */
label.with-note:after {
    content: attr(data-note);
    font-weight: normal;
    display: block;
}

/* Tabs */
.tabs {
    width: 100%;
    margin: 10px 0 20px 0;
    list-style: none;
}

#searchDialog .tabs {
    margin: 0 0 15px;
}

ul.tabs,
ul.ui-tabs-nav.ui-corner-all.ui-widget-header {
    background: none; /* jquery overide */
    background-image: url("../img/tab-back-line.png");
    background-position: left bottom;
    background-repeat: repeat-x;
    background-image: -webkit-linear-gradient(bottom,  @border-color 0, rgba(255, 255, 255, 0)
        2px, rgba(255, 255, 255, 0) 100%);
    background-image: -moz-linear-gradient(bottom,  @border-color 0, rgba(255, 255, 255, 0)
        2px, rgba(255, 255, 255, 0) 100%);
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0,  @border-color),
        color-stop(2px, rgba(255, 255, 255, 0)),
        color-stop(1, rgba(255, 255, 255, 0)));
    background-image: linear-gradient(bottom,  @border-color 0, rgba(255, 255, 255, 0)
        2px, rgba(255, 255, 255, 0) 100%);
    overflow: hidden;
    margin: 0 0 20px 0;
    padding: 0 0 0 20px;
    width: -webkit-calc(~"100% - 20px");
    width: calc(~"100% - 20px");
    border-radius: 0; /* jquery overide */
}

.box-round ul.tabs {
    margin: 0 0 10px 0;
    width: -webkit-calc(~"100% - 20px");
    width: calc(~"100% - 20px");
}

div.tabs ul.ui-tabs-nav>li {
    margin: 0;
    border-color:  @border-color;
}

ul.tabs li,
.ui-tabs .ui-tabs-nav li {
    margin: 0 0 -1px 0;
    text-decoration: none;
    color: #000;
    float: inline-start;
    background-color: #F4F4F4;
    background-image: none;
    display: inline-block;
    border: 1px solid  @border-color;
    border-radius: 2px 2px 0 0;
}

ul.tabs li a,
div.tabs ul.ui-tabs-nav li a {
    margin: 0;
  padding: 0.5em 1em;
    color: #000;
}
ul.tabs li a {
  line-height: 2em;
}
.ui-tabs.ui-widget-content {
    border: none;
}

.ui-tabs {
    padding: 0;
}

.ui-tabs .ui-tabs-panel {
    padding: 0;
}
ul.ui-tabs-nav {
  .ui-tabs-active:focus {
    outline: none;
    a {
      box-shadow: inset 0 0 5px @outline-color;
    }
  }
}
/* This is for an "action" tab in a folder-type tabs list. Requires some tweaking. */
ul.tabs li.tabAction,
ul.tabs li.tabAction:hover {
    cursor: pointer;
    border: outset 1px #666;
    color: #FFF;
    padding: 0;
    margin: 0;
    font-size: 100%;
    background-color: #83A8E4;
    background-position: center top;
    background-image: url("../img/btn-back.png");
    background-repeat: repeat-x;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

ul.tabs li:hover,
div.tabs ul.ui-tabs-nav li:hover {
    color: #000;
    background-color: #FFFFFF;
    padding: 0 0 0 0;
}

ul.tabs li.selected,
li.ui-tabs-selected,
div.tabs ul.ui-tabs-nav li.ui-state-active:hover,
.ui-tabs .ui-tabs-nav li.ui-tabs-active    {
    color: #000;
    background: #FFF;
    padding: 0 0 1px 0;
}

ul.tabs.no-selected {
    padding-bottom: 1px;
}

fieldset.submit input {
    float: left;
}

.overflow {
    overflow: auto;
}

/* box-round */
.box-round {
    border: @border-style;
    border-radius: @border-radius-med;
    margin: 0 20px 20px;
  > :first-child {
    margin-top: 10px;
  }
  &.noborder {
      border: none;
  }
}

.box-round > form:first-child {
    margin-top: 10px;
    display: block;
}

.box-round .box-round,
.box-round h2.toggle + div > fieldset {
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
}

#dialog-translationhints .box-round {
    padding: 5px;
    background-color: @light-grey;
}

#dialog-translationhints .box-round:hover {
    background-color: @light-med-blue;
}

#dialog-translationhints .box-round:hover a {
    font-weight: bold;
}

.popUp .box-round {
    margin-top: 20px;
}

.popUp .constraint-dialog .box-round{
    width: fit-content;
}

.box-round {
  div.dashboardFunctions>h2 {
      margin-top: 20px;
  }
  h2, h3, h4, h5 {
    margin-right: 10px;
    margin-left: 10px;
    position: relative;
    &:first-child {
      margin-top: 5px;
    }
  }
  p {
    margin: 0 10px 10px;
  }

  > p:first-child {
    margin-top: 10px;
  }
  &.nested {
    display: inline-block;
    background-color: #fff;
    background-color: rgba(255, 255, 255, 0.8);
    p {
      display: inline-block;
    }

    .choicefloat {
      float: none;
    }
  }
  fieldset {
     h3:first-child {
      margin-top: 0;
      border-top: none;
      border-bottom: 1px solid #CCC;
    }

     h3 {
      margin-top: 0;
      border-bottom: 1px solid #CCC;
      border-top: 1px solid #CCC;
    }
  }

   ul.tabs+h2 {
    font-size: 110%;
    font-weight: bold;
    color: #000;
    margin: 0 0 10px 0;
    padding: 5px 10px;
    clear: left;
  }
  /* end box-round */
}

th.expanded {
    background: #FFF url("../img/arrow-down-blk.svg") no-repeat 3px center;
    padding-left: 16px !important;
    cursor: pointer;
}

th.collapsed {
    background: #FFF url("../img/arrow-right-blk.svg") no-repeat 3px center;
    padding-left: 16px !important;
    cursor: pointer;
    margin-bottom: 0;
}

button.table_group_expanded {
    background: transparent url("../img/arrow-down-blk.svg") no-repeat 3px center;
    padding-left: 10px !important;
    cursor: pointer;
    border: none;
    vertical-align: middle;
    margin: 0;
}

button.table_group_collapsed {
    background: transparent url("../img/arrow-right-blk.svg") no-repeat 3px center;
    padding-left: 10px !important;
    cursor: pointer;
    border: none;
    vertical-align: middle;
    margin: 0;
}

.overflowMaxAutoX {
    max-width: 100%;
    overflow: auto;
    overflow-y: hidden;
}

body#publicBulletin {
  background-image: none;
  #content-main {
    margin-left: 0;
    padding: 0;
    background: #FFF;
    padding-top: 1px;
  }

  #usercontext-bar {
    height: 10px;
    padding: 0;
    background: @dark-blue;
  }

  #breadcrumbs {
    background: transparent;
  }

  #btnContMax, #btnNoNav {
    display: none; /* hides nav collapse, as there is none */
  }
}

.header-buttons {
    display: block;
    margin: 10px;
}

input.dummy {
    visibility: hidden;
    height: 0;
    width: 0;
    position: absolute;
    top: 0;
    right: 0;
}
/* this class controls multiple really wide inputs: the custom field page, and some SR pages have */
input.inputDBWidth {
    width: 90%;
}
/* End really wide inputs */
.header-buttons button {
    margin: 0 0 0 10px;
}

.blank {
    visibility: hidden;
}

.concealed {
    display: none;
}

.invisible {
    /* Depreciated, use .concealed and proper standards. */
    /* Use $j().addClass('concealed') and $j().removeClass('concealed') */
    display: none;
}

.hide,.hidden {
    /* Depreciated, use .concealed and proper standards. */
    /* Use $j().addClass('concealed') and $j().removeClass('concealed') */
    display: none !important;
}

.show {
    /* Depreciated, use .concealed and proper standards. */
    /* Use $j().addClass('concealed') and $j().removeClass('concealed') */
    display: block !important;
}

table.gridSched td.highlight {
    border: 2px solid Blue;
    background-color: lightSteelBlue;
}

table.gridSched {
    .schedule-room {
        margin-top: 20px;
    }
    .cellWrapper {
        position: relative;
        white-space: normal;
    }
    .clockIndicator {
        position: absolute;
        top: 0;
        right: 0;
    }
    .flexRow {
        display: flex;
    }
    .flexSpaceBetween {
        justify-content: space-between;
    }
    .flexAlignItemsFlexStart {
        align-items: flex-start;
    }
    .flexAlignItemsCenter {
        align-items: center;
    }
}

.highlight,td.highlightDate,table.ui-datepicker-calendar tbody td.highlightDate a
    {
    background: #b2D0E9;
}

.center,
.align-center,
tr.center th,
th.center,
th[align="center"] {
    text-align: center;
}

table.grid th.right,
.right {
    text-align: end;
}
.left,
th.left,
table.grid th.left,
table.grid td.th.left {
    text-align: start;
}

#attTotal, #termAbsTotal, #termTarTotal {
	font-weight: bold;
	color: #444;
	padding: 4px 5px;
	background: #a3bfcc;
}
#attByClass{
    font-weight: bold;
	color: #444;
	padding: 4px 5px;
	background: #a3bfcc;
    font-size: 100%;
}
.no-float {
    float: none;
}
/* page Customization */
div.injectable {
    display: none;
}

.xray_include {
    border: black 2px dashed;
    background-color: #FCC;
    padding: 10px;
}

.xray td {
    border: black 1px solid;
}

.xray div {
    border: blue 1px solid;
}
/* end Page Customization */
body.feedbackPop #container {
    min-width: 600px;
}

/* table styles */
table,
table div table {
    margin: 0 20px 20px;
    width: -webkit-calc(~"100% - 40px");
    width: calc(~"100% - 40px");
    border: none;
    word-wrap: break-word;
    font-size: inherit;
}

.box-round table,
#quickLookup table,
#brij-standards table {
    margin: 0 10px 10px;
    width: -webkit-calc(~"100% - 20px");
    width: calc(~"100% - 20px");

    #newCourseSchoolTable {
        width:100%;
}
    #newCourseYearTable {
      width:100%;
      max-height:250px;
      overflow:auto;
    }
}

.box-round>table:first-child,
#quickLookup table {
    margin-top: 10px;
}

#quickLookup td {
    vertical-align: middle;
}

th,
td.th,
table.grid td.th,
table.grid tbody tr:nth-child(odd)>td.th,
table.grid tbody.tbody tr:nth-child(odd) th.th {
    font-weight: bold;
    color: @dark-grey;
    padding: 4px 5px;
    background: @light-med-blue;
  a {
    color: darken(@link-color, 10%);
  }
}

td {
    border: none;
    padding: 4px 5px;
    vertical-align: top;
}
th:first-of-type {
   text-align: left;
  &.right {
    text-align: right;
  }
  &.center {
    text-align: center;
  }
}

tr.headerRow td,
tr.headerrow td,
table.linkDescList tr.headerRow>td,
table.linkDescList tr.headerrow>td,
tr[bgcolor="#f6f6f6"]>td,
tr.headerRow:nth-child(odd) td,
tr.headerrow:nth-child(odd) td,
/* old legacy td tags that look like th */
table.grid tbody tr[bgcolor="#f6f6f6"] > td.bold,
table.linkDescList tbody tr[bgcolor="#f6f6f6"] > td.bold,
table.linkDescList tr.headerrow td.bold,
table.zebra tbody tr[bgcolor="#f6f6f6"] > td.bold
    {
    /* old school 4D TH styles... they didn't use th tags */
    font-weight: bold;
    color: @dark-grey;
    padding: 4px 5px;
    background: @light-med-blue;
}

td.pre {
    white-space: pre-wrap
}

/* standards list all */

[dir=rtl] .hierarchical-standards-list {
    button {
        background-image: url('../img/arrow-left-blk.svg');
    }
}

.hierarchical-standards-list {
    margin: 0 10px 10px 10px;

    button {
        height: 22px;
        width: 20px;
        background-color: transparent;
        background-image: url('../../images/listcollapsed.gif');
        background-position: 4px 5px;
        background-repeat: no-repeat;
        border: none;
        margin: 0;
        vertical-align: middle;

        &:hover {
            background-color: transparent;
        }

        &.expanded {
            background-image: url('../../images/listexpanded.gif');
        }
    }

    a, span, button {
        vertical-align: middle;
    }

    a, .identifier {
        display: inline-block;
        font-size: 14px;
        line-height: 14px;
        padding: 9px 0;
    }

    li {
        list-style: none;
        margin: 0;
        padding: 0;

        &.no-children .row-wrapper {
            padding-left: 24px;
        }

        &:nth-of-type(odd){
            background-color: @zebra;
        }

        &:nth-of-type(even){
            background-color: @zebra-alt;
        }

        &.match-result {
            font-weight: bold;
        }

        .standardCheckMark {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

#grid-legend-hierarchical-standards-list {
    display: none;
}

.hierarchical-button-row {
    background-color: @light-med-blue;
    margin: 10px 10px 0 10px;
    padding: 0 10px;
    text-align: right;
}

/* old legacy td tags that look like th */
table.grid tbody tr[bgcolor="#f6f6f6"] > td.bold {
   text-align: center;
   vertical-align: middle;
}
table#attendance {
    font-size: 80%;
    margin: 10px auto;
}

td button,
th button,
td button:first-of-type {
    margin: 0;
}

td button + button,
td .button + .button {
    margin-left: 5px;
}

/* table grid styles */
table.grid {
    border-collapse: collapse;
    background-image: url("../table-back-stripes.png");
    border: 1px solid  @border-color;
}

table.grid th,
table.grid td.th {
   text-align: center;
    border: 1px solid  @border-color;
}

table.grid td {
    background: #FFF;
    border: 1px solid  @border-color;
}

table.gridTopDivider td {
    border-top: 4px solid #666;
}

table.grid th.date {
    width: 70px;
}

table.grid img {
    margin: 0 0 0 3px;
}

table.grid img.center {
    margin-left: auto;
    margin-right: auto;
    display: block;
}


/* immunizations screen code */
table.grid td div > em {
    float: right;
}
table.grid td > em {
    float: right;
}

table#studentImmunizations td.error {
    background: #F5CDCD;
}

table#studentImmunizations td.immunOpt {
    background-image: url("../icon-optional-16x16.png");
}

table#studentImmunizations td.exempt {
    background: #CCC;
    color: #675252;
}

table#studentImmunizations td.immunExempt.immunOpt {
    background-image: url("../icon-exempt-16x16.png"), url("../icon-optional-16x16.png");
    background-position: -webkit-calc(~"100% - 2px") center, -webkit-calc(~"100% - 22px") center;
    background-position: calc(~"100% - 2px") center, calc(~"100% - 22px") center;
}

table#studentImmunizations td>div {
    position: relative;
}

table#studentImmunizations td>div em {
    position: absolute;
    right: 0;
    top: 3px;
}

table#studentImmunizations td>div em+em {
    right: 20px;
}

table#studentImmunizations td>div span {
    float: left;
}

.changereasontext {
    width:100%;
    height:95px;
}
table.grid tfoot td {
    border: 1px solid  @border-color;
    line-height: 20px;
    background: #E7E7E7;
}

table.grid tfoot img {
    margin: 0 0 -3px 0;
    padding: 0;
}

table.grid tfoot ul {
    margin: 0;
    padding: 0;
}

table.grid tfoot li {
    text-decoration: none;
    list-style-type: none;
    display: inline;
    margin: 0 20px 0 0;
}
/*end table grid styles */

/* NEWTABLE old styles for: Sched_Matrix_DisplaySections*/
.NEWTABLE {
    border-spacing: 1px !important;
    border-collapse: separate !important;
    table-layout: fixed;
    word-break: break-all;
}

.NEWTABLE td {
    font-weight: normal;
    padding: 0 2px;
}
/* new schedMatrixDisplay */
#schedMatrixTable {
    border-collapse: separate;
}

#schedMatrixTable th,
#schedMatrixTable td {
    vertical-align: middle;
    border: 1px solid  @border-color;
    border-bottom: 2px solid #666;
}

#schedMatrixTable td.header {
    /* old school 4D TH styles... they didn't use th tags */
    font-weight: bold;
    color: #000;
    height: 1em;
    padding: 0 2px;
    background: @light-med-blue;
}
#schedMatrixTable div {
    border: 1 px solid  @border-color;
}

#schedMatrixTable p {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
}
.sched-course-name {
    font-weight: bold;
}
.level1 {
    background-color: rgba(0, 0, 0, 0.25);
}

.level2 {
    background-color: rgba(0, 0, 0, 0.2);
}

.level3 {
    background-color: rgba(0, 0, 0, 0.15);
}

.level4 {
    background-color: rgba(0, 0, 0, 0.1);
}

.level5 {
    background-color: rgba(0, 0, 0, 0.05);
}

.section-conflict {
    background-color: rgba(255, 0, 0, 0.6);
}

#schedMatrixTable .white {
    background-color: rgba(255, 255, 255, 1);
}

td.subhead {
    color: #000;
    text-indent: 10px;
    vertical-align: top;
}

.fourDTable .subhead strong {
    font-weight: normal;
}

table.sectionsScheduleTable.grid td {
    max-width: 100px;
}

table.sectionsScheduleTable.grid tr:first-child td {
    /* should be th tags needs 4j code change */
    background-color: @light-med-blue;
    color: #000;
    font-weight: bold;
    height: 1em;
    padding: 0 2px;
}

#brij-standards td div {
    width: 400px;
}

#accountManagementStudentsBox {
    width: 400px;
    margin: 0 auto;
}

#accountManagementStudentsBox table {
    /* recheck bad */
    width: 100%;
}

#content-main #divAttendance>table {
    border: 1px solid #666;
}

table table,
.box-round table table,
table.linkDescList table {
    width: auto;
    min-width: 200px;
    margin: 0;
  /* blocks parent table row color */
  background-color: #FFF;
  border: 1px solid @border-color;
}

table #idColumnList,
table #idColumnSelected {
    width: 100%;
}

td[background] {
    padding: 0;
}

td[background] img,td[width="25"] img {
    display: block;
}

td[width="25"] {
    padding: 0;
    background-color: transparent;
}

.psteacher .grid2 td:first-child,
.normal{
    font-weight: normal;
}
/*legacy table styles*/
tr.hiddenRow {
    display: none;
}

/* end legacy table styles */
/* Zebra Stripe Code */

table.grid tr:nth-child(even) > td{
    background-color: #FFF;
}
tr.alt,
tr.oddRow > td,
tr.oddrow > td,
div.oddRow,
tr[bgcolor="#edf3fe"] > td,
td[bgcolor="#edf3fe"] {
  /* SR legacy zebra color set */
  background-color: @zebra;
}

table.grid,
table.linkDescList,
table.zebra {
  tr.alt,
  tr.oddRow,
  tr.oddrow {
    background-color: transparent;
  }
  tbody {
    tr:nth-child(odd)>td {
      background-color: @zebra;
    }
    &.tbody {
      th {
        text-align: left;
      }
      tr:nth-child(odd) th {
        background-color: @zebra;
      }
      tr:nth-child(even) th {
        background-color: white;
      }
    }
  }
  table,
  table & {
    tr:nth-child(odd){
      > td {
        background-color: @zebra;
      }
    }
  }
}

.gridWidget {
  tbody {
    tr:nth-child(odd) {
      background-color: @zebra;
    }
  }
}

tbody.tbody.center-cell-content td{
    text-align: center;
}
.alt2 {
    border-top: 1px solid #000;
    background: #f6f6f6;
    margin: 20px 0 0 0;
}

/* Nested Table Zebras */
table table tbody tr:nth-child(odd)>td {
    background-color: @zebra;
}

table table tr:nth-child(odd)>td {
    background-color: @zebra;
}

table.linkDescList {
  col:first-child {
    width: 40%;
  }
  col:nth-child(2) {
    /* this forces the second column to take up as much space as possible, which further allows 3..n columns to be minimal width AND aligned */
    width: 60%;
  }
  .standardContainer & col:nth-child(2) {
    /* this forces the second column to be auto width for the student standards page*/
    width: auto;
  }
  &.colgroup3 col:first-child {
    width: 20%;
  }
  td > a.button:first-child,
  td > button:first-child {
    margin-left: 0;
  }
}

table.altZebra {
  background-color: #FFF;
  tr:nth-child(odd) {
    background-color: #E7E7E7;
  }
}

/* turn zebras off for some conditions */
tr[bgcolor="#edf3fe"] td.notInSession,
tr[bgcolor="#FFFFFF"] td.notInSession{
  background-color: transparent;
}

table.linkDescList {
  tr:nth-child(odd) {
    td.notInSession {
      background-color: transparent;
    }
  }
  tbody tr:nth-child(even) {
    > td.notInSession {
      background-color: transparent;
    }
  }
}

tr[bgcolor="#edf3fe"] td.ui-state-highlight,
tr[bgcolor="#FFFFFF"] td.ui-state-highlight {
    background-color: @light-med-blue;
}

table.grid {
  tr.total {
    th, td {
      font-weight: bold;
      border-top-width: 2px;
      border-top-color: #000;
    }
  }
  th, td {
    &.off {
      background-color: transparent;
      border: none;
      cursor: not-allowed;
    }
  }
  td.warn {
    background-color: #FF6600;
  }
  td.immunError,
  tr:nth-child(even) td.immunError,
  tr:nth-child(odd) td.immunError {
      background-color: #F5CDCD;
  }
  td.immunExempt,
  tr:nth-child(even) td.immunExempt,
  tr:nth-child(odd) td.immunExempt {
    background-color: #CCC;
    color: #666;
  }
  tr:nth-child(even) > td.ui-state-highlight,
  tr:nth-child(odd) > td.ui-state-highlight {
    background-color: @light-med-blue;
  }
  tr:nth-child(even) > td.notInSession,
  tr:nth-child(odd) td.notInSession {
      background-color: transparent;
    }
  tr:nth-child(even),
  tr:nth-child(odd){
    th, td {
      &.off {
        background-color: transparent;
        border: none;
      }
    }
  }
}


/* tlistChildTable styles used for Child Table Extensions (SEPC-17) */
.tlistCollectionTable>table col:first-child {
    width: auto;
}

.tlistCollectionTable>.button-row {
    padding-bottom: 0;
}

.tlistCollectionTable>.button-row+table {
    margin-top: 0;
}

.tlistCollectionTable tr.new {
    display: none;
}

.tlistCollectionTable button.deleteRow,
.tlistCollectionTable button.undoDelRow {
    width: 21px;
    height: 21px;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.tlistCollectionTable button.deleteRow em,
.tlistCollectionTable button.undoDelRow em {
    background: url("../img/jquery/ui-icons_FFFFFF_256x240.png") no-repeat scroll -64px -127px transparent;
    display: inline-block;
    height: 16px;
    width: 16px;
    margin: 0;
    overflow: hidden;
}

.tlistCollectionTable button.undoDelRow em {
    background-position: 0 -63px;
}

.tlistCollectionTable tr.rowToDelete,
.markedDelete {
    background-color: #EEE !important;
    color: #CCC;
    .checkmark-icon em {
        background-image: url(../img/icon-check-grey.svg);
    }
    a {
        color: #CCC;
    }
}

.tlistCollectionTable input[readonly],
.tlistCollectionTable textarea[readonly]
    {
    border: @border-style;
    background-color: transparent;
}

tr.row-alt>td,#search-container .yui-dt-even {
    background-color: #EDF3FE;
}

/* end table styles */

.titledList {
    margin-left: 20px;
    margin-right: 20px;
    > div:first-of-type h3 {
        border: none;
    }
    h3 {
        font-weight: bold;
        border-top: 1px solid @border-color;
        border-bottom: none;
        line-height: 2;
        Padding-top: 10px;
        padding-left: 10px;
        font-size: 100%;
        margin: 0;
        span {
            float: none;
            font-weight: inherit;
            text-align: left;
            margin-right: 0;
        }
        strong {
            font-weight: bold;
        }
        em {
            font-weight: normal;
            font-style: normal;
        }
    }
    p {
        margin-inline-end: 10px;
        margin-bottom: 10px;
        margin-inline-start: 10px;
        padding: 10px 10px 10px 30px;
        position: relative;
        &:before {
            position: absolute;
            display: inline-block;
            width: 16px;
            height: 16px;
            text-align: center;
            top: 10px;
            left: 7px;
        }
        &:nth-of-type(odd) {
            background-color: @zebra;
        }
        &+h3 {
            margin-top: 10px;
        }
    }
}


/* Filters */
.psfilter-exclude {
    /* table filter box */
    background-color: #E9E9E9;
    padding: 5px;
    font-weight: bold;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.psfilter-exclude+table {
    margin-top: 0;
}

h2.filtersHeader span {
    font-size: 80%;
    font-weight: normal;
    color: DarkRed;
    max-width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 5px;
}

table.resultsFilters {
    margin: 0;
    border-bottom: 1px solid #CCC;
}

.box-round table.resultsFilters+.box-round h2 {
    margin-top: 0;
}

table.resultsFilters td {
    padding: 2px 4px;
    vertical-align: top;
}

table.resultsFilters label {
    padding: 0 3px 0 0;
    text-align: right;
    vertical-align: middle;
    width: 30%;
    display: inline-block;
    line-height: 1.2em;
}

table.resultsFilters input[type="text"],table.resultsFilters input[type="textfield"],table.resultsFilters select
    {
    width: 65%;
    vertical-align: middle;
}

table.resultsFilters input.psDateWidget {
    max-width: 89px;
    vertical-align: middle;
}

table.resultsFilters input[type="checkbox"] {
    vertical-align: middle;
}

table.resultsFilters input[type="checkbox"]+label {
    width: 30%;
    text-align: left;
    padding: 0;
}

.dialogLoading .ui-dialog-titlebar-close {
    display: none;
}


.psteacher #teacherSectionTable {
  img {
    vertical-align: middle;
  }
  a {
    border-radius: @border-radius-med;
    display: block;
    padding: 2px;
    &:hover {
      background-color: @light-med-blue;
    }
  }
  tr:nth-child(odd) {
    background-color: @zebra;
  }
  td {
    vertical-align: middle;
    text-align: center;
    &:nth-of-type(1) {
        width: 100px;
    }
    &:nth-of-type(2) {
      font-weight: bold;
      text-align: left;
    }
  }
}
#tableContent img {
  vertical-align: middle;
}

/*Feedback Styles*/
#barberpole,
.barberpole {
    margin: 20px auto 0;
    display: block;
    width: 100%;
    text-align: center;
}

@-webkit-keyframes barberpoleKeyFrames {
   from { background-position:0 0;}
   to {    background-position: 60px 30px;}
}
@keyframes barberpoleKeyFrames {
    from { background-position:0 0;}
    to { background-position: 60px 30px; }
}
#loading {
    height: 12px;
    padding: 5px 15px;
    overflow: hidden;
    border: none;
}

#loading .ui-progressbar-value,.barberpole .ui-progressbar-value {
    background: @med-blue;
}

#loading .ui-progressbar-overlay {
    background-size: 30px 30px;
    background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.9) 25%,
        rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.9) 50%,
        rgba(0, 0, 0, 0.9) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    -webkit-animation: barberpoleKeyFrames 0.7s linear infinite;
    animation: barberpoleKeyFrames 0.7s linear infinite;
}

/**
* End of progress spinner styles
*/
/**
* centeringContainer will center its contents inside a wrapping element
* that has property of position:relative (or similar)
* - Used for progress spinner
*/
.centeringContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    z-index: 999;
}

/**
* (C)Leanest CSS spinner ever
* from http://lea.verou.me/2013/11/cleanest-css-spinner-ever/
*/
@keyframes spin {
    to { transform: rotate(1turn); }
}
@-webkit-keyframes spin {
    to { -webkit-transform: rotate(1turn); }
}
.progress {
    position: relative;
    display: inline-block;
    width: 5em;
    height: 5em;
    margin: 0 .5em;
    font-size: 8px;
    text-indent: 999em;
    overflow: hidden;
    -webkit-animation: spin 1s infinite steps(8);
    animation: spin 1s infinite steps(8);
}
.small.progress {
    font-size: 4px;
}
.large.progress {
    font-size: 12px;
}
.progress:before,
.progress:after,
.progress > div:before,
.progress > div:after {
    content: '';
    position: absolute;
    top: 0;
    left: 2.25em; /* (container width - part width)/2  */
    width: .5em;
    height: 1.5em;
    border-radius: @border-radius-small;
    background:  rgba(0,72,144,0.2);
    box-shadow: 0 3.5em  rgba(0,72,144,0.2); /* container height - part height */
    transform-origin: 50% 2.5em; /* container height / 2 */
    -ms-transform-origin: 50% 2.5em; /* container height / 2 */
    -webkit-transform-origin: 50% 2.5em; /* container height / 2 */
}
.progress:before {
    background: rgba(0,72,144,1);
}
.progress:after {
    background:  rgba(0,72,144,0.9);
    transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
.progress > div:before {
    background:  rgba(0,72,144,0.8);
    transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
}
.progress > div:after {
    background:  rgba(0,72,144,0.7);
    transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}
/**
* End of progress spinner styles
*/

#pageLevelModAccess {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #FFCC66;
    background-color: rgba(255, 204, 102, 0.8);
    border-radius: @border-radius-med;
    Padding: 0 10px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4);
    width: 100%;
    font-weight: bold;
    z-index: 99000;
}

.gray { /*for disabled links*/
    color: gray;
}

#addressValidationMap {
    background: #FFF;
}

.feedback-confirm,
.feedback-caution,
.feedback-alert,
.feedback-error,
.feedback-note,
.feedback-info {
    ul {
        margin-top: 10px;
    }
}

table.grid td.feedback-caution,
.feedback-confirm,
.feedback-caution,
.feedback-alert,
.feedback-error,
.feedback-blocker,
.feedback-note,
.feedback-info,
.pslogin .error    {
    padding: 2px 4px 2px 30px;
    margin: 0 20px 20px;
    border-radius: @border-radius-med;
    background-position: 4px 4px;
    background-repeat: no-repeat;
    position: static;
  .box-round &,
  .ui-accordion & {
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  .box-round label& {
    margin: 4px;
    width: calc(~"35% - 30px");
    border-radius: @border-radius-med;
  }
  p, li {
    margin-left: 0;
    :first-child {
      margin-top: 0;
    }
    :last-child {
      margin-bottom: 0;
    }
  }
  ul {
    margin-left: 14px;
  }
  ol {
    margin-left: 17px;
  }
  ul& li {
    list-style-type: none;
    margin: 0;
  }
  #content-main .feedback-list li,
  .feedback-list li {
    list-style-type: none;
  }
  a {
      text-decoration: underline;
  }
}
div.feedback-list ul li {
  list-style-type: none;
  margin: 0;
}
#content-main .feedback-list li,
.feedback-list li {
  list-style-type: none;
}

.feedback-note {
    color: @alert-info-foreground;
    background-color: @alert-info-background;
    background-image: url("../img/icon-note.svg");
    border: 1px solid @alert-info-foreground;
}

[dir=rtl] .feedback-note {
  background-position: right 5px top 5px;
}

.feedback-info {
    color: @alert-info-foreground;
    background-color: @lightest-blue;
    background-image: url("../img/icon-info.svg");
    border: 1px solid @alert-info-foreground;

    &.fieldset-info {
	    margin-top: 4px;
    }
}

[dir=rtl] .feedback-info {
  background-position: right 5px top 5px;
}

.feedback-active {
  border: 2px solid @alert-info-foreground;
}

.feedback-running {
    color: inherit;
    background-color: #FFF;
    background-image: url("../tab-images/spinner.gif");
    padding: 0 0 0 50px;
    line-height: 2.5em;
}

[dir=rtl] .feedback-running {
  background-position: right 5px top 5px;
}

.feedback-confirm {
    color: @alert-success-foreground;
    background-color: @alert-success-background;
    background-image: url("../img/icon-check.svg");
    border: 1px solid @alert-success-foreground;
}
[dir=rtl] .feedback-confirm {
  background-position: right 5px top 5px;
}

table.grid td.feedback-caution,
.feedback-alert,
.feedback-caution,
.pslogin .error {
    background-color: @alert-warn-background;
    background-image: url("../img/icon-caution.svg");
    max-height:15em;
    overflow: auto;
    color: @alert-warn-foreground;
    border: 1px solid @alert-warn-foreground;
}
[dir=rtl] table.grid td.feedback-caution,
[dir=rtl] .feedback-alert,
[dir=rtl] .feedback-caution,
[dir=rtl] .pslogin .error {
  background-position: right 5px top 5px;
}

.feedback-alert-icon, .ui-widget-content .feedback-alert-icon {
    background-image: url("../img/icon-caution.svg");
}
[dir=rtl] .feedback-alert-icon, [dir=rtl] .ui-widget-content .feedback-alert-icon {
  background-position: right 5px top 5px;
}

table.grid tbody tr.feedback-alert {
    > td {
        background-color: @alert-warn-background;
        color: @alert-warn-foreground;
        > a {
            text-decoration: none;
        }
    }
    button {
        background-color: @alert-warn-foreground;
    }
}

table.grid tr td.feedback-caution,
table.grid tr td.feedback-caution:nth-child(even) {
    background-color: @alert-warn-background;
    border: 1px solid  @border-color;
    color: black;
}

.feedback-error {
    background-color: @alert-danger-background;
    background-image: url("../img/icon-error.svg");
    color: @alert-danger-foreground;
    border: 1px solid @alert-danger-foreground;
}

[dir=rtl] .feedback-error {
  background-position: right 5px top 5px;
}

.feedback-blocker {
    background-color: @alert-danger-background;
    background-image: url("../img/icon-error.svg");
    max-height: 15em;
    overflow: auto;
    color: @alert-danger-foreground;
    border: 1px solid @alert-danger-foreground;
}

[dir=rtl] .feedback-blocker {
  background-position: right 5px top 5px;
}

.ui-dialog .ui-dialog-titlebar.feedback-alert {
    color: @alert-warn-foreground;
    background-color: @alert-warn-background;
    background-image: url("../img/icon-error.png");
    padding: 0 0 3px 30px;
}

[dir=rtl] .ui-dialog .ui-dialog-titlebar.feedback-alert {
  background-position: right 5px top 5px;
}

tr.feedback-alert,
td.feedback-alert {
    padding: 0 0 3px 30px;
    color: @alert-warn-foreground;
    background-color: @alert-warn-background;
}

#content-main .box-round h2.feedback-alert {
    padding: 3px 0 3px 30px;
}

span.feedback-alert,
span.feedback-confirm,
body.pslogin span.error {
    display: block;
}
.psDialog #feedbackMarker {
    margin: 10px 0 15px 0;
}
#feedback-message {
    border-radius: @border-radius-med;
    margin: 0 20px 10px;
    position: relative;
    top: 0;
    z-index: 2;
}

#feedback-message.floaty {
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.4);
    width: 78%;
    position: fixed;
    top: 10px;
}
#psDialogDocked #feedback-message.floaty {
    top: 35px;
}

.psDialog #feedback-message,
.psDialog #feedback-message.floaty{
    width: auto;
}
#container-frame #feedback-message.floaty {
    width: 95%;
}

#content-main #feedback-message ul {
    margin-left: 13px;
}

#content-main #feedback-message ol {
    margin-left: 19px;
}

#content-main #changes-saved-container {
    margin: 0 20px 10px;
}

select.error { /* keeps select options black*/
    color: #000;
}

label.error {
    color: #FF6600;
    display: inline-block;
    font-style: italic;
    font-weight: normal;
}
div.error-with-images:before {
  content: url("../error.png");
  padding-right: 6px;
}
div.error-with-images:after {
  content: url("../error.png");
  padding-left: 6px;
}
div.error-with-images{
  color: #FF0000;
  display: block;
  font-weight: bold;
  background-repeat: no-repeat;
}
input.one-char{
  width:1ch;
}
input.required+em,select.required+em,textarea.required+em,.ui-datepicker-append+em,.ui-datepicker-trigger+em {
    font-size: 150%;
    margin-left: 5px;
    vertical-align: top;
    padding-inline-start: 4px;
}

[dir=rtl] .ui-icon-triangle-1-e { background-position: -96px -16px; }

.error,.red { /*for address validation*/
    color: #990000;
}

.grey {
    color: @palette-grey-1;
}

input[class^="timeEntry"].error {
    background-color: rgba(100%, 89%, 71%, 0.5);
    border: 1px solid DarkRed;
}

input.error,
select.error {
    border: 1px solid DarkRed;
    box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.2);
    background: rgba(100%, 89%, 71%, 0.5);
}

#content-main .box-round #feedback p {
    margin: 0;
}

.error-message,.ui-dialog p.error-message {
    background: transparent url("../img/icon-error-sml.gif") no-repeat
        scroll 0 4px;
    font-weight: bold;
    color: @alert-danger-foreground;
    padding: 2px 2px 2px 16px;
    font-style: italic;
    font-size: 13px;
    line-height: 1.2em;
    [dir=rtl] & {
        background-position: right;
    }
}

[dir=rtl] p.error-message {
  background-position: right;
}

.ui-dialog p.error-message {
    margin: 0;
}

.box-round .error-message {
    margin: 0;
}

li.error {
    padding: 2px 0;
    background: #FFB943 url("../error.png") no-repeat left center;
}

/*individual implementation will define the width/max-width*/
span.ellipsis {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
    text-overflow: ellipsis;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/* Set as important because it overrides display:none ... fallback for non-CSS-ified pages in SR */
#sessiontimeoutwarning.visible {
    display: block !important;
}
/* Should be set above everything else in PowerSchool, including the psDialogs at 99000, calendars at 99200, and our tooltip at 99500 */
#sessiontimeoutwarning {
    background: url("../img/lock1.png") no-repeat scroll 30px center #FFDDAB;
    background-color: rgba(255, 190, 80, 1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99900;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.7);

  &.signedout {
    background-image: url("../img/lock2.png");
}
  h2 {
    margin-top: 10px;
  }
  p {
    width: -webkit-calc(~"100% - 180px");
    width: calc(~"100% - 180px");
  }

  button {
    margin: 0;
    position: absolute;
    right: 20px;
    bottom: 10px;
  }
  .signedout {
    display: none;
  }
  &.signedout {
    .signedout {
      display: block;
}
    .warning {
      display: none;
}
}

#sessiontimeoutwarningBox {
    margin: 10px 20px 10px 100px;
    padding: 1px;
    background: #FFF;
    background: rgba(255, 255, 255, 0.8);
    position: relative;
    background: #FFF;
      border: 1px solid #999;
}
}
.signedout.ui-widget-overlay.ui-front.security-overlay {
    background: url("../table-back-stripes.png") repeat;
    opacity: 0.95;
    z-index: 99800;
}

#sessionrestore {
    background: url("../img/resume1.png") no-repeat scroll 5px center
        #FFDDAB;
    background-color: rgba(255, 190, 80, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99900;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.7);
      p {
      width: -webkit-calc(~"100% - 140px");
      width: calc(~"100% - 140px");
}

#sessionRestoreBox {
    margin: 10px 20px 10px 110px;
    padding: 1px;
    background: #FFF;
    background: rgba(255, 255, 255, 0.8);
    position: relative;
      .buttons {
    margin: 0;
    position: absolute;
    right: 20px;
    bottom: 30%;
    width: 100px;
}
  }
}




/* legend styles */
#legend {
    border: @border-style;
    border-radius: @border-radius-med;
    margin: 10px 20px;
    background-color: #ffffff;
  .box-round & {
    margin-right: 10px;
    margin-left: 10px;
  }
  h2, h3 {
    font-size: 110%;
    font-weight: bold;
    margin: 0 10px 10px 10px;
    padding: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

  p {
    font-size: 90%;
    color: #666;
    padding: 0 10px;
    margin: 0 0 5px 0;
  }

  strong {
    color: #666;
  }

  img {
    vertical-align: middle;
    height: 16px;
    width: 16px;
    margin-inline-end: 1.3px;
  }
  /* end Legend styles */
}

.noprint {
    display: block;
    width: 75%;
    margin: 20px auto;
    padding: 10px;
}

.noprint-gradplan {
    padding: 0;
    margin: 0 20px 10px;
    width: auto;
}

.noprint.box-round {
    padding: 0;
    float: none;
}

.noprint h1 {
    margin: 20px 0;
}

.pagebefore {
    page-break-before: always;
}

.pageafter {
    page-break-after: always;
}

@import url("videos.less");

/* Gradebook launch styles */
#ptgInstall #installOpts>h2 {
    margin: 20px 0;
}

#ptgNewLaunchBox {
    background-color: #FFFFFF;
    border: @border-style;
    margin: 20px 10px 0;

  h2 {
    background-color: @dark-blue;
    color: #FFF;
    font-size: 85%;
    margin: 0 0 5px;
    padding-left: 5px;
  }
  p {
    margin: 0 5px 5px;
    text-align: center;
    line-height: 1.2em;
  }
  hr {
    border-color: #666;
      margin-bottom: 0;
  }
  a.button {
    margin: 0;
    vertical-align: bottom;
  }
}


#ptGradebookLaunch article {
    width: -webkit-calc(~"100% - 254px");
    width: calc(~"100% - 254px");
    min-width: 250px;
    vertical-align: top;
    display: inline-block;
}

#ptGradebookLaunch aside {
    width: 250px;
    vertical-align: top;
    display: inline-block;
}

#ptGradebookLaunch aside .box-round {
    margin-left: 0;
}

.nocalc #ptGradebookLaunch article {
    vertical-align: top;
    width: 70%;
}

.nocalc #ptGradebookLaunch aside {
    width: 29%;
}

#ptGradebookLaunch p.ptgIntro,#ptGradebookLaunch p.ptgaIntro,#ptGradebookLaunch p.psourceIntro
    {
    min-height: 50px;
}

#ptGradebookLaunch img.left {
    float: left;
    margin: 0 10px 0 0;
}

#ptGradebookLaunch p>img,#psReportWorksLaunch p>img {
    float: left;
    margin: 0 5px 5px 0;
}

/* end Gradebook Launch styles */
.account-photo img,.user-photo img {
    padding: 2px;
    margin: 5px 10px 10px 10px;
    border: @border-style;
    background-color: #EEE;
    max-height:100%;
    max-width:100%;
}

.account-photo img {
    float: left;
}
.user-photo {
    text-align: center;
}

.user-photo img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.user-photo-container {
    min-width: 500px;
    text-align: center;
}

.ssoLogoutBody {
    height: 100%;
    line-height: 1.5em;
}

.ssoLogoutLineSpacer {
    height: 15px;
}

.ssoLogoutBoxRound {
    border: @border-style;
    background: #FFF;
    border-radius: @border-radius-med;
    display: table;
    float: left;
    padding: 30px 30px 15px 30px;
    margin: 10px 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
    position: relative;
}

.floatleft {
    float: left;
}

.floatright {
    float: right;
}

.posRel {
    position: relative;
    //min-height: 95px;
}

.posRel .absRight {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
}

/* THIS IS A HACK - DO NOT USE */
/* This is for the course availability page. */
/* It has buttons positioned right in jqgrid table cells */
/* and this is the only place in PowerSchool we do this */
.course-availability-right-button {
    position: absolute;
    top: 1px;
    right: 2px;
    margin: 0;
    bottom: 1px;
    line-height: 75%;
}

.posRel .absLeft {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
}

.ssoLogoutContainer {
    margin-right: auto;
    margin-left: auto;
    width: 35em;
}

.ssoLogoutPowerSchoolLogo {
    text-align: center;
    width: 35em;
    margin-top: 2em;
}

.ssoLogoutContent {
    float: left;
    width: 30em;
    margin: 1em;
}

.ssoLogoutAnchorPaddingLeft {
    padding-left: 1em;
}

.ssoLogoutButtonContainer {
    float: left;
    width: 29em;
    margin-bottom: 2em;
}

.ssoLogoutButton {
    width: 19em;
}

/*panel styles*/
#panel_requestInProcess_mask {
    z-index: 5;
    height: 1813px;
    width: 1087px;
}
/* dialog styles */
.psDialog {
    min-width: 300px;
}

#psDialogDocked.psDialog .box-round {
    width: calc(~"100% - 22px");
}

#psDialogDocked.psDialog .box-round-nested {
    width: calc(~"100% - 32px");
}

#psDialogDocked.psDialog .box-round-comment {
    margin: 20px;
    width: calc(~"100% - 42px");
    padding: 2px 5px;
    min-height: 50px;
}

#psDialogDocked.hasButtonRow {
    height: calc(~"100% - 80px");
}

#psDialogDocked {
    height: calc(~"100% - 37px");
}

.ui-dialog.psAlert .psDialog {
    padding: 0.5em 1em;
}
/* end dialog styles */

@import url("pshelp.less");

/*legacy styles from student page*/
#cachetool {
    clear: both;
}

/*Legacy fixes for Parent Portal*/
#content>table {
    width: 96%;
    margin: 20px;
}

span.header {
    font-size: 130%;
    font-weight: bold;
    color: #000;
    margin: 20px 0 10px 0;
}

p>b {
    font-size: 110%;
    font-weight: bold;
    color: #000;
    margin: 5px 0 10px 5px;
}

/*print specific hides*/
#print-disclaimer,#print-school {
    display: none;
    visibility: hidden;
}

p.messageText {
    color: @alert-danger-foreground;
    margin-bottom: 8px;
}

div.messageBlock {
    text-align: center;
}

span.bold-underline a {
    font-weight: bold;
    text-decoration: underline;
}

.divPwdRule {
    padding: 0 10px;
}

table.wwFormTable {
    margin: 0;
    padding: 0;
    width: 100%;
    /*border:none !important;*/
}

table.wwFormTable tr:nth-child(odd) {
    background-color: @zebra;
}

/*Course Request style corrections*/
.grouptitle_name {
    color: #000;
    font-family: Helvetica, Arial, sans-Serif;
    font-variant: normal;
    letter-spacing: normal;
}

.req_displaycourse {
    background: #F3F3F3;
    border: 1px solid  @border-color;
    border-radius: @border-radius-med;
    padding: 2px;
}

#iPhone-nav-main {
    display: none;
}

#nav-main-phone {
    display: none;
}

/*Hide Reveal code*/
a.reveal {
  &:after {
    content: "";
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-left: 10px;
    .button-style;
    vertical-align: middle;
    background-image: url("../img/jquery/ui-icons_FFFFFF_256x240.png");
    background-position: -64px -112px;
    background-repeat: no-repeat;
  }
  &:hover {
    &:after{
      .button-style-hover;
    }
  }
}

div.edit div:first-child {
    padding-top: 0;
}

fieldset.edit {
    margin: 10px 0;
}

.edit label {
    margin: 0 -16px 0 20px !important;
}

/*dashboard styles */
#dashPortlets {
    margin: 0 0 20px;
}

#dashPortlets table {
    margin: 0 !important;
    width: 100%;
}

#dashPortlets table tr:nth-child(even) {
    background-color: @zebra;
}

#dashPortlets>div {
    width: -webkit-calc(~"50% - 32px");
    width: calc(~"50% - 32px");
    line-height: 1.5em;
    margin: 0 0 20px 20px;
    display: inline-block;
    vertical-align: top;
}

#dashPortlets>object {
    margin: 0;
    padding: 0;
    line-height: 0;
}

#dashPortlets>div>div {
    display: inline-block;
    width: -webkit-calc(~"100% - 2px");
    width: calc(~"100% - 2px");
    border: @border-style;
    border-top: none;
}

#dashPortlets h3 {
    background: #E9E9E9;
    margin: 0;
    padding-left: 5px;
    border: @border-style;
    border-radius: 4px 4px 0 0;
    position: relative;
}

#dashPortlets h3 a {
    float: right;
    padding: 0 5px;
    color: #000;
    position: absolute;
    right: 2px;
}

#dashPortlets label {
    float: left;
    margin-right: 10px;
    width: 45%;
}
/*dashboard styles */
/*Health*/
table[id$=screening-value-table] tr:nth-child(even).disabled > td,
table[id$=screening-value-table] tr:nth-child(odd).disabled > td,
.disabled td a{
    color: #666;
    background-color: rgba(200,200,200,0.4);
}

table[id$=screening-value-table] tr{
    height:40px;
}
table[id$=screening-value-table] th {
    width: auto;
}
table[id$=screening-value-table] th:nth-of-type(1),
table[id$=screening-value-table] td:nth-of-type(1) {
    width: auto;
}
table[id$=screening-value-table] th:nth-of-type(2),
table[id$=screening-value-table] td:nth-of-type(2) {
    width: 25em;
}
table[id$=screening-value-table] th:first-child,
table[id$=screening-value-table] td:first-child {
    width: 5%;
    text-align: center;
}

table[id$=screening-value-table] td{
    vertical-align:middle;
}
table[id$=screening-value-table] tr.feedback-success td:first-child, td.feedback-success {
    background-image: url("../img/icon-check.svg");
    background-repeat: no-repeat;
    background-position: center center;
}
table[id$=screening-value-table] tr:nth-child(odd).highlight td,
table[id$=screening-value-table] tr:nth-child(even).highlight td{
    background-color: @light-med-blue;
    color: #FFFFFF;
}

table[id$=screening-value-table] tr:nth-child(odd).highlight td a,
table[id$=screening-value-table] tr:nth-child(even).highlight td a{
    color: #FFFFFF;
}

div#screening-panel div#allScreenings {
    margin-bottom: -12px;
}
.screening-detail-inner-label {
    min-width: 5px;
}

#screening-panel select{
    max-width: -webkit-calc(~"100% - 35% - 25px");
    max-width: calc(~"100%-35%-25px");
}

#vision-box tr.oddRow,#hearing-box tr.oddRow {
    background-color: rgba(215, 235, 244, 0.7);
}

#vision-box tr.evenRow,#hearing-box tr.evenRow {
    background-color: rgba(255, 255, 255, 0.7);
}

#vision-box {
    background-image: url("../img/eye-right.png"),
        url("../img/eye-left.png");
    background-position: 5% 20%, 95% 20%;
    background-size: 30% auto;
    background-repeat: no-repeat;
}

#hearing-box {
    background-image: url("../img/ear-right.png"),
        url("../img/ear-left.png");
    background-position: 5% 90%, 95% 90%;
    background-size: auto 90%;
    background-repeat: no-repeat;
}

.txtctr {
    text-align: center;
}

.overrideItalic{
    font-style:normal;
}

/* form styles*/
.formList { /*legacy*/
    list-style-image: none;
    list-style-type: none;
    margin-bottom: 0;
    padding: 0;
}

.formList li {
    list-style-image: none;
    list-style-type: none;
    margin: 0;
    padding: 4px 0;
    width: 100%;
}

.formList li label {
    width: 30%;
    text-align: right;
    float: left;
    vertical-align: bottom;
    font-weight: normal;
}

/* tabs and boxes*/
.box-grey {
    padding: 0;
    margin: 0 0 10px 0;
    border: 1px solid  @border-color;
    width: 98%;
    position: relative;
}

.box-grey h4 {
    background: #D3E0F0;
    border-bottom: 1px solid  @border-color;
    padding: 0 0 0 2px;
}

#euaa {
    background: #EDF3FE;
    border: 1px solid #125D9D;
    border-radius: @border-radius-med;
    overflow: hidden;
    width: 98%;
}

#euaa span {
    float: left;
    margin: 10px;
    width: 84%;
    line-height: 2em;
}

#eauu span input {
    border: 1px solid #6599CC;
    vertical-align: middle;
}

#euaa em {
    float: right;
    margin: 10px;
}
/* student immunizations */
#cellEdit ul,#exemptCell ul {
    padding: 2px;
    margin: 0;
}

[dir=rtl] #cellEdit ul,#exemptCell ul {
     padding: 8px;
     margin: 0;
}
#flex-basis-increase {
    flex-basis: 126px;
}
[dir=rtl] #exemptCell{
    inset-inline-start:50px;
}

#cellEdit ul li,#exemptCell ul li {
    list-style-type: none;
}

#cellEdit:before,#exemptCell:before {
    height: 16px;
    width: 16px;
    background:
        url("data:image/svg+xml;base64, PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+DQo8IS0tIENyZWF0ZWQgd2l0aCBJbmtzY2FwZSAoaHR0cDovL3d3dy5pbmtzY2FwZS5vcmcvKSAtLT4NCg0KPHN2Zw0KICAgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIg0KICAgeG1sbnM6Y2M9Imh0dHA6Ly9jcmVhdGl2ZWNvbW1vbnMub3JnL25zIyINCiAgIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyINCiAgIHhtbG5zOnN2Zz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciDQogICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciDQogICB2ZXJzaW9uPSIxLjEiDQogICB3aWR0aD0iMTYiDQogICBoZWlnaHQ9IjE2Ig0KICAgaWQ9InN2ZzExMTUxIj4NCiAgPGRlZnMNCiAgICAgaWQ9ImRlZnMxMTE1MyIgLz4NCiAgPG1ldGFkYXRhDQogICAgIGlkPSJtZXRhZGF0YTExMTU2Ij4NCiAgICA8cmRmOlJERj4NCiAgICAgIDxjYzpXb3JrDQogICAgICAgICByZGY6YWJvdXQ9IiI+DQogICAgICAgIDxkYzpmb3JtYXQ+aW1hZ2Uvc3ZnK3htbDwvZGM6Zm9ybWF0Pg0KICAgICAgICA8ZGM6dHlwZQ0KICAgICAgICAgICByZGY6cmVzb3VyY2U9Imh0dHA6Ly9wdXJsLm9yZy9kYy9kY21pdHlwZS9TdGlsbEltYWdlIiAvPg0KICAgICAgICA8ZGM6dGl0bGU+PC9kYzp0aXRsZT4NCiAgICAgIDwvY2M6V29yaz4NCiAgICA8L3JkZjpSREY+DQogIDwvbWV0YWRhdGE+DQogIDxnDQogICAgIGlkPSJsYXllcjEiPg0KICAgIDxwYXRoDQogICAgICAgZD0iTSA4LjUxNDM1NjUsMC4wNTI0NzUxMSAwLjA0NTA0OTUxLDEwLjk5OTUwNSAxNS45OTI1NzQsMTEuMDQ0NTU0IHoiDQogICAgICAgaWQ9InBhdGgxMTE2MSINCiAgICAgICBzdHlsZT0iZmlsbDojNjY2NjY2O2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPg0KICA8L2c+DQo8L3N2Zz4=")
        no-repeat scroll center top rgba(0, 0, 0, 0);
    background-size: 100%;
    content: "";
    position: absolute;
    top: -15px;
    left: 10px;
}

#cellEdit,#exemptCell {
    position: absolute;
    border: 1px solid #666;
    border-top-width: 4px;
    box-shadow: 0 5px 10px #666;
    background: #FFF;
}

#cellEdit input,#exemptCell input {
    width: -webkit-calc(~"100% - 50px");
    width: calc(~"100% - 50px");
}

#cellEdit select,#exemptCell select {
    width: 19em;
    font-size: 11px;
}

#exemptCell h3 {
    background-position: 0 -10px;
    font-weight: bold;
    font-size: 100%;
    color: #000;
    line-height: 1.5em;
    overflow: hidden;
    border-bottom: 1px solid #125D9D;
    /*    width: 100%;*/
    border-radius: 0 !important;
}

#exemptCell h3 label {
    font-weight: bold;
    padding: 0 0 0 2px;
}

#exemptCell h3 img {
    float: right;
}

#exemptCell h3 span label {
    float: none;
}

.revertItem {
    display: block;
    margin: 0;
    padding: 0 2px;
    width: 15px;
    height: 15px;
    float: right;
    -moz-opacity: 0.5;
    -webkit-opacity: 0.5;
    opacity: 0.5;
}

.revertItem {
    background: url("../btn_undo.png") no-repeat center center;
    cursor: pointer;
    color: #eee;
    font-size: 0;
}

#id_form_save_alert_message {
    margin-bottom: 10px;
}

/*System Locales page.. maybe global?*/
div.confirm {
    display: none;
}

/*  Tool*/
body.pslogin #feedbackHover,body.frameMenu #feedbackHover,body.popUp #feedbackHover
    {
    display: none;
}

#feedbackHover,#feedbackSend {
    bottom: 0;
    right: 0;
    position: fixed;
    margin: 0;
    padding: 2px 5px;
    font-size: 12px;
    line-height: 1em;
    background: #BED6EA;
    background: rgba(56, 117, 172, .5);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .3);
    border-top-left-radius: 4px;
}

#feedbackSend div {
    padding: 2px 5px;
    border-radius: @border-radius-med;
    border: 1px solid rgba(56, 117, 172, .5);
    display: inline-block;
    line-height: 1em;
    margin: 0 5px 0 0;
    float: none;
    cursor: pointer;
}

#feedbackSend #recordingStop {
    background-color: #FF3333;
}

#feedbackSend #recordingStart {
    background-color: #CCFF99;
}

#feedbackSend #recordingCancel {
    background-color: #111;
}

#feedbackSend #recordingStop:hover {
    background-color: #CC0000;
}

#feedbackSend #recordingStart:hover {
    background-color: #669933;
}

#feedbackSend #recordingCancel:hover {
    background-color: #333333;
}

/* @group Colors used in Schedule Matrix */
.matrix_1 {
    background-color: PowderBlue;
}

.matrix_2 {
    background-color: Peachpuff;
}

.matrix_3 {
    background-color: Gold;
}

.matrix_4 {
    background-color: #BCA6E8;
}

.matrix_5 {
    background-color: LightGreen;
}

.matrix_6 {
    background-color: Khaki;
}

.matrix_7 {
    background-color: Gainsboro;
}

.matrix_8 {
    background-color: SteelBlue;
}

.matrix_9 {
    background-color: IndianRed;
}

.matrix_10 {
    background-color: Goldenrod;
}

.matrix_11 {
    background-color: Orchid;
}

.matrix_12 {
    background-color: MediumAquamarine;
}

.matrix_13 {
    background-color: DarkKhaki;
}

.matrix_14 {
    background-color: LightSlateGray;
}

.matrix_15 {
    background-color: CornflowerBlue;
}

.matrix_16 {
    background-color: LightBlue;
}

.matrix_17 {
    background-color: SandyBrown;
}

.matrix_18 {
    background-color: PaleVioletRed;
}

.matrix_19 {
    background-color: LightGoldenrodYellow;
}

.matrix_20 {
    background-color: Pink;
}

.matrix_21 {
    background-color: DarkSeaGreen;
}

.matrix_22 {
    background-color: Coral;
}

.matrix_23 {
    background-color: DarkGray;
}

.matrix_24 {
    background-color: Salmon;
}

.matrix_25 {
    background-color: Plum;
}

.matrix_26 {
    background-color: MediumSeaGreen;
}

.matrix_27 {
    background-color: Burlywood;
}

.matrix_28 {
    background-color: Silver;
}

.matrix_29 {
    background-color: RoyalBlue;
}

.matrix_30 {
    background-color: Wheat;
}

.matrix_31 {
    background-color: Thistle;
}

.matrix_32 {
    background-color: LightSeaGreen;
}

.matrix_33 {
    background-color: AntiqueWhite;
}

.matrix_34 {
    background-color: SlateGray;
}

.matrix_35 {
    background-color: SkyBlue;
}

.matrix_36 {
    background-color: HotPink;
}

.matrix_37 {
    background-color: LemonChiffon;
}

.matrix_38 {
    background-color: MediumOrchid;
}

.matrix_39 {
    background-color: DarkTurquoise;
}

.matrix_40 {
    background-color: Moccasin;
}

.matrix_41 {
    background-color: Mistyrose;
}

.matrix_42 {
    background-color: RoyalBlue;
}

.matrix_43 {
    background-color: LavenderBlush;
}

.matrix_44 {
    background-color: Beige;
}

.matrix_45 {
    background-color: MediumSlateBlue;
}

.matrix_46 {
    background-color: PaleGreen;
}

.matrix_47 {
    background-color: PaleGoldenrod;
}

.matrix_48 {
    background-color: LightSteelBlue;
}

.matrix_49 {
    background-color: CadetBlue;
}

/* @end of Schedule Matrix colors */

/* psFilePicker style */
label.filePickerClickable {
    width: auto;
    line-height: 2.5em;
}

.ui-dialog label.filePickerClickable {

}
input.psFilePicker+span {
    line-height: 1.3em;
    padding-top: .2em;
    padding-bottom: .2em;
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 5px;
}

div.pickerCount {
    padding: 0 20px;
    display: inline-block;
    vertical-align: top;
}

div.pickerCount.collapsed {
    background: url("../img/arrow-right-blk.svg") no-repeat 0.35em center;
    padding-left: 1.2em;
    cursor: pointer;
    margin-bottom: 0;
}

div.pickerCount.expanded {
    background: url("../img/arrow-down-blk.svg") no-repeat 0.3em center;
    padding-left: 1.2em;
    cursor: pointer;
}

div.fileDataContainer {
    display: block;
    vertical-align: top;
}

div.fileData {
    display: inline-block;
    white-space: nowrap;
}

.multiFile div.fileData {
    padding-right: 16px;
}

.multiFile div.fileDataRow:nth-child(even) {
    background-color: #E7E7E7;
}

span.fileDataElement {
    max-width: 400px;
    padding: 0 5px;
}

span.fileDataElement.size {
    max-width: 100px;
}

span.fileDataElement.sizeAndType {
    max-width: 300px;
}

div.fileDataContainer.multiFile {
    background-color: #FFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    max-height: 10em;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    z-index: 100;
    border: @border-style;
}

div.pickerGlassPane {
    position: fixed;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    background-color: #ccc;
    opacity: 0.3;
    z-index: 99;
    cursor: pointer;
}

fieldset .filePickerDisplay div {
    padding: 0;
}

fieldset.inline span.fileDataElement {
    max-width: 400px;
}

/* end psFilePicker style */
/* attendance taken styles */
#attendanceListTable,#attendanceListTable td {
    width: 100%;
    margin-right: 10px;
    margin-bottom: 0;
    margin-left: 10px;
    padding: 0;
}

#ps-attendance-grid {
    margin: 0;
    /* this is a unique UL, it is wrapped in a table, bad, Old 4J code */
}

#ps-attendance-grid li {
    list-style-type: none;
    width: -webkit-calc(~"33% - 44px");
    width: calc(~"33% - 44px");
    min-width: 150px;
    max-width: 250px;
    margin-right: 5px;
    margin-left: 0;
    display: inline-block;
    cursor: pointer;
  overflow: inherit;
}

/* end attendance taken styles */
/* jquery override styles */

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background-image: none;
  background-color: @light-grey;
}

.ui-widget-overlay {
  opacity: 0.5;
  background-image: url("../table-back-stripes.png");
  background-repeat: repeat;
}
.ui-widget,.ui-widget .ui-widget {
    font: inherit;
}

.ui-widget input,.ui-widget select,.ui-widget textarea {
    font-family: Arial, sans-serif;
    padding: 0;
}
.ui-widget button {
    font-family: Arial, sans-serif;
}
.ui-state-default .ui-icon,
.ui-state-default .ui-widget-content .ui-icon {
    background-image: url("../img/jquery/ui-icons_222222_256x240.png");
}

.ui-state-error-clear {
    border: none;
    background: none;
}

.ui-icon-alert-inline {
    display: inline-block;
    vertical-align: text-top;
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .uiwidget-header .ui-state-highlight,
table.linkDescList tbody tr:nth-child(2n+1) > td.ui-state-highlight {
      background: none repeat scroll 0 0 @light-med-blue;
    border-color: @dark-blue;
      color: inherit;
}

.ui-state-highlight a,.ui-widget-content .ui-state-highlight a,.uiwidget-header .ui-state-highlight a
    {
    color: inherit;
}

.ui-dialog .ui-resizable-se {
    bottom: 5px;
    right: 5px;
}

.ui-dialog .ui-icon-grip-diagonal-se {
    background-position: -64px -224px;
}

.ui-dialog .ui-icon-gripsmall-diagonal-se {
    background-position: -80px -224px;
}

.ui-dialog {
    z-index: 99000;
}

.ui-widget-content {
    background: #fff url("../img/jquery/ui-bg_flat_75_ffffff_40x100.png")
        50% 50% repeat-x;
    border: none;
}

.ui-widget-content li.ui-menu-item.ui-state-focus {
    background-image: none;
    background-color: rgba(178, 208, 233, 0.5);
    border: none;
}

.ui-widget-content a {
    color: @link-color;
}

ul.ui-autocomplete.ui-front.ui-menu.ui-widget.ui-widget-content
    {
    overflow-x: hidden;
    overflow-y: scroll;
}

.ui-widget-content a.button {
    color: #FFF;
}

.ui-widget-header {
    border: none;
}

.ui-widget-header .ui-icon {
    background-image: url("../img/jquery/ui-icons_222222_256x240.png");
}

button .ui-icon,
.ui-widget-content  button  .ui-icon {
    display: inline-block;
    vertical-align: bottom;
}

a.button .ui-icon,
a.button-inactive .ui-icon,
.ui-widget-content a.button .ui-icon{
    vertical-align: text-bottom;
}

.ui-icon-white,
.ui-widget-content .ui-icon-white {
    vertical-align: bottom;
    width: 16px;
    height: 16px;
    background-image: url("../img/jquery/ui-icons_FFFFFF_256x240.png");
    display: inline-block;
}

.ui-icon-gray,
.ui-widget-content .ui-icon-gray {
    vertical-align: bottom;
    width: 16px;
    height: 16px;
    background-image: url("../img/jquery/ui-icons_454545_256x240.png");
    display: inline-block;
}

.button-inactive {
    background-color: @button-inactive-color;
    border: 1px solid #454545;
    color: #454545;
}

.mini .ui-icon-white {
    height: 15px;
}

/* ui-button styles */
button.ui-button {
    .button-style;
}


.ui-button.ui-corner-left,.ui-button.ui-corner-right,.ui-button.ui-corner-all
    {
    border-radius: 0;
}

.ui-button-text-only .ui-button-text {
    padding: 0.2em 0.4em;
}
/* ui-buttonset */
.ui-button-icon-only .ui-button-text
.ui-button-icons-only .ui-button-text {
    padding: .2em .4em;
    margin: 0;
}

.ui-controlgroup-item.ui-button{
    padding: .2em .4em;
    margin: 0;
}
.ui-controlgroup  {
    margin-left: 10px;
    display: inline-block;
}

.ui-controlgroup  button {
    margin-top: 0;
    margin-bottom: 0;
}

.ui-buttonset button.ui-button.ui-state-default.ui-button-icon-only {

}

.ui-button.ui-state-default .ui-button-icon-primary,.ui-button.ui-state-default .ui-icon,.ui-button .ui-icon
    {
    height: 1em;
    width: 16px;
    background-image: url("../img/jquery/ui-icons_ffffff_256x240.png");
}

/* Makes the close button the proper height when the psDialog is docked*/
.rightSideDrawerPanel .ui-button.ui-state-default .ui-button-icon-primary,.drawerPanel .ui-button.ui-state-default .ui-button-icon-primary
    {
    height: 1em;
}

.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
    text-align: right;
    float: none !important;
}

#psDialogDocked+.ui-dialog-buttonpane {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 15px;
}

.ui-dialog.ui-resizable .ui-dialog-buttonpane .ui-dialog-buttonset {
    padding-right: 12px;
}

.ui-dialog .ui-dialog-buttonpane button.ui-button.ui-state-default,.ui-dialog .ui-dialog-buttonpane a.button
    {
    margin-left: 10px;
}

.ui-dialog .ui-dialog-buttonpane button.ui-button.ui-state-default:focus{
    .button-style-hover;
}

/* ui accordion styles */
.ui-accordion.ui-widget {
    border: @border-style;
    border-radius: @border-radius-med;
}

.ui-accordion-content {
    position: relative;
}

.ui-accordion-content.ui-widget-content,.ui-accordion-content.ui-widget-accordion-active:last-of-type,.ui-accordion-content.ui-widget-content:last-of-type
    {
    border-radius: 0;
}

.ui-accordion-content.ui-accordion-content-active:last-of-type {
    border-radius: 0 0 4px 4px;
}

.ui-accordion .ui-accordion-header {
    padding: 0 0.15em 0 0.25em;
      background-image: none;
    background-color: @light-grey;
    border: none;
    color: @text-color;
    font-weight: bold;
    font-size: 1.15em;
    margin-bottom: 0;
}

.ui-accordion-header.ui-accordion-header-active {
      background-color: @light-med-blue;
    border-bottom: none;
}

.ui-accordion-header.ui-corner-top {
    border-radius: 0;
}

.ui-accordion-header.ui-corner-top:first-of-type,.ui-accordion-header.ui-corner-all:first-of-type
    {
    border-radius: 4px 4px 0 0;
    margin-top: 0;
}

.ui-accordion-header.ui-corner-all:last-of-type {
    border-radius: 0 0 4px 4px;
}

.ui-accordion-header.ui-corner-all {
    border-radius: 0;
}

.ui-accordion .ui-accordion-icons {
    padding-left: 1.2em;
}

.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
    left: 0.1em;
}

.ui-accordion .ui-accordion-content {
    padding: 0.75em 0;
    position: relative;
}

.ui-accordion .ui-accordion-content p {
  margin: 0 10px 10px;
}

.ui-accordion .ui-accordion-content .button-row-wizard {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.ui-accordion.wizard {
  .ui-accordion-header {
    .ui-accordion-header-icon {
      top: 12px;
    }
    .flex-display();
    .wizStep {
      .flex-shrink(0);
    }
    span {
      float: none;
    }
  }
  .ui-accordion-content {
    padding-bottom: 2.5em;
  }
   .ui-accordion-content .ui-accordion-content{
    padding-bottom: 0;
  }
   div.header-pane {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .wizStep {
    float: none;
  }
}

/*markitup styles*/

fieldset .html {
    margin: 0;
    padding: 0;
    width: -webkit-calc(~"100% - 35% - 20px");
    width: calc(~"100% - 35% - 20px");
  display: inline-block;
  .markItUp {
    width: 100%;
  }
}
.markItUp {
  .markItUpHeader {
    border: @border-style;
    background: @lightest-blue;
    min-height: 20px;
    width: -webkit-calc(~"100% - 2px");
    width: calc(~"100% - 2px");
    ul {
      margin: 0;
      padding: 0;
      li {
        padding: 0;
        margin: 0;
        background-position: center center;
        background-repeat: no-repeat;
        list-style-type: none;
        display: inline-block;
        float: none;
        &:hover {
          background: @light-med-blue;
        }
      }
      .markItUpSeparator {
        background-color: @text-color;
        height: 9px;
        margin: 0 5px;
        overflow: hidden;
        width: 1px;

      }
    }
    a {
      background-position: center center;
      background-repeat: no-repeat;
      padding: 5px;
      }
  }

  .markItUpResizeHandle {
    display: none;
  }
  .markItUpEditor {
    margin-top: -2px;
    width: -webkit-calc(~"100% - 12px");
    width: calc(~"100% - 12px");
    max-width: -webkit-calc(~"100% - 12px");
    max-width: calc(~"100% - 12px");
    min-width: -webkit-calc(~"100% - 12px");
    min-width: calc(~"100% - 12px");

  }

}
/*dialog styles */
.ui-dialog {
    -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
    border: 1px solid  @border-color;
    border-radius: @border-radius-med;
    position: absolute;
    padding: 0 0 5px 0;
}

[dir=rtl] .ui-dialog {
    inset-inline-start: unset;
}


.ui-dialog .ui-corner-all {
    border-radius: 0;
}

.ui-dialog .ui-dialog-titlebar,.ui-dialog-titlebar {
    font-size: 128%;
    line-height: 1.5em;
    border-bottom: 1px solid @psdialog-borderline-color;
    padding: 0 5px;
    background: @psdialog-title-background;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-left-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    width: auto;
}

.ui-dialog-title {
    position: relative;
}

span.ui-dialog-title {
    font-weight: bold;
}

.ui-dialog .ui-dialog-titlebar-close {
    .button-style;
    position: absolute;
    top: 15%;
    right: .2em;
    width: 22px;
    margin: 0 0 0 0;
    padding: 1px;
    height: 22px;
    border-radius: 3px;
      border-color: @light-med-blue !important;
    &:hover, &:focus{
        .button-style-hover;
    }
}

.ui-dialog .ui-dialog-content {
    padding: 10px 0 0 0;
    overflow: auto;
}

.ui-dialog-titlebar-close span {
    // display: block;
    cursor: pointer;
    font-weight: bold;
    font-size: 110%;
    color: #4E94BF;
    font: Arial, Helvetica, sans-serif;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.ui-dialog button.ui-state-hover {
    .button-style-hover !important;
}

.filePickerBorder {
   border: thin solid #0075db;
}

// .ui-dialog .ui-dialog-content button.ui-button.ui-state-default {
    .ui-dialog button.ui-state-default {
   .button-style;
    white-space: nowrap;
    // position: relative;
    cursor: default;
    &:hover, &:focus {
        .button-style-hover;
    }
}

.ui-dialog .ui-button-icon-primary.ui-icon {
    background-image: url("../img/jquery/ui-icons_FFFFFF_256x240.png");
}

.ui-dialog button.ui-button.ui-state-default.ui-dialog-titlebar-close {
    position: absolute;
}

.ui-dialog button.ui-button.ui-state-default {

}

.ui-dialog .ui-dialog-buttonpane {
    margin-top: 0;
    padding: 0.4em 1.4em 0.4em;
    border-top: 1px solid @psdialog-borderline-color;
}

.ui-dialog-buttonpane button.ui-button.ui-state-default {
    margin: 0;
}

.alert-dialog-pre-text{
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-y : auto;
  -ms-overflow-x : hidden;
}
.textLegal {
    padding: 10px;
    font-size: 80%;
}
.pluginButtons {
        padding-right: 10px;
        text-align: right;
}

#confirmationDialog.ui-dialog-content {
    padding: 0 10px;
    word-wrap: break-word;
}

.ui-dialog div.ui-dialog-titlebar {
    margin: 0;
}

.ui-dialog label.no-float {
    float: none;
}

.ui-dialog label+p {
    line-height: 1.5em;
}
input.psDateWidget {
  width: 7em;
    padding: 3px;
    vertical-align: middle;
}
/*jQuery Date Picker styles*/

#ui-datepicker-div {
    display: none;
}

.ui-datepicker {
    background: #FFF;
    padding: 0;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
    border: @border-style;
    border-radius: @border-radius-med;
    position: absolute;
    z-index: 99200 !important;
}

.ui-datepicker .ui-datepicker-header {
    padding: 0.2em 0;
    position: relative;
}

.ui-datepicker-header {
    border-bottom: 1px solid #CCC;
    padding: 5px 5px;
    background: #E7E7E7;
    -moz-border-radius: 3px 3px 0 0;
    -webkit-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0;
}

.ui-datepicker .ui-datepicker-title {
    line-height: 1.8em;
    margin: 0 2.3em;
    text-align: center;
}
.ui-datepicker .ui-datepicker-title select {
    font-size: 1em;
    padding: .25em 0;
}
.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span    {
    display: block;
    left: 50%;
    margin-left: -8px;
    margin-top: -8px;
    position: absolute;
    top: 50%;
    white-space: nowrap;
    text-indent: -99999px;
    width: 16px;
    height: 16px;
    background-image: url("../img/jquery/ui-icons_FFFFFF_256x240.png");
}

/* Invert psDateWidget arrows for RTL locales */
[dir=rtl] .ui-datepicker .ui-datepicker-header {
    .ui-datepicker-prev .ui-icon,
    .ui-datepicker-next .ui-icon {
        transform: scale(-1, 1);
    }
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
    position: absolute;
    border-radius: 0;
  .button-style;
    font-size: 100%;
    line-height: 1.2em;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    top: 3px;
}

.ui-datepicker .ui-datepicker-prev span {
    background-position: -96px -17px;
}
.ui-datepicker .ui-datepicker-next span {
    background-position: -32px -17px;
}
.ui-datepicker .ui-datepicker-next.ui-state-hover,
.ui-datepicker  .ui-datepicker-prev.ui-state-hover {

}

.ui-datepicker .ui-datepicker-prev {
    left: 3px;
}

.ui-datepicker .ui-datepicker-next {
    right: 3px;
}

.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover,
.ui-widget-header .ui-state-hover.ui-datepicker-prev-hover,
.ui-widget-header .ui-state-hover.ui-datepicker-next-hover {
    top: 3px;
    background: none @button-hover-color;
}

.ui-datepicker-title {
    font-weight: bold;
    text-align: center;
}

.ui-datepicker .ui-datepicker-title select.ui-datepicker-month {
    margin-right: 8px;
}

table.ui-datepicker-calendar {
    /*margin: 5px; removed to bring inline with base JQUI CSS width also changed from auto to 100%*/
    width: 100%;
}

.ui-datepicker td {
    padding: 1px;
    line-height: 1em;
}

.ui-datepicker td span,.ui-datepicker td a.ui-state-default {
    display: block;
    padding: 0.2em;
    text-align: center;
    text-decoration: none;
    background-image: none;
    line-height: 2em;
}

.ui-datepicker td a.ui-state-default.ui-state-highlight {
    background-color: @dark-blue;
    color: #FFF;
}

.ui-datepicker-trigger {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 5px;
    align-self: center;
    vertical-align: middle;
    padding: 0.3em 0.4em;
  .button-row button& {
    margin-left: 5px;
  }
}

.ui-datepicker-trigger::before {
    content: '';
    background-image: url("../icon-calendar.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 16px;
    height: 1.1em;
    display: block;
}

.ui-datepicker-calendar th {
    padding: 0;
    font-weight: bold;
    background: none;
    text-align: center;
}

.ui-datepicker-week-end .ui-state-default {
    background-color: #CCC !important;
}

.ui-datepicker-other-month {

}

.ui-datepicker-buttonpane {

}

.ui-datepicker-current {
    font-weight: bold;
}

.ui-datepicker-close {

}

.ui-datepicker-today {
    font-weight: bold;
}

.datepicker-icon-only {
  & .psDateWidget,
  & .ui-date-picker-append {
    display: none;
  }
  & .ui-datepicker-trigger {
    position: relative;
  }
}

.ui-state-default {
    border: @border-style;
}

/* autocomplete styles*/
.ui-autocomplete-input {
    float: none;
    border: 1px solid  @border-color;
    height: 1.6em;
}

textarea.ui-autocomplete-input {
    height: auto;
}

.ui-autocomplete-button {
    margin: 0 0 0 5px;
    padding: 0.2em 0;
    border: 1px solid  @border-color;
    box-shadow: none;
    border-radius: 0;
}

.ui-autocomplete-button span.ui-button-text {
    display: none;
}
[dir=rtl] .ui-autocomplete {
    inset-inline-start: unset;
}
.ui-menu {
    list-style: none;
    padding: 2px;
    margin: 0;
    display: block;
    height: 200px;
    width: 170px;
    overflow: hidden;
    overflow-x: scroll;
    position: absolute;
    z-index: 100;
    background: #fff;
    border: @border-style;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
    text-align: left;
}

ul.ui-menu li {
    list-style-type: none;
}

ul.ui-menu li.active {
    background-image: url("../img/icon-check-green.png");
    background-repeat: no-repeat;
    background-position: 2px center;
}

.ui-menu .ui-menu-item .ui-state-focus,.ui-menu .ui-menu-item.ui-state-active
    {
    font-weight: normal;
    margin: 0;
}

.ui-menu a {
    display: block;
}

/* TimeEntry Widget*/
/* style to add informative icon to TimeEntry inputs */
input[class^="timeEntry"],
input.timeEntry,
input.timeEntry5,
input.timeEntry15,
input.timeEntry30,
input.timeEntryHourOnly,
input.timeEntry05To22,
input.timeEntryNowOnClick {
    border: 1px solid @border-color;
    padding: 3px 3px 3px 20px;
    background-position: 2px center;
    background-color: #FFF;
    background-repeat: no-repeat;
}

input.timeEntry {
  background-image: url("../img/icon-time.png");
}

input.timeEntry5 {
  background-image: url("../img/icon-time-5.png");
}

input.timeEntry15 {
  background-image: url("../img/icon-time-15.png");
}

input.timeEntry30 {
  background-image: url("../img/icon-time-30.png");
}

input.timeEntryHourOnly {
  background-image: url("../img/icon-time-60.png");
}

input.timeEntry05To22 {
  background-image: url("../img/icon-time-5.png");
}

input.timeEntryNowOnClick {
    background-image: url("../img/icon-time.png");
}

[dir=rtl] input.timeEntry {
  background-position-x: 98%;
  padding-inline-start: 23px;
}

[dir=rtl] input.timeEntry5 {
  background-position-x: 98%;
}

[dir=rtl] input.timeEntry15 {
  background-position-x: 98%;
}

[dir=rtl] input.timeEntry30 {
  background-position-x: 98%;
}

[dir=rtl] input.timeEntryHourOnly {
  background-position-x: 98%;
}

[dir=rtl] input.timeEntry05To22 {
  background-position-x: 98%;
}

[dir=rtl] input.timeEntryNowOnClick {
  background-position-x: 98%;
}

/* End TimeEntry Widget */

/* jQuery UI AutoComplete Smart Search Styles */
#ssAutoComplete {

}

#ssAutoComplete a[class*="Result"] {
    background-position: left center;
    background-repeat: no-repeat;
    padding-left: 20px;
}
a.ui-corner-all {}

#ssAutoComplete a.inactivestudentResult {
    color: #666;
}

#ssAutoComplete .ui-menu {
    overflow-x: hidden;
}

#ssAutoComplete .ui-state-focus {
    background-color: rgba(178, 208, 233, 0.5);
    border: 1px solid transparent;
}

#ssAutoComplete .fieldResult,#ssAutoComplete .fieldResult.ui-state-focus
    {
    background-image: url("../icon-ps-field.png");
    color: black;
}

#ssAutoComplete .pageResult,#ssAutoComplete .pageResult.ui-state-focus {
    background-image: url("../icon-ps-page.png");
    color: #4E94BF;
}

#ssAutoComplete .storedsearchResult,#ssAutoComplete .storedsearchResult.ui-state-focus,#ssAutoComplete .storedtchsearchResult,.storedtchsearchResult.ui-state-focus
    {
    background-image: url("../icon-ps-stored.png");
    color: #006600;
}

.studentResult,.staffResult {
    color: #FF9900;
}

.inactivestudentResult,.inactivestaffResult {
    color: #919191;
}

#ssAutoComplete .studentResult,#ssAutoComplete .studentResult.ui-state-focus
    {
    background-image: url("../icon-person.png");
}

#ssAutoComplete .staffResult,#ssAutoComplete .staffResult.ui-state-focus
    {
    background-image: url("../icon-person-2.png");
}

#ssAutoComplete .inactivestudentResult,#ssAutoComplete .inactivestudentResult.ui-state-focus
    {
    background-image: url("../icon-person-bw.png");
}

#ssAutoComplete .inactivestaffResult,#ssAutoComplete .inactivestaffResult.ui-state-focus
    {
    background-image: url("../icon-person-2-bw.png");
}

/* end jQui AutoComplete Smart Search */
/* jQgrid override styles */
.ui-th-column, .ui-jqgrid .ui-jqgrid-htable th.ui-th-column {
    border-bottom: 0 none;
    border-top: 0 none;
    overflow: visible;
    text-align: center;
    white-space: normal;
}
.ui-jqgrid .ui-jqgrid-htable th {
    height: auto;
    padding: 0 2px;
}
.ui-jqgrid .ui-jqgrid-htable th div.ui-jqgrid-sortable {
    background-color: rgba(0, 0, 0, 0);
    height: auto !important;
    overflow: visible !important;
    white-space: normal;
}

.ui-jqgrid .ui-pg-button span.ui-separator {
  visibility: hidden;
}

.ui-jqgrid,.ui-jqgrid.ui-corner-all {
    margin: 0 20px 20px;
    border: 1px solid  @border-color;
    border-radius: 0;
}
fieldset.divColumns .ui-jqgrid,
fieldset.divColumns .ui-jqgrid.ui-corner-all {
   margin-left: 0;
   margin-right: 0;
   margin-bottom: 0;
}
.ui-jqgrid tr:last-of-type td:first-of-type {
    border-bottom-left-radius: 4px;
}

.ui-jqgrid tr:last-of-type td:last-of-type {
    border-bottom-right-radius: 4px;
}

.ui-jqgrid .ui-helper-clearfix:after {
    clear: none;
}
.box-round {
  .ui-jqgrid {
    margin: 0 10px 10px;
  }

  td .ui-jqgrid {
    margin: 0;
  }

  > .ui-jqgrid:first-child,
  > .jqgridx:first-child,
  > .jqgrid-editable  {
    margin-top: 10px;
  }
}


.ui-jqgrid-htable,
.ui-jqgrid-btable {
    background-image: url("../table-back-stripes.png");
}

.ui-jqgrid,.ui-jqgrid th,
.ui-jqgrid td,
.ui-jqgrid .ui-state-default,
.ui-jqgrid .ui-widget-content
    {
    font-size: 14px;
    color: #000;
    border-color:  @border-color;
}

fieldset div.ui-jqgrid,
fieldset div.ui-jqgrid div {
    padding-top: 0;
    padding-bottom: 0;
}

.ui-jqgrid tr.ui-priority-secondary {
    opacity: 1;
}

.ui-jqgrid tr.greyOutRow td,
.ui-jqgrid tr.greyOutRow:nth-child(odd) td {
    background: #E6E6E6;
    color: #666;
}

.ui-jqgrid-bdiv td {
    background-color: #FFF;
}

.ui-jqgrid-bdiv tr:nth-child(odd) td {
    /*  zebra color set */
    background-color: @zebra;
}

.ui-jqgrid-bdiv tr.ui-widget-content {
    background-color: transparent;
    background-image: none;
}

.ui-jqgrid-bdiv tr.ui-state-highlight td,
.ui-jqgrid-bdiv tr.ui-state-highlight:nth-child(odd) td,
.ui-jqgrid-bdiv tr:nth-child(odd) td.ui-state-highlight,
.ui-jqgrid-bdiv tr.ui-widget-content.ui-state-highlight.ui-state-hover td,
table.linkDescList table tr.ui-state-highlight:nth-child(2n+1) > td,
table:not(#schedMatrixTable) table tbody tr.ui-state-highlight:nth-child(2n+1) > td
    {
    background-image: none;
    color: inherit;
    background-color: @light-med-blue;
}

.ui-jqgrid-bdiv tr.ui-widget-content.ui-state-hover,
.ui-jqgrid-bdiv tr.ui-widget-content.ui-state-hover td
    {
    background-image:  linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    color: #000;
}
.ui-pg-table td.ui-pg-button.ui-state-hover {
    border: none;
    padding: 1px;
}
.ui-jqgrid-bdiv td.cvInvalid,
.ui-jqgrid-bdiv td.cvEditNotAllowed
.ui-jqgrid-bdiv tr.ui-state-highlight td.cvInvalid,
.ui-jqgrid-bdiv tr.ui-state-highlight td.cvEditNotAllowed,
.ui-jqgrid-bdiv tr.ui-state-highlight:nth-child(odd) td.cvInvalid,
.ui-jqgrid-bdiv tr.ui-state-highlight:nth-child(odd) td.cvEditNotAllowed,
.ui-jqgrid-bdiv tr:nth-child(odd) td.cvInvalid,
.ui-jqgrid-bdiv tr:nth-child(odd) td.cvEditNotAllowed,
.ui-jqgrid-bdiv tr:nth-child(even) td.cvInvalid,
.ui-jqgrid-bdiv tr:nth-child(even) td.cvEditNotAllowed {
    background-color: rgba(0,0,0,0);
    background-image: none;
}
.ui-jqgrid-bdiv td.cvInvalid,
.ui-jqgrid-bdiv tr:nth-child(odd) td.cvInvalid,
.ui-jqgrid-bdiv tr:nth-child(even) td.cvInvalid {
   background-color: rgba(0,0,0,0.2);
}

.ui-jqgrid-bdiv tr.ui-state-highlight td.cvInvalid,
.ui-jqgrid-bdiv tr.ui-state-highlight:nth-child(odd) td.cvInvalid,
.ui-jqgrid-bdiv tr.ui-state-highlight:nth-child(even) td.cvEditNotAllowed,
.ui-jqgrid-bdiv tr.ui-state-highlight:nth-child(odd) td.cvEditNotAllowed {
   background-color: rgba(68, 141, 206, 0.8);
}

.simpleTextBox {
    display: block;
    font-weight: normal;
}

.simpleTextBox30,
.simpleTextBox35,
.simpleTextBox40,
.simpleTextBox45,
.simpleTextBox55,
.simpleTextBox65,
.simpleTextBox75,
.simpleTextBox85,
.simpleTextBox95,
.simpleTextBox105,
.simpleTextBox110,
.simpleTextBox115,
.simpleTextBox125,
.simpleTextBox135 {
    display: block;
    font-weight: normal;
    text-align: center;
    height: 19px;
    margin-top: 1px;
    position: relative;
}

.simpleTextBox30 {
    width: 30px;
}

.simpleTextBox35 {
    width: 35px;
}

.simpleTextBox40 {
    width: 36px;
}

.simpleTextBox110 {
    width: 110px;
}

.simpleTextBox45 {
    width: 45px;
    padding-right: 5px;
}

.simpleTextBox55 {
    width: 55px;
    padding-right: 5px;
}

.simpleTextBox65 {
    width: 65px;
    padding-right: 5px;
}

.simpleTextBox75 {
    width: 75px;
    padding-right: 5px;
}

.simpleTextBox85 {
    width: 85px;
    padding-right: 5px;
}

.simpleTextBox95 {
    width: 95px;
    padding-right: 5px;
}

.simpleTextBox105 {
    width: 105px;
    padding-right: 5px;
}

.simpleTextBox115 {
    width: 115px;
    padding-right: 5px;
}

.simpleTextBox125 {
    width: 125px;
    padding-right: 5px;
}

.simpleTextBox135 {
    width: 135px;
    padding-right: 5px;
}

.simpleTextBox45 img,
.simpleTextBox55 img,
.simpleTextBox65 img,
.simpleTextBox75 img,
.simpleTextBox85 img,
.simpleTextBox95 img,
.simpleTextBox105 img,
.simpleTextBox115 img,
.simpleTextBox125 img,
.simpleTextBox135 img {
    position: absolute;
    right: 5px;
    top: 3px;
    left: auto;
    bottom: auto;
}

.modifiedTextBox {
   color: #000;
  background-color: #D7D7D7;
}
.comment-icon {
    height: 12px;
    overflow: hidden;
    padding-left: 2px;
    text-indent: -99999px;
    width: 12px;
}

.hasComment {
    background-image: url("../seatingchart/icon-comment-lrg-blue.png");
    background-position: ~"calc(100% - 2px)" 2px; // value when dir is not rtl
    background-repeat: no-repeat;
    background-size: 10px;
    margin: -4px -5px;
    padding: 4px 5px;
    cursor: pointer;
    &:hover {
        background-color: fade(@button-hover-color, 10%);
    }
}

[dir=rtl] &.hasComment {
    background-position: ~"calc(0% + 2px)" 2px; // value when dir equals rtl
}

.att_dropdown {
    border: medium none;
    left: -3px;
    min-width: 60px;
    position: relative;
    z-index: 10;
}

.cvToday {
    background-image: linear-gradient(to right, rgba(0, 128, 0, 0.3),
        rgba(0, 128, 0, 0.3));
}

.cvWeekDemarcation {
    border-left: 2px Solid  @border-color;
}

.ui-jqgrid .ui-jqgrid-title {
    float: none;
}

.ui-jqgrid-titlebar {
    background-image: none;
    background-color: @light-grey;
    color: inherit;
    font-size: 128%;
}

.ui-jqgrid .ui-jqgrid-htable th {
    background-image: none;
    background-color: @light-med-blue;
    color: inherit;
    font-weight: bold;
}
/* this is an override to the default styles. this may break grids */
.ui-jqgrid .ui-jqgrid-htable th div {
    white-space: normal;
    height: auto;
    overflow: visible;
}
.ui-jqgrid .ui-jqgrid-htable th div.ui-jqgrid-sortable {
    white-space: normal;
    overflow: hidden;
    height: 17px;
    background-color: transparent;
}
.ui-jqgrid span.s-ico {
   display: inline;
}
.ui-jqgrid tr.jqgrow td {
    white-space: normal;
    height: auto;
    overflow: visible;
    vertical-align: middle;
}
#multiDayAttendanceGrid .ui-jqgrid tr.jqgrow td {
    white-space: normal;
    height: auto;
    overflow: hidden;
}
#multiDayAttendanceGrid .ui-jqgrid-sortable {
    /* should remove class from jqgrid instantiation code instead */
    cursor: default;
}
/* End this is an override to the default styles. this may break grids */
.jqgrid-editable .ui-jqgrid .ui-jqgrid-htable th div {
    height: 24px;
    line-height: 1.7em;
}

.frozen-bdiv td {
    background-color: #FFF;
}

.jqgrid-editable table {
    border: none;
}

button.massFill {
    width: 100%;
    height: 24px;
}
/* end jQgrid overrides */
div.tableToGridLoadingImage {
    text-align: center;
}

@import url("gridwidget.less");

.ui-jqdialog {
    display: none;
    width: 300px;
    position: absolute;
    padding: .2em;
    font-size: 11px;
    overflow: visible;
}

.ui-jqdialog .ui-jqdialog-titlebar {
    padding: .3em .2em;
    position: relative;
}

.ui-jqdialog .ui-jqdialog-title {
    float: left;
    margin: .1em 0 .2em;
}

.ui-jqdialog .ui-jqdialog-titlebar-close {
    position: absolute;
    right: .3em;
    top: 50%;
    width: 19px;
    margin: -10px 0 0 0;
    padding: 1px;
    height: 18px;
}

.ui-jqdialog .ui-jqdialog-titlebar-close span {
    display: block;
    margin: 1px;
}

.ui-jqdialog .ui-jqdialog-titlebar-close:hover,.ui-jqdialog .ui-jqdialog-titlebar-close:focus
    {
    padding: 0;
}

.ui-jqdialog-content,.ui-jqdialog .ui-jqdialog-content {
    border: 0;
    padding: .3em .2em;
    background: none;
    height: auto;
}

.ui-jqdialog .ui-jqconfirm {
    padding: .4em 1em;
    border-width: 3px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    overflow: visible;
    display: none;
    height: 80px;
    width: 220px;
    text-align: center;
}

.ui-jqdialog-content .FormGrid {
    margin: 0;
}

.ui-jqdialog-content .EditTable {
    width: 100%;
    margin-bottom: 0;
}

.ui-jqdialog-content .DelTable {
    width: 100%;
    margin-bottom: 0;
}

.EditTable td input,.EditTable td select,.EditTable td textarea {
    margin: 0;
}

.EditTable td textarea {
    width: auto;
    height: auto;
}

.ui-jqdialog-content td.EditButton {
    text-align: right;
    border-top: 0 none;
    border-left: 0 none;
    border-right: 0 none;
    padding-bottom: 5px;
    padding-top: 5px;
}

.ui-jqdialog-content td.navButton {
    text-align: center;
    border-left: 0 none;
    border-top: 0 none;
    border-right: 0 none;
    padding-bottom: 5px;
    padding-top: 5px;
}

.ui-jqdialog-content .CaptionTD {
    text-align: left;
    vertical-align: top;
    border-left: 0 none;
    border-right: 0 none;
    border-bottom: 0 none;
    padding: 1px;
    white-space: nowrap;
}

.ui-jqdialog-content .DataTD {
    padding: 1px;
    border-left: 0 none;
    border-right: 0 none;
    border-bottom: 0 none;
    vertical-align: top;
}

.fm-button {
    margin: 0 4px 0 0;
    padding: .4em .5em;
    text-decoration: none !important;
    cursor: pointer;
    position: relative;
    text-align: center;
    zoom: 1;
}

.fm-button-icon-left {
    padding-left: 1.9em;
}

.fm-button-icon-right {
    padding-right: 1.9em;
}

.fm-button-icon-left .ui-icon {
    right: auto;
    left: .2em;
    margin-left: 0;
    position: absolute;
    top: 50%;
    margin-top: -8px;
}

.fm-button-icon-right .ui-icon {
    left: auto;
    right: .2em;
    margin-left: 0;
    position: absolute;
    top: 50%;
    margin-top: -8px;
}

#nData,#pData {
    float: left;
    margin: 3px;
    padding: 0;
    display: block;
    width: 15px;
}

.jqmOverlay {
    background-color: #000;
}

* iframe.jqm {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.ui-searchFilter {
    display: none;
    position: absolute;
    z-index: 100;
    width: 1px;
    overflow: visible;
}

.ui-searchFilter table {
    position: relative;
    margin: 0;
    width: 100%;
}

.ui-searchFilter table td {
    margin: 0;
    padding: 1px;
}

.ui-searchFilter table td input,.ui-searchFilter table td select {
    margin: .1em;
}

.ui-searchFilter .ui-state-default {
    cursor: pointer;
}

.ui-searchFilter .divider {
    height: 1px;
}

.ui-searchFilter .divider div {
    background-color: black;
    height: 1px;
}

.iconXlate {
    background: url("../img/icon-magnify.png") no-repeat;
    float: left;
    margin: 0;
    padding: 0;
    width: 15px;
    height: 16px;
}

.iconCopyadd {
    background: url("../icon-doc-plus.png") no-repeat;
    margin: 0;
    padding: 0;
    width: 17px;
    height: 15px;
    float: left;
}

.iconMerge {
    background: url("../icon-doc-merge.png") no-repeat;
    margin: 0;
    padding: 0;
    width: 17px;
    height: 16px;
    padding-left: 2px;
    float: left;
}

.iconCheckMark {
    background: url("../img/icon-check-green.png") no-repeat;
    width: 15px;
    height: 15px;
    display: inline-block;
}

a.dlglink {
    font-size: 11px;
    text-decoration: none;
    font-weight: normal;
    color: #333;
    display: block;
}

/* jQuery UI Slider styles*/
.ui-slider {
    position: relative;
    text-align: left;
}

.ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 1.2em;
    height: 1.2em;
    cursor: default;
}

.ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    font-size: .7em;
    display: block;
    border: 0;
    background-position: 0 0;
}

.ui-slider-horizontal {
    height: .8em;
}

.ui-slider-horizontal .ui-slider-handle {
    top: -.3em;
    margin-left: -.6em;
}

.ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 100%;
}

.ui-slider-horizontal .ui-slider-range-min {
    left: 0;
}

.ui-slider-horizontal .ui-slider-range-max {
    right: 0;
}

.ui-slider-vertical {
    width: .8em;
    height: 100px;
}

.ui-slider-vertical .ui-slider-handle {
    left: -.3em;
    margin-left: 0;
    margin-bottom: -.6em;
}

.ui-slider-vertical .ui-slider-range {
    left: 0;
    width: 100%;
}

.ui-slider-vertical .ui-slider-range-min {
    bottom: 0;
}

.ui-slider-vertical .ui-slider-range-max {
    top: 0;
}
/*End jQuery UI Slider styles*/
/* jQuery UI Slider styles*/

/*
* jQuery Tree Style Overides
*/
#content-main div.treeview,div.treeview {
    line-height: 1em;
}

#content-main div.treeview ul li,div.treeview ul li {
    list-style-type: none;
    margin: 0;
    padding: 3px 0 2px 16px;
}

#content-main div.treeview ul,div.treeview ul {
    margin-top: 4px;
}
/* End Tree Style Overides */

/* Override of Core UI Theme's Component containers styles*/
.ui-widget-content.ui-slider {
    border: 1px solid #aaa;
    background: #fff url("../img/jquery/ui-bg_flat_75_ffffff_40x100.png")
        50% 50% repeat-x;
    color: #222;
}

/* Override of Core UI Theme's Interaction states styles */
.ui-widget-content.ui-slider .ui-state-default {
    border: 1px solid @border-color /*{borderColorDefault}*/;
    background-color: @light-grey;
    background-image: url("../img/jquery/ui-bg_glass_75_e6e6e6_1x400.png");
    background-position: 50% 50%;
      background-rpeat: repeat-x;
    font-weight: normal;
    color: #555;
}

.ui-widget-content.ui-slider .ui-state-hover,.ui-widget-content.ui-slider .ui-state-focus
    {
    border: 1px solid  @border-color;
    background: #dadada url("../img/jquery/ui-bg_glass_75_dadada_1x400.png") 50% 50% repeat-x;
    font-weight: normal;
    color: #212121;
}

.ui-widget-content.ui-slider .ui-state-active {
    border: 1px solid #aaa;
    background: #fff url("../img/jquery/ui-bg_glass_65_ffffff_1x400.png")
        50% 50% repeat-x;
    font-weight: normal;
    color: #212121;
}

/*for autoReload thingy */
.prDropList .ui-state-default,.prDropList .ui-widget-content .ui-state-default,.prDropList .ui-widget-header .ui-state-default
    {

    .button-style;
    line-height: normal;
    &:hover{
        background: unset;
        .button-style !important;
    }
    &:active{
        background: unset;
        .button-style !important;
    }
    &:focus{
        background: unset;
        .button-style !important;
    }
}

.prDropList.ui-controlgroup .ui-controlgroup-item {
    margin-right: 5px;
}

.prDropList.ui-controlgroup {
    position: relative;
    display: inline-block;
}

.prDropList button {
    cursor: pointer;
    min-height: 1.9em;
}

.prDropList .autoReloadPage .prClock {
    margin: 0 0 0 5px;
    color: #444;
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
    background-color: #CCC;
    display: inline-block;
    padding: 0 4px;
    font-style: normal;
    border: 1px solid #003D7A;
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.4);
    min-width: 1.1em;
    border-radius: 2px;
}

.prDropList div.prDropListSettings,
.prDropList div.prDropListSettings.button-row
    {
    display: none;
    background-color: #FFF;
    border: 1px solid #003D7A;
    position: absolute;
    color: #000;
    padding: 0 5px;
    border-radius: 4px 0 4px 4px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    min-width: 140px;
    white-space: nowrap;
    top: 1.9em;
    right: 0;
    margin-right: 0;
}

.prDropList div.prDropListSettings button {
    margin: 5px 0;
    display: inline-block;
    border-left-width: 0;
}

.prDropList div.prDropListSettings button:first-child {
    border-left-width: 1px;
}

.prDropList div.prDropListSettings button#prManual {
    height: 1.7em;
}

.prDropList div.prDropListSettings button.selected {
    background-color: DarkOrange;
    &:hover{
        background: unset;
        background-color: DarkOrange !important;
    }
}

/*end autoReload thingy */

/* Override of Core UI Theme's Corner radius styles */
.ui-slider.ui-corner-all,.ui-slider .ui-corner-all {
    border-radius: @border-radius-med;
}

/*End jQuery UI Slider styles*/

/*Specific UI Elements /features */
div#statsBuddy {
    overflow: auto;
}

#div_popup.yui-panel .bd {
    overflow-y: auto;
}

/* @group FTL progressBar macro support (pss-macros.ftl) */
.progressBar {
    width: 200px;
    height: 12px;
    position: relative;
    border: 1px solid  @border-color;
    overflow: hidden;
    background-color: transparent;
}

.progressBar * {
    position: absolute;
    display: block;
    width: 200px;
    font-size: 12px;
    top: 0;
    left: 0;
    overflow: hidden;
    text-align: right;
    margin-right: 2px;
    border-right: 1px solid #ddd;
    color: #333;
    line-height: 12px;
}

.prog_blue {
    background-color: #25ccca;
}

.prog_darkgreen {
    background-color: #149500;
}

.prog_green {
    background-color: #66ff62;
}

.prog_yellow {
    background-color: #f9ff65;
}

.prog_orange {
    background-color: #f89e12;
}

.prog_red {
    background-color: #c86568;
}

.prog_white {
    background-color: white;
}

.colorLegendSquare {
    width: 10px;
    height: 10px;
    border: 1px solid #000;
    display: inline-block;
    position: relative;
    overflow: hidden;
}

.colorLegendSquare b {
    padding: 1px;
}

.colorLegend {
    display: inline-block;
    position: relative;
    padding-left: 2px;
    padding-right: 10px;
    top: -2px;
}

.colorLegendBar {
    width: 3px;
    height: 12px;
    display: inline-block;
    position: relative;
    overflow: hidden;
}

tr.greyOutRow td,
tr.greyOutRow:nth-child(odd) td {
    background: #E6E6E6;
    color: #666;
}

table.testScoreTable > tbody > tr:first-child > td:first-child {
    width: 40px;
}
table.testScoreTable > tbody > tr:first-child > td:last-child {
    width: 20%;
}

.testScoreTableIconSpan {
    width:50px!important;
    padding-left: 20px;
}
table.testScoreTable > tbody > tr.testheader td {
    background: #e1ebfd;
}


/* @end */
/* @group FTL decimalAligned macro support (pss-macros.ftl) */
.numInt {
    text-align: right;
}

.numDec {
    text-align: left;
}

/* @end */
/* @group Depth Backgrounds */
.bgLevel0 {
    background-color: #3E7EDC;
}

.bgLevel1 {
    background-color: #6D9ADB;
}

.bgLevel2 {
    background-color: #99B4DC;
}

.bgLevel3 {
    background-color: #B0C2DC;
}

.bgLevel4 {
    background-color: #C6CFDC;
}

.bgLevel5 {
    background-color: #FFF;
}

.bgLightLevel0 {
    background-color: #a2c2fd;
}

.bgLightLevel1 {
    background-color: #bfd5fd;
}

.bgLightLevel2 {
    background-color: #d4e3fd;
}

.bgLightLevel3 {
    background-color: #e1ebfd;
}

.bgLightLevel4 {
    background-color: #edf3fe;
}

.bgLightLevel4_5 {
    background-color: #f2f6fc;
}

.bgLightLevel5 {
    background-color: #fcfcfc;
}

table table tr[class^="bgLightLevel"]:nth-child(even) td {
    background-color: transparent;
}

tr[class^="bgLightLevel"].highlight,
tr[class^="bgLightLevel"].highlight .creditDetails,
table table tr[class^="bgLightLevel"].highlight:nth-child(odd) td,
table table tr[class^="bgLightLevel"].highlight td    {
    background-color: rgb(68, 141, 206);
    color: #FFF;
}

tr[class^="bgLightLevel"].highlight a {
    color: #FFF;
}

.report_icon {
    float: left;
    display: block;
    width: 20px;
    height: 15px;
    background-image: url('../icon-misc.gif');
    background-repeat: no-repeat;
}
[dir="rtl"] .report_icon{
	float: right;
    margin-inline-end: 4px;
    }
.report_icon_PDF {
    background-image: url('../icon-pdf.gif');
    background-repeat: no-repeat;
}

.report_icon_CSV {
    background-image: url('../icon-csv.gif');
    background-repeat: no-repeat;
}

.report_icon_Delimited {
    background-image: url('../icon-txt.gif');
    background-repeat: no-repeat;
}

.report_icon_Text {
    background-image: url('../icon-txt.gif');
    background-repeat: no-repeat;
}

.report_icon_Excel {
    background-image: url('../icon-excel.gif');
    background-repeat: no-repeat;
}

.report_icon_Word {
    background-image: url('../icon-word.gif');
    background-repeat: no-repeat;
}

.report_icon_PowerPoint {
    background-image: url('../icon-powerpoint.gif');
    background-repeat: no-repeat;
}

.report_icon_HTML {
    background-image: url('../icon-html.gif');
    background-repeat: no-repeat;
}

.report_icon_XML {
    background-image: url('../icon-xml.gif');
    background-repeat: no-repeat;
}

.report_icon_Misc {
    background-image: url('../icon-misc.gif');
    background-repeat: no-repeat;
}

.report_icon_Video {
    background-image: url('../icon-video.gif');
    background-repeat: no-repeat;
}

.report_icon_Audio {
    background-image: url('../icon-audio.gif');
    background-repeat: no-repeat;
}

.report_icon_WebApp {
    background-image: url('../icon-webapp.gif');
    background-repeat: no-repeat;
}

.report_icon_WebSite {
    background-image: url('../icon-website.gif');
    background-repeat: no-repeat;
}

.report_icon_ZIP {
    background-image: url('../icon-zip.gif');
    background-repeat: no-repeat;
}

.comment {
    margin: 0 20px;
    padding: 0 0 0 10px;
}

.psDateWidget.jumpDate {
    //height: 0;
    //width: 0;
    //background: #E9E9E9;
    //border: none;
}

.noformat+.ui-datepicker-trigger+.ui-datepicker-append {
    display: none;
}

.guardianModalWindow {
    width: 500px;
    padding-left: 3%;
}

#listrecordsview {
    padding-left: 23px;
}

.left-padding {
    padding-left: 23px;
}

#studentInfoToAdd_relationshipTypeId {
    width: 100%;
}

.teachercommenttitle {
    width: 10%;
}

#content-main .box-round p.studpagesteachercomment {
    margin-top: 0;
    font-family: Geneva, Courier, monospace;
    white-space: pre-wrap;
    color: #333;
}

#pageLevelModAccess {
    clear: left;
}

img.partner-logo {
    float: left;
    border: @border-style;
    margin: 0 10px 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
}

.pluginConsoleFixedDialogHeight {
    height: 500px;
    overflow: auto;
}

/* ---------- Right-Side Sliding Drawer Panel ------------- */
body.drawerPanelExpanded {
    overflow: hidden;
}

.rightSideDrawerPanel,.drawerPanel {
    position: fixed;
    top: 0;
    background: #FFF;
    box-shadow: -10px 0 10px rgba(0, 0, 0, 0.3);
    height: 100%;
}

.rightSideDrawerPanel,.drawerPanel.east {
    right: 0;
    border-left: 1px solid #666;
    box-shadow: -10px 0 10px rgba(0, 0, 0, 0.3);
}

.drawerPanel .psDialog {
    overflow-y: auto;
    overflow-x: hidden;
}

.psDialog .dialogScrollbar {
    height: ~"calc(100% - 20px)";
    overflow-x: hidden;
    overflow-y: auto;
}

.drawerPanel.background {
    z-index: 98000;
}

.drawerPanel.west {
    left: 0;
    border-right: 1px solid #666;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.3);
}

.ui-dialog.rightSideDrawerPanel,.ui-dialog.drawerPanel {
    border-radius: 0;
}

.ui-dialog.rightSideDrawerPanel,.ui-dialog.drawerPanel.east {
    right: 0;
    left: auto;
    bottom: auto;
}

.ui-dialog.drawerPanel.west {
    left: 0;
    right: auto;
    bottom: auto;
}

.ui-dialog.rightSideDrawerPanel .ui-dialog-titlebar,.ui-dialog.drawerPanel .ui-dialog-titlebar
    {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.rightSideDrawerPanel.expanded {
    margin-left: 35%;
    margin-top: 0;
    overflow: hidden;
}

.rightSideDrawerPanel.collapsed {
    width: 0;
    margin-left: 100%;
    display: none;
    overflow: hidden;
}

.rightSideDrawerPanel .content {
    overflow: auto;
    height: 90%;
    margin: 2.6em 0;
    min-width: 400px;
}

.rightSideDrawerPanel.buttonless .content {
    overflow: auto;
    height: 95%;
    margin: 2.6em 0 0;
}

.rightSideDrawerPanel .doorProp {
    height: 10%;
}

.rightSideDrawerPanel.buttonless .doorProp {
    height: 2%;
}

.rightSideDrawerPanel .box-round,.drawerPanel .box-round {
    margin: 10px;
    display: block;
    float: none;
}

.rightSideDrawerPanel.buttonless .button-row {
    display: none;
}

.rightSideDrawerPanel .button-row,#psDialogDocked+.ui-dialog-buttonpane, #incidentWidgetTemplate .ui-dialog-buttonpane
    {
    border-top: 1px solid @psdialog-borderline-color;
    background-color: @psdialog-buttonbar-background;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}

#psDialogDocked>.button-row,#psDialogDocked+.ui-dialog-buttonpane {
    width: auto;
}
/* drawer styles */
/* legacy drawer code - .rightSideDrawerPanel*/
.rightSideDrawerPanel h1,.drawerPanel h1 {
    background-color: @breadcrumb-background;
    color: #FFF;
    font-size: 18px;
    line-height: 2em;
    margin: 0 0 10px;
    text-indent: 10px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}

.rightSideDrawerPanel .ui-dialog-titlebar,.drawerPanel .ui-dialog-titlebar
    {
    background-color: @psdialog-title-background;
    color: @psdialog-title-color;
    border-width: 1px 0;
    border-style: solid;
    border-color: @psdialog-borderline-color;
}

.drawerPanel.ui-dialog {
    border-top: 0;
    border-bottom: 0;
}

.drawerPanel.ui-dialog.east {
    border-right: 0;
    max-width: 100%;
}

.drawerPanel.ui-dialog.west {
    border-left: 0;
}

.rightSideDrawerPanel h1 span {
    margin: 0 10px 0 0;
}

.rightSideDrawerPanel h1 div {
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    max-width: 685px;
}

.rightSideDrawerPanel h1 div span {
    float: none;
}

.rightSideDrawerPanel h1 div span:first-child {
    font-size: 18px;
    font-weight: bold;
    margin-right: 20px;
}

.rightSideDrawerPanel h1 button {
    text-indent: 0;
}

.rightSideDrawerPanel .box-round table {
    table-layout: fixed;
    word-wrap: break-word;
}

.rightSideDrawerPanel .box-round table td,.rightSideDrawerPanel .box-round table th
    {
    word-wrap: break-word;
    white-space: normal;
}

.rightSideDrawerPanel table.linkDescList col:first-child {
    width: 30%;
}

.rightSideDrawerPanel table.linkDescList img {
    border: @border-style;
    background: #E7E7E7;
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px 0 0;
    -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.rightSideDrawerPanel h1 div {
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    max-width: 685px;
}

.rightSideDrawerPanel h1 div span {
    float: none;
}

.rightSideDrawerPanel h1 div span:first-child {
    font-size: 18px;
    font-weight: bold;
    margin-right: 20px;
}

.drawerModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000;
    opacity: 0.5;
    background: rgba(0, 0, 0, 0.4);
    display: none;
}

/* ---------- END: Right-Side Sliding Drawer Panel ------------- */
/* end drawer styles */
td.button-column {
    text-align: right;
}

th.button-column {
    padding: 4px;
}

div#prePluginRegistrationContainer {
    width: 600px;
}

div#prePluginRegistrationContainer div#info,div#prePluginRegistrationContainer div#preRegBox,div#prePluginRegistrationContainer div#preRegBox>div,div#prePluginRegistrationContainer table#preRegDataTable
    {
    width: 586px;
}

div#prePluginRegistrationContainer div#preRegBox p {
    width: 576px;
}

div#prePluginRegistrationContainer table#preRegDataTable {
    table-layout: fixed;
    word-wrap: break-word;
}

.wordwrapbreak {
    word-wrap: break-word;
}

.overflowwrapbreak {
  width: 95%;
  overflow-wrap: break-word;
}
/* ----------- Student Alert Style ------------------*/
.studentAlert {
    width: 400px;
    background-repeat: no-repeat;
    background-position: 10px 10px;
}

.studentAlert pds-icon {
  fill: #0075db;
}

.alertHeader {
    font-weight: bold;
}

.alertImage {
    float: left;
    padding: 10px;
    width: 30px;
}

.alertContent {
    padding: 10px 0;
    margin-left: 50px;
    max-width: 600px;
    background-color: white;
}

.medical-alert-enhanced {
  max-height: 200px;
  overflow-y: auto;
}

/* ----------- Student Alert Style ------------------*/

/* @end */

/* start coteach styles*/
.termSelector {
    float: left;
    margin: 10px 0;
}

.termDateDisplay {
    float: left;
    margin: 0 0 0 20px;
}

.textResize {
    //width: 75% !important;
}

.columnWidthResize {
    width: 100%;
}

[dir=rtl] .allocationPadding {
    width: 89px;
}

.coteachGridBorder {
    width: 800px;
}
/*end co teach styles*/

/* accessibility styles*/
.screen_readers_only {
    position: absolute;
    left: -99999px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}
/* end accessibility styles*/

/*drag n drop sortable table*/
table.sortable tbody td:first-child {
    background-image: url("../icon-grab-handle.png");
    background-repeat: no-repeat;
    background-position: 2px center;
}
[dir=rtl] table.sortable tbody td:first-child {
     background-position: right 2px center;
 }

table.sortable td:first-child {
    padding-left: 24px;
}

.ui-sortable-helper {
    border-top: 1px solid #CCC;
    background-color: #fff !important;
}

tr.ui-sortable-helper {
    min-height: 2.5em;
    padding: 0;
}

.ui-sortable-placeholder {
    min-height: 2.5em;
    border: @border-style;
}
/*end drag n drop sortable table*/


td p.descriptivenote {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 30px;
}

th.collapsedHeaderPeek span {
    font-size: 80%;
    font-weight: normal;
    color: DarkRed;
    max-width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    float: right;
}

.dualPanelList col:first-child,.dualPanelList col:last-child {
    width: 45%;
}

.dualPanelList select {
    height: 400px;
    width: 100%;
}

/* General Scrolling Display*/
.scrollableDisplay {
    height: 18em;
    width: 400px;
    overflow: auto;
    overflow-x: hidden;
    border: 1px solid  @border-color;
    background-color: #FFF;
    display: inline-block;
}

.scrollableDisplay#addRoleDiv {
    width: 99%;
}

#addSchoolContainer, #addRoleContainer {
    margin: 0 1px;
}

.scrollableDisplay span {
    display: block;
    width: 100%;
    float: none;
    overflow: hidden;
    white-space: nowrap;
}
.scrollableDisplay#specialPrograms {
    display: block;
}
.scrollableDisplay#massApplySchoolList {
  max-height: 200px;
  width: 448px;
  margin-left: 20px;
  li {
    margin-left: 10px;
    list-style-type: none;
  }
}
.scrollableDisplay label {
    float: none;
    font-weight: normal;
    color: black;
    display: inline-block;
    width: -webkit-calc(~"100% - 35px");
    width: calc(~"100% - 35px");
    max-width: 400px;
    word-wrap: break-word;
    white-space: normal;
}

.scrollableDisplay input[type="checkbox"]+label {
    margin-right: 0;
    width: -webkit-calc(~"100% - 40px");
    width: calc(~"100% - 40px");
    padding-right: 10px;
}

.scrollableDisplay span:nth-child(odd) {
    background-color: @zebra;
}

.scrollableDisplay span:nth-child(even) {
    background-color: #FFF;
}

.scrollableDisplay span input.lastChecked,
table tr input.lastChecked {
    -moz-box-shadow: 3px 3px 3px @light-med-blue, -3px 3px 3px @light-med-blue, 3px -3px 3px
        @light-med-blue, -3px -3px 3px @light-med-blue;
    -webkit-box-shadow: 3px 3px 3px @light-med-blue, -3px 3px 3px @light-med-blue, 3px -3px
        3px @light-med-blue, -3px -3px 3px @light-med-blue;
    box-shadow: 3px 3px 3px @light-med-blue, -3px 3px 3px @light-med-blue, 3px -3px 3px
        @light-med-blue, -3px -3px 3px @light-med-blue;
}

.scrollableDisplay span.selected input.lastChecked,
table tr.selected input.lastChecked {
    -moz-box-shadow: 3px 3px 3px #FFF, -3px 3px 3px #FFF, 3px -3px 3px #FFF,
        -3px -3px 3px #FFF;
    -webkit-box-shadow: 3px 3px 3px #FFF, -3px 3px 3px #FFF, 3px -3px 3px
        #FFF, -3px -3px 3px #FFF;
    box-shadow: 3px 3px 3px #FFF, -3px 3px 3px #FFF, 3px -3px 3px #FFF, -3px
        -3px 3px #FFF;
}

.scrollableDisplay span.selected,
table tr.selected,
table.linkDescList tbody tr.selected:nth-child(odd) > td {
    background: @light-med-blue;
}

.scrollableDisplay span.selected label,
table tr.selected {
    color: white;
}

.scrollableDisplay span.selected label b,
table tr.selected {
    color: white;
}

.scrollableDisplay input[type="checkbox"] {
    float: none;
    margin-top: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
    margin-left: 5px;
}

.scrollableDisplay.unselected input {
    box-shadow: none !important;
    opacity: 0.5;
}

fieldset .scrollableDisplay {
    width: calc(~"100% - 20px");
}

#balanceBy.scrollableDisplay {
    width: 400px;
}

.reorderable {
  opacity: 1;
  &.fadeOut {
    opacity: 0.5;
  }
}

fieldset>div>div.status {
    display: inline-block;
    margin: 0;
}


/* kpi styles - used for quick data */
/* Progress Vidget Styles */
.progress-vidget-box-round {
  width: 100%;
  background-color: white;
  font-weight: bold;
  padding: 0;
  border:#DDD solid 1px;
  border-radius: 5px;
  &>div {
    .flex-display;
    .justify-content(space-between);
    .flex-direction;
    .flex-wrap(nowrap);
  }
  .link-box {
    padding: 10px;
    .flex-wrap(wrap);
    .flex(1);
    span {
      .flex(1);
      .flex-grow(1);
      .flex-shrink(1);
      .flex-basis(auto);
    }
    a {
      margin-left: 10px;
    }
  }
}

.progress-vidget-box-round div:first-child {
    margin-top: 0;
    background-color: #DDD;
    border-radius: 3px 3px 0 0;
}

.progress-vidget-box-round .tile-progress-box {
   /* This will be four times as big as the small item. */
    .flex(3);
    .justify-content(space-around);
    width:75%;
    margin: 12px;
    background-color: #fff;
    padding: 0;
    border-radius: 5px;
    white-space: nowrap;
    text-align: right;
}
.progress-vidget-box-round .tile-progress-box > span {
    display:block;
    height:100%;
    width:0;
    background-color: @dark-blue;
      color: #FFFFFF;
    border-radius: 5px 0 0 5px;
    padding-top: 0;
    min-width: initial;
}

.progress-vidget-box-round .tile-percentage-box {
    .flex(1);
    width:25%!important;
    .align-content(space-around);
    line-height: normal;
    background-color: @dark-blue!important;
    white-space: nowrap;
    font-weight: bold;
    text-align: center;
    color: white;
    padding: 5px 5px 5px 5px;
    vertical-align:middle;
    font-size: 30px;
}
.progress-vidget-box-round .radius-leftonly-round {
    border-radius: 5px 0 0 5px!important;
}
.progress-vidget-box-round .radius-topleftonly-round {
    border-radius: 5px 0 0 0!important;
}
.progress-vidget-container {
    width: 310px;
}
.progress-vidget-content {
    .flex-display();
    .flex(1 1 auto);
}
.progress-vidget-bar {
    .flex(1 1 auto);
}
.progress-vidget-controls {
    .flex-display();
    .flex(0 1 auto);
    .align-items(flex-start);
    line-height: 1;
    a, .radioSlider{
        margin-left: 10px;
    }
    a img {
        background-color: @dark-blue;
        border-radius: 5px;
        width: 18px;
        padding: 3px;
    }
    .radioSlider {
        min-width: 60px;
    }
    &>* {
        .flex-basis(auto);
    }
}
.progress-vidget-mini {
    .flex-display();
    padding: 0;
    .tile-progress-box {
        margin: 0;
        flex: auto;
        background-color: #F5F5F5;
        border: 1px solid #DDD;
        border-radius: 11px;
        span {
            display: inline-block;
            height:100%;
            width:0;
            background-color: @dark-blue;
            color: #FFFFFF;
            border-radius: 11px 0 0 11px;
            min-width: initial;
        }
    }
    .tile-percentage-box {
        align-self: center;
        color: @dark-blue;
        font-weight: bold;
        font-size: 110%;
        margin-left: 5px;
        padding: 0;
        text-align: right;
    }
}
/* End Progress Vidget Styles */

/* Start of tile vidget styles */
/* tiles Style */
/* tile container */
.tiles .tile-container,
.tiles-wide .tile-container,
.tiles-wide-mini .tile-container{
  .flex-display();
  .flex-direction(row);
  .flex-wrap(wrap);
  h2.expanded + & {
    padding-top: 10px;
    > .tile:first-child {
      margin-top: 0;
    }
  }
  .tile-header {
    margin: 0 10px 5px 0;
    h3 {
      border-bottom: none;
      padding: 0;
    }
  }
}
.tiles-wide-mini .tile-container {
  .align-items(flex-start);
}
/* individual tile */
.tile {
  margin-top: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 0;
  padding: 0;
  color: @dark-blue;
  border-radius: 5px;
  border: #DDD solid 1px;
  overflow: hidden;
  &:hover {
    border: @dark-blue solid 1px;
    p.tile-big-text {
      background-color: #B2D0E9;
    }
  }
  &.tile-selected {
    border: @dark-blue solid 1px;
    color: @dark-blue;
    background-color: #B2D0E9;
    p.tile-big-text {
      color:#FFFFFF ;
      background-color: @dark-blue;
    }
    &:hover {
      border: #CCC solid 1px;
    }
  }
  .tiles & {
    .flex(0 0 80px);
    text-align: center;
    .flex-display();
    .flex-direction(column);
    .tile-big-text {
      padding-top: 5px;
    }
    .tile-details {
      width: 100%;
    }
  }
  .tiles-wide & {
    height: auto;
    min-width: 300px;
    width: 48%;
    .flex-grow();
    .flex-shrink(1);
    .flex-display();
    .flex-direction(row);
    p {
      padding: 0 5px;
    }
    .tile-big-text {
      .flex(0 0 3.5em);
      font-size: 30px;
      margin-bottom: 0;
      width: 4em;
      border-radius: @border-radius-med 0 0 @border-radius-med;
      position: relative;
      display: inline-block;
      text-align: center;
    }
    .tile-details {
      width: -webkit-calc(~"100% - 8.5em");
      width: calc(~"100% - 8.5em");
      .tile-small-text {
        text-align: left;
      }
    }
  }
  .tiles-wide-mini & {
    .flex-display();
    .flex-direction(row);
    .flex(0 0 auto);
    margin: 0 10px 5px 0;
    p {
      width: auto;
      line-height: 1;
      padding: 0;
    }
    .tile-big-text {
      .flex(0 0 auto);
      font-size: 125%;
      margin-bottom: 0;
      width: auto;
      border-radius: 5px 0 0 5px;
      position: relative;
      text-align: center;
      padding: 2px 5px 0 5px;
    }
    .tile-details {
      .flex-direction(row);
      padding-top: 2px;
      p {
        font-size: 100%;
        text-align: left;
        padding-left: 5px;
        &.tile-small-text {
          padding-right: 5px;
        }
      }
    }
  }
  p {
    margin: 0;
    width: calc(~"100% - 10px");
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
    white-space: nowrap;
    font-weight: bold;
    padding: 0 5px 5px;
    min-width: 0;
  }
  .tile-big-text {
    margin-top: 0;
    margin-bottom: 5px;
    background-color: #DDD;
    border-radius: 3px 3px 0 0;
    font-size: 30px;
    .tile-selected & {
      color: white;
      background-color: @dark-blue;
    }
  }
  .tile-small-type-text {
    font-size: 8px;
  }
  .tile-small-text {
    font-size: 12px;
  }
  .tile-details {
    .flex(0 0 auto);
    .justify-content(space-around);
    .flex-display();
    .flex-direction(column);
    div {
      .flex-display();
    }
    &>*:last-of-type {
      bottom: 0;
    }
  }
}
/* End tile vidget styles */

.toggle-button {
  .flex-display;
  .align-items(center);
  .justify-content(center);
  .flex(0 0 20px);
  padding: 0.2em 0.4em;
  color: #FFFFFF;
  background-color: rgba(255,255,255,0.15);
  border-radius: 2px;
  margin:2px;
}

.kpi-container {
  .flex(1);
  .flex-grow(0);
  .flex-shrink(0);
  .flex-basis(auto);
  border: @border-style;
  border-radius: @border-radius-med;
  margin: 0 20px 10px;
  & .flex-none {
    .flex(none) !important;
  }
  .kpi-header {
    .flex-display;
    .flex-wrap(wrap);
    margin-top: 0;
    padding: 0;
    border-bottom: 1px solid @border-color;
    &>* {
      margin: 0;
      padding-top: 5px;
      .flex(1);
      .flex-grow(1);
      .flex-shrink(0);
      .flex-basis(auto);
    }
    h2, h3 {
      border-bottom: none;
      padding-left: 10px;
      button {
        margin: inherit;
        padding: 3px;
        top: -2px;
      }
    }
  }
  .button-row {
    margin: 10px 10px 10px 0;
  }
  .header-bar {
     border-radius: 3px 3px 0 0;
	 background-color: @med-blue;
    background-position: 12px center;
    color: #FFF;
	  a {
		max-width:20px;
		margin:2px;
    }
	  &.collapsed {
	 border-bottom: none;
      border-radius: 3px;
    }
  }
  .heading-text {
    .flex(0 0 auto);
    font-weight: bold;
  }
  .kpi-control {
    margin-left: 5px;
    margin-right: 10px;
    .flex-display;
    .justify-content(flex-end);
    .flex-wrap(wrap);
    .flex-shrink(1);
    &.kpi-row {
      padding-top: 10px;
    }
    >*, >* span {
      .flex(1);
      .flex-grow(0);
      .flex-shrink(0);
      .flex-basis(auto);
      margin-left: 10px;
      margin-bottom: 5px;
    }
    button {
      margin: 0 0 0 5px;
      vertical-align: top;
    }
    label, input {
      vertical-align: baseline;
    }
  }
  .kpi-content {
    .flex-display;
    .justify-content(space-between);
    .flex-wrap(nowrap);
    margin: 0;
    padding: 0;
    .kpi-container {
      margin: 0;
      margin-bottom: 0;
      padding: 10px;
      vertical-align: top;
      border: none;
      border-left: solid 1px #ccc;
      border-radius: 0;
      &:first-child {
        border-left: none;
      }
      .kpi-header {
        padding: 0;
        h2, h3 {
          padding-left: 0;
        }
      }
      .kpi-control {
        margin: 0;
      }
      .button-row {
        margin: 0;
      }
    }
    .kpi-header {
      margin: 0;
      margin-bottom: 5px;
      padding-right: 5px;
      border-bottom: none;
    }
    h2, h3 {
      margin: 0;
    }
    > div {
      .flex(1);
      &.progress-vidget-header {
        .flex(0 1 auto);
        margin: 0 10px 0 0;
        h3 {
          border-bottom: none;
          padding: 0;
        }
      }
    }
    .button-row {
      margin: 10px;
    }
    .feedback-confirm, .feedback-caution, .feedback-alert, .feedback-error, .feedback-note, .feedback-info {
      margin: 0;
    }
  }
  .kpi-footer {
    border-top: 1px solid @border-color;
    margin: 0;
    padding: 0 10px;
  }
  .prev, .next {
    display: inline-block;
    position: relative;
    margin-bottom: 10px;
    .button-style;
    em {
      background-position: center;
      background-repeat: no-repeat;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 35px;
      left: 5px;
    }
    &:hover {
      .button-style-hover;
    }
  }
  .prev em {
    background-image: url("../img/arrow-left-wht.svg");
  }
  .next em{
    background-image: url("../img/arrow-right-wht.svg");
  }
  .page-indicator {
    text-align: center;
    span {
      width: 10px;
      height: 10px;
      margin-right: 5px;
      border-radius: 5px;
      display: inline-block;
      background-color: @light-grey;
      &.active {
        background-color: @med-blue;
      }
    }
  }
}
/* kpi styles - END */

/* carousel */
[data-pss-carousel] {
    .carousel-row {
        .align-content(flex-start);
        .align-items();
        .flex-display;
        .justify-content(space-between);
        margin-left: 10px;
        margin-right: 10px;
        width: calc(~"100% - 20px");
        .tile {
            outline: none;
        }
    }
    .carousel-items {
        .flex(1 1 auto);
        max-height: 92px;
          min-height: 35px;
        overflow: hidden;
    }
    .carousel-item, .items div {
        display: inline-block;
    }
    .prev, .next {
        .flex(0 0 10px);
        border-radius: 5px;
        display: inline-block;
        margin: 0 0 10px 0;
        vertical-align: middle;
        em {
            left: 5px;
            position: absolute;
            top: calc(~"50% - 8px");
        }
    }
    .prev {
        margin-right: 10px;
    }
    .dots > a {
        background: @light-med-grey;
        border-radius: @border-radius-circle;
        cursor: pointer;
        display: inline-block;
        height: 13px;
        margin: 0 10px;
        width: 13px;
    }
    .dots > a.active {
        background: @med-blue;
    }
}

/* Media Queries for reformating content for different platforms */
/* from bootstrap templates */
@media ( min-width : 1200px) { /* Large Desktop */
}

@media ( min-width : 768px) and (max-width: 979px) {
    /* Portrait tablet to landscape and desktop */
}

@media ( max-width : 767px) { /* Landscape phone to portrait tablet */
}

@media ( min-width : 481px) { /* Portrait tablet to landscape and desktop */
    .rightSideDrawerPanel .content, .rightSideDrawerPanel.buttonless .content, .rightSideDrawerPanel h1 {
        width: 500px;
    }
}

/* Landscape phones and down */
@media only screen and (max-width: 480px) {

    .responsive #header {
        height: auto;
        display: flex;
        flex-direction: column-reverse;
    }

    .responsive #tools {
        position: unset;
    }
    .yui-dt {
        overflow: hidden;
        overflow-x: auto;
    }
    .responsive .rightSideDrawerPanel .content {
        min-width: 0;
    }
    .responsive .rightSideDrawerPanel.buttonless .content {
        width: 350px;
    }
    .responsive .rightSideDrawerPanel h1 span {
        left: 78%;
        position: absolute;
    }
    .responsive #btnNoNav {
        width: 16px;
        height: 32px;
        top: 16px;
    }
    .responsive #btnContMax {
        width: 32px;
        height: 16px;
        left: 174px;
    }
    .responsive .uiNoNav #btnContMax {
        width: 32px;
        height: 16px;
        left: 0px;
    }
    .responsive #tools a {
        margin: 0 3px;
    }
    .responsive #tools li {
        text-align: right;
        margin-bottom: 2px;
        margin-right: 2px;
        border-radius: @border-radius-med;
    }
    .responsive #tools2 li {
        width: 20%;
    }
    .responsive #tools2 {
        left: 250px;
        right: 0px;
    }
    .responsive #tools {
            padding: 2px 0 0 0;
        }
    .responsive #tools li:before {
        content: "";
    }
    .responsive #content {
        position: relative;
    }
    .responsive #nav-main {
        position: absolute;
        z-index: 10;
        background-color: #F2F6FA;
        top: 0;
        bottom: 0;
        height: 100%;
        border-right: 1px solid  @border-color;
    }
    .responsive #content-main {
        margin-left: 6px;
    }
    .responsive #btnNoNav {
        z-index: 11;
        left: 174px;
    }
    .responsive .uiNoNav #btnNoNav {
        left: -1px;
    }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    #sectionOwnerGridContainer table.ui-jqgrid-htable,#sectionTeacherGridContainer table.ui-jqgrid-htable
        {
        border-right: 2px solid #CCC;
    }
}

[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none !important;
}

.categoriesTable {
    table-layout: fixed;
}
.categoriesTable col:first-child {
    width: 30%;
}
.categoriesTable col:last-child {
    width: 100px;
}
.categoriesDialogTextArea {
    max-width: 400px;
    white-space: pre-wrap;
}

.waiversTable {
    table-layout: fixed;
}
.waiversTable col:first-child {
    width: 20%;
}
.waiversTable col:last-child {
    width: 100px;
}

table#autoCalendarFlags td {
    text-align: center;
}

td.hasComment, th.hasComment {
    background-repeat: no-repeat !important;
    background-position: calc(~"100% - 5px") 5px !important;
    background-position: -webkit-calc(~"100% - 5px") 5px !important;
    background-size: auto 16px !important;
}

[dir=rtl] th.hasComment {
  background-position: left 5px top 5px !important;
}

[dir=rtl] td.hasComment {
  background-position: left 5px top 5px !important;
}

td.hasComment {
    background-image: url('../img/icon-comments-16x16.svg') !important;
}
th.hasComment {
    background-image: url('../img/icon-comments-16x16-grey.svg') !important;
    padding-right: 22px !important;
}

td.hasDetails {
  background-repeat: no-repeat !important;
  background-position: 100% 0 !important;
  background-size: auto 20px !important;
  background-image: url('../img/notes-admin-30x30.svg') !important;
  padding: 0 20px !important;
}
[dir=rtl] td.hasDetails {
            background-position: 0% 3px !important;
          }

td.hasDescription {
  background-repeat: no-repeat !important;
  background-position: 100% 3px !important;
  background-size: auto 20px !important;
  background-image: url('../img/info-inverse-admin-30x30.svg') !important;
  padding-right: 20px !important;
}
[dir=rtl] td.hasDescription {
           background-position: 0% 3px !important;
         }
td.hasWeight .ps-icon.tt-calculator {
  position: absolute;
  top: 0;
  left: 0;
}

.strikeout {
  text-decoration: line-through;
}

.uses-comment td, .uses-comment th:not(:first-child) {
  padding-left: 22px;
  padding-right: 22px;
}

div.reorderWidget {
    display: inline-block;
    width: 50px;

    button {
        height: 20px;
        width: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 10px;

        &.up {
            background-image: url('../img/arrow-up-wht.svg');
        }

        &.down {
            background-image: url('../img/arrow-down-wht.svg');
        }
    }
}
.tt-collected,
.tt-late,
.tt-missing,
.tt-exempt,
.tt-excluded,
.tt-absent,
.tt-incomplete,
.tt-standards-white,
.tt-standards,
.tt-info,
.tt-comment,
.tt-calculator{
    height: 20px;
    width: 20px;
    -webkit-background-size: 20px;
    background-size: 20px;
    display: inline-block;
    vertical-align: bottom;
    &.mini {
        height: 16px;
        width: 16px;
        -webkit-background-size: 16px;
        background-size: 16px;
    }
}

.tt-collected {
    background-image: url('../img/collected-inverse-green-30x30.svg') !important;
}
.tt-late {
    background-image: url('../img/late-inverse-red-30x30.svg') !important;
}
.tt-missing {
    background-image: url('../img/missing-inverse-orange-30x30.svg') !important;
}
.tt-exempt {
    background-image: url('../img/exempt-inverse-purple-30x30.svg') !important;
}
.tt-excluded {
    background-image: url('../img/excluded-inverse-orange-30x30.svg') !important;
}
.tt-absent {
    background-image: url('../img/absent-inverse-green-30x30.svg') !important;
}
.tt-incomplete {
    background-image: url('../img/incomplete-inverse-blue-30x30.svg') !important;
}
.tt-standards-white {
    background-image: url('../img/standard-final-grade-white-30x30.svg') !important;
}
.tt-standards {
    background-image: url('../img/standard-grade-category-30x30.svg') !important;
}
.tt-info {
  background-image: url('../img/info-inverse-admin-30x30.svg') !important;
}
.tt-comment {
  background-image: url('../img/notes-admin-30x30.svg') !important;
}
.tt-calculator {
  background-image: url('../img/calculator-black.svg') !important;
}

/* Begin Category Color Chip Styles */
.category-color-chip {
  display: inline-block;
  white-space: nowrap;
}

.color-chip {
  height: 25px;
  width: 25px;
  display: inline-block;
  vertical-align: middle;
  &.color-1 {
    background-color: @category-blue;
  }

  &.color-2 {
    background-color: @category-teal;
  }

  &.color-3 {
    background-color: @category-green;
  }

  &.color-4 {
    background-color: @category-red;
  }

  &.color-5 {
    background-color: @category-orange;
  }

  &.color-6 {
    background-color: @category-tan;
  }

  &.color-7 {
    background-color: @category-purple;
  }

  &.color-8 {
    background-color: @category-grey;
  }
}

.color-chip-text {
  display: inline-block;
}

.category-color-select > .error-message {
  display: inline-block;
  margin-left: 0;
}
/* End Category Color Chip Styles */

/* Begin Category Styles */
a.category-description-collapse {
  margin-left: 10px;
}

a.category-name {
  white-space: nowrap;
}

div.category-school-search {
  margin-top: 2px;
  margin-right: 10px;
  .search-input {
    width: 130px;
  }
}

div#edit-category  {
  h4 {
    margin-right: 20px;
    margin-bottom:0;
  }
  .expandable-row {
    margin-bottom: 10px;
  }
}

.connector {
  height: 36px;
  width: 10px;
  position: absolute;
  bottom: -28px;
  display: inline-block;
  border: 1px solid #000;
  border-left-style: none;
}

.equals {
  position: relative;
  hr {
    float:left;
    width: 295px;
    border: 1px solid #999999;
  }
}

/*Specified exact div to keep background color from being overridden. */
div#included-schools,
div#available-schools{
    background-color: #fff ;
}

div.selection-row {
  &.scroll-table {
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #e4e4e4;
    .table {
      margin-bottom: 0;
      table-layout: fixed;
    }
  }
  .selection-buttons {
    line-height: 56px;
  }
}
/* End Category Styles */

fieldset table {
  margin: 0 10px 20px;
  width: -webkit-calc(~"100% - 20px");
  width: calc(~"100% - 20px");
}

.colorPicker {

  display: inline-block;
  .color {
    height: 100%;
    width: 20px;
    margin: 0;
    &:after {
      content: " ";
      height: 20px;
      width: 20px;
      border-radius: 4px;
      display: block;
    }
    &.color1:after {
      background: @standards-red
    }
    &.color2:after {
      background: @standards-orange
    }
    &.color3:after {
      background: @standards-yellow
    }
    &.color4:after {
      background: @standards-lightgreen
    }
    &.color5:after {
      background: @standards-green
    }
  }
  .colorContainer {
    display: inline-table;
    vertical-align: middle;
    p {
      margin: 5px 0;
    }
  }
}

.wrap {
    white-space: normal;
}

pre.nowrap {
  white-space: pre;
}

.nowrap {
    white-space: nowrap;
}

.ptp-gradebooktype-link {
    display: none;
    &.gradebooktype-2 {
        display: block;
    }
}

input.non-editable-fields[disabled] {
    background-color: LightGrey;
    opacity: 1;
    color: black;
}

.portal-standard-container {
    .no-grades-collected {
        font-style: italic;
          font-size: 90%;
        display: block;
    }
    .standard-grade-link {
        margin: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      label {
        font-weight: normal;
            padding: 0;
      }
    }

  .standard-grade-cell-link {
    cursor: pointer;

    &.hierarchyCell {
      cursor: default;
      border-left: 1px solid transparent;
      &:not(:last-of-type) {
        border-right: 1px solid transparent;
      }
    }
  }
}

// Remove this after the preview release
.preview-stamp:before {
    opacity: 0.05;
    font-family: monospace;
    display: inline-block;
    font-weight:bold;
    font-size: 20vh;
      text-align: center;
    line-height: 1em;
    color: Red;
      width: 90vh;
    content: "Preview Release";
    position: fixed;
    top: calc(~"0% + 30vh");
    left: calc(~"50% - 47vh");
    bottom: auto;
    right: auto;
    border: 20px solid red;
      border-radius: 50px;
    text-transform:uppercase;
    z-index: 0;
    -ms-transform:rotate(-20deg); /* IE 9 */
    -moz-transform:rotate(-20deg); /* Firefox */
    -webkit-transform:rotate(-20deg); /* Safari and Chrome */
    -o-transform:rotate(-20deg); /* Opera */
    transform:rotate(-20deg);
      z-index: -1;
}

#section-readiness-table td {
    max-width:250px;
}

.flex-header {
    display: flex;
    flex-direction: row;
    .flex-item {
        flex: 1 1 auto;
        margin: auto;
        &.button {
            position: initial;
        }
    }
    .no-grow {
        flex-grow: 0;
    }
    .ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.grade-scale-selector {
    vertical-align: middle;
}

.contact-can-access-round-box-div {
    padding: 0 0 0 10px;
}

.formIndent1 {
    margin-left: 22px;
}

.gradeSetupPref-radio-btn {
    float: left;
    width: 50%;
    padding-left: 30px;
}

.gradeSetupPref-label-small {
    padding-left: 22px;
    color:#ccc;
    font-size: small;
}

#right-arrow {
    margin-left: -5px;
}

#calculating-higher-level{
    margin-left: -5px;
}
#level-metric{
    margin-inline-start: -8px;
    [dir=rtl] & {
        margin-inline-start: 0;
    }
}

#course-grade-preference-messages {
    margin-left: 20px;
}

#schools-information, #courses-information {
  h2 {border-bottom: none;}
}

.massapplydisplay {
  width: 100%;
  border: 1px solid @border-color;
  background-color: #FFF;
  display: inline-block;
  margin-bottom: -6px;
  margin-left: -1px;
  table {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    label {
      padding-left: 0;
      font-weight: normal;
    }
  }
}

.massapplyselectorwidget {
  .grid-header {
    margin-left: 6px;
  }
}

#grade-setup-preferences-table {
  img {
    vertical-align: middle;
    height: 20px;
    width: 20px;
  }
  #percent-earned-for-standards-grades {
    margin-left: -4px;
  }
}

#selectedSections {
    margin: inherit;
    font-size: medium;
}

.add-comma::after {
    content: ", ";
}

.inherit-width {
  width: inherit !important;
}

.wrap-text {
    white-space: normal;
}

#securitySchoolGrid {
    max-height: 320px;
    overflow-y: auto;
    margin: 0 1px 10px;
    #schoolCheckBox {
        width: 5% !important;
    }
    #schoolName {
        width: 50% !important;
    }
    #schoolAbbreviation {
        width: 15% !important;
    }
    #schoolId {
        width: 15% !important;
    }
    #schoolCategory {
        width: 15% !important;
    }
}

#courseEditAvailabilitySchoolGrid {
    max-height: 400px;
    overflow-y: auto;
    margin: 0 1px 10px;
}

.leftPadded {
    padding: 0 10px;
    #id_makeavailable, #id_makeunavailable {
        margin: 0 0 0 20px;
    }
}

#newCourseSchoolTableDiv {
    max-height:400px;
    overflow-y:auto;
}

#newCourseYearTableDiv {
    width: 250px;
    max-height: 250px;
    overflow-y: auto;
    margin-left: 10px;
}

#course-year-grid-widget {
   width: 250px;
   max-height: 250px;
   overflow-y: auto;
   margin-left: 0;
   > table {
       margin: 0;
       width: 100%;
   }
}

.psButtonPopup {
  display: none;

  .ps-icon {
    height: 20px;
    width: 20px;
    display: inline-block;
    vertical-align: bottom;
  }
  .ps-icon-gear {
    background-image: url("../img/gear.svg") !important;
    background-position: 0;
  }
}
.ui-selectmenu-menu {
  .ui-menu {
    height:auto;
  }
}
table#auditMasterSwitch .no-border {
    font-weight: bold;
    border: none;
}
table#auditMasterSwitch td:nth-child(1) {
    font-weight: bold;
    width: 35%;
}
table#changeHistoryGridTable td {
    width: 50%;
}

.mode-button-bar {
    background-color: #137ABB;
    color: #FFF;
    display: flex; //Use the Flex mixins that are in the system!!!
    align-content: center;
    height: 50px;
    font-size: 16px;
    justify-content: space-between;
    align-items: center;
    margin: 0 10px 20px 10px;

    p {
        margin: 0 20px;
        font-weight: bold;
        flex-grow: 1;
    }

    button {
        text-decoration: none;
        font-weight: bold;
        padding: 0 25px;
        text-align: center;
        width: auto;
        margin: 0 10px 0 0;
        line-height: 32px;
        border-radius: 4px;

        &[disabled] {
            opacity: 30%;
            cursor: not-allowed;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .button-secondary {
        border: 1px solid #fff;
        background-color: transparent;

        &:hover {

            background-color: #FFF;
            color: #0066A5;
        }

        &[disabled]:hover {
            color: #FFF;
            background-color: transparent;
            text-decoration: none;
        }
        &:focus {
                background-color: #FFF;
                color: #0066A5;
                text-decoration: underline;
                }
    }

    .button-primary {
        border: none;
        color: #137ABB;
        background-color: #FFF;

        &:hover {
            background-color: #0066A5;
            color: #fff;
        }

        &[disabled]:hover {
            color: #137ABB;
            background-color: #FFF;
            text-decoration: none;
        }
        &:focus {
             background-color: #0066A5;
             color: #fff;
             text-decoration: underline;
             }
    }

}

.clickable {
  cursor: pointer;
}
.noteColumn {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.hide-sub-modaccess-link .page-level-mod-access-no-body-match {
    display: none;
}
div#history-data-div table#data-table {
    th#ip-address-header {
        width: 8% !important;
    }
    th#student-name-header, th#contact-name-header, th#staff-name-header, th#who-modified-header {
        width: 10% !important;
    }
    th#date-time-header {
    	width: 14% !important;
    }
    th#student-school-header, th#action-header {
    	width: 6% !important;
    }
    th#view-header {
    	width: 4% !important;
    }
    th#select-all-student-header, th#select-all-staff-header {
    	width: 2% !important;
    }
    td {
        word-wrap: break-word;
    }
}
#view-history-link {
    display: none;
}

#concerndiv {
    width: 300px;
}

#showCommentInAlert {
    float : right;
}

#showConcernInAlert {
    margin-inline-start: 70px;
}

.table-layout-fixed{
    table-layout:fixed;
}
.word-wrap{
    word-wrap:break-word;
}.email-select-dropdown-ui {
    font-size: 100%;
    padding: 3px;
    width: 30%;
}
.use-email-global-id {
    padding-bottom: 5px;
}

.textarea-resize-none {
  resize: none;
}

.textarea-resize-vertical {
  resize: vertical;
}

.teacher-list-grade-scale-course-section {

  #previous-record {
    float: left;
  }

  #next-record {
    margin: 5px 10px 5px 10px;
    float: right;
  }

  .ng-binding {
    font-weight: bold;
  }
}

.teacher-list-grade-scale {

    .teacher-list-grade-scale-item {
      .color-level {
        border-radius: 3px;
      }
    }

    .italic {
      font-style:italic;
      font-weight: bold;
    }

    .color5 {
      background: @standards-green;
    }

    .color4 {
      background: @standards-lightgreen;
    }

    .color3 {
      background: @standards-yellow;
    }

    .color2 {
      background: @standards-orange;
    }

    .color1 {
      background: @standards-red;
    }
}

.fitness-scale-definition {
  .debug-info {
    color: #ccc;
  }
  .fitness-scale-info {
    > div {
      label + * {
        // When the browser screen is narrow, this is to keep the it
        // left-aligned properly with the label.
        margin-left: 10px;
      }
    }
  }
  .fitness-scale-measure {
    label + fieldset {
      border: none;
      > label {
        // inverted scoring field
        display: block;
        white-space: nowrap;
      }
    }
  }
  fieldset.fitness-scales {
    > div {
      // Do not want zebra stripes for these as per design.  Only within the grids.
      background-color: #fff;
      > h2 {
        margin: 0 10px 20px 10px;
      }
      > table.grid {
        width: auto;
        .fitness-code-code,
        .fitness-scale-item-age,
        .fitness-scale-item-measure {
          width: 100px;
        }
        &.fitness-scale-codes {
          .fitness-code-description {
            min-width: 200px;
          }
          .fitness-code-color {
            width: 110px;
          }
          .colorPicker {
            padding-right: 10px;
            white-space: nowrap; // keep the color chip in-line with the label
          }
        }
      }
    }
  }
}

.manage-profile-details {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;

    .manage-profile-picture {
        margin-top: 6px;
        order: 1;
        align-self: flex-start;
        width: 110px;
        max-width: 110px;

        p {
            margin: 0 0 5px 10px;

            img {
                max-height: 130px;
                max-width: 100px;
                margin: 0;
            }
        }
    }
    .manage-profile-info {
        margin-top: 8px;
        order: 2;
        align-self: flex-start;
        flex-shrink: 3;

        table tbody tr {
            height: 36px;

            td {
                height: 28px;

                p {
                    margin: 0 0 0 8px;
                }
            }

            td:first-child {
                text-align: right;
            }
        }
    }
}

.teacher-settings-options {
    margin-left: 10px;

    .feedback-info {
        margin: 10px 10px 10px 0;

        .divPwdRule {
            padding: none;
        }
    }

    .teacher-settings-two-line {
        margin-top: 8px;
        margin-bottom: 12px;
    }

    input {
        width: 400px;
    }

    select {
        width: 400px;
    }

    .teacher-setting-single-line {

        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        margin-top: 18px;

        select {
            width: 50px;
            margin-left: 15px;
        }
    }
}

.fitnessSubtestName {
  min-width: 150px;
}

.schedule-expression {
  margin-left: .25em;
}

.twenty-five-pct-width {
  width: 25%;
}

.chevron {
    background-image: url("../listexpanded.gif");
    background-position: right 6px;
    background-repeat: no-repeat;
    [dir=rtl] & {
        background-position: left 6px;
    }
}

/* UI Example Doc */
.ui-example {
  & pre.code {
    overflow-x: auto;
    white-space: pre;
    background-color: @light-grey;
  }
  & li {
    list-style-type: none;
  }
}

textarea.vaccineInput {
  resize: vertical;
}

/*School Boundary and district Boundary css*/
#map_canvas {
  width: 45%;
  height: 480px;
  border: 1px solid #666;
  margin:0 10px 10px;
  display: inline-block;
}
#drawBoundaryDivId {
  width:50%;
  display: inline-block;
  vertical-align: top;
}
#pointList {
  width: 100%;
  margin-left: 10px;
}
#boundaryEditColumn {
  width: 20%;
}
#txtAddress {
  width: 87%;
}
#searchAddressDivId{
  padding-bottom: 2%;
  padding-left: 2%;
}

.popup-box,
.popup-box-right {
    z-index: 1;
    position: relative;
    width: 0px;
    height: 0px;
    & > div {
        position: absolute;
        background-color: white;
        width: auto;
        border-style: solid;
        border-width: 1px;
        box-shadow: 5px 5px 8px 0px;
    }
}
.popup-box-right {
    left: 100%;
    & > div {
        right: 0;
    }
}
.reports-standard-table {
    display: table;
    margin: 10px 10px 10px;
    width: -webkit-calc(~"100% - 20px");
    width: calc(~"100% - 20px");
}
.reports-standard-table-tr {
    display:table-row;
    background-color: #eee;
}
.reports-standard-table-td {
    display:table-cell;
    padding: 4px 5px;
}
.reports-standard-table-row {
    display:table-row;
}
.reports-standard-table-valign {
    vertical-align:baseline;
    display:table-row;
}
.reports-standard-back-btn {
    text-align: center;
    display:table-row;
}
.reports-standard-table-txt{
    display:table-cell;
    padding: 4px 0px;
}
.student-info-selectscreens{
    display: inline-block;
}
.titleHeading {
    border-bottom: none;
    margin: 0px;
    padding-bottom: 1.5px;
    padding-top: 2px;
    line-height: 1em;
    font-size: 100%;
}
.multitabs-common-label{
    font-weight: inherit;
    line-height: 2em;
    padding: 0;
}.nopadding-label{
    padding: 0;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap; /* added line */
    border: 0;
}
.powerscheduledropmenu {
    max-width: 195px;
}
.scrollable-build-queue {
    max-height: 500px;
    overflow-y: auto;
}
.sticky-table-header {
	position: sticky;
	top: 0;
}
/**
Tooltip style start
*/
.tooltipParent {
    position: relative;
    display: -webkit-box;
}

.tooltipParent .tooltiptext {
    background-color: #ffee69;
    color: #000000;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    visibility: hidden;
    top: -99999px;
    left: -99999px;
    border: 2px solid grey;
    line-height: normal;
    position: absolute;
    z-index: 100;
}

.tooltipParent:hover .tooltiptext {
    visibility: visible;
}

.tooltipwidth {
    min-width: 550px;
}

/**
Tooltip style end
**/

.tooltip-table {
    display: table;
}

.tooltip-row {
    display: table-row;
    white-space: nowrap;
}

.tooltip-cell {
    display: table-cell;
    padding-left: 5px;
}

.tooltip-title-style {
    font-style: italic;
}
.helpPopup{
    float:left;
    margin-top:2px;
}
.popup-menu-item {
    white-space: nowrap;
    cursor: pointer;
    padding: 0 10px;
    text-decoration: none;
    font-weight: bold;
    outline: none;
}

.padding-10 {
    padding: 10px;
}

.label-date{
    padding-inline-start: 5px;
    display: block;
}

.buttonheading {
    position: absolute;
    float: right;
    margin-top: -37px;
    right: 18px;
}

#page-heading-div {
    display:flex;
    margin-bottom:-20px;
    .account-photo {
        margin-block: auto;
    }
}

#page-heading-alerts{
    margin-top:32px;
    margin-left:-20px;
    margin-right:20px;
    display:flex;
    flex:1
}

[dir=rtl] .psteacher #page-heading-alerts{
    margin-inline-start: -10px;
}

#page-heading-alerts span {
    margin-left:auto;
    margin-right: 10px;
}
.marginleft-20 {
    margin-left: 20px;
}
.selectChkBox {
    font-weight: bold;
    font-size:17px;
}
.marginright-20 {
     margin-right: 20px;
}
#collapsibleContainer {
    margin-bottom: 60px;
}
.wrapColumn {
       max-width:100px;
       width:7%;
}

.kpi-container-btn{
    margin: 0 0px 5px 0px !important;
    background-color: #C8EAEA !important;
    color: grey;
    font-weight: bold !important;
}

.kpi-container-btn:hover{
    background-color: #C9E9E9 !important;
    color: black;
}

.tiles-close{
    position: relative;
    & > em {
        position: absolute;
        top: 0;
        right: 0;
    }
}

.fixTableHead {
    overflow-y: auto;
    height: 85vh;
}

.fixTableHead thead th {
    position: sticky;
    top: 0;
}
.fixTableHead tbody {
    overflow:auto;
}
.checkboxContainer {
    width:75px;
}
.selectCVXCodes {
    min-width:305px;
    overflow-x: auto;
    width: 100%;
}
.selectCVXCodes option {
    width: 280%;
}
.cvxSearchText {
    width: 295px;
}

.alignmiddle{
    vertical-align: middle !important;
}

.dpWrapper> .ui-datepicker-trigger {
    margin-left: 5px !important;
}

[dir=rtl] .dpWrapper> .ui-datepicker-trigger {
    margin-inline-end: 10px !important;
}

.mt-5{
    margin-top: 5px !important;
}

.admitSlip-td {
    width: 60px;
    max-width: 60px;
    word-break: break-all;
}

.admitSlip-div {
	width:295px;
}
.admitSlip-body {
	zoom:90%;
}

.admit-slip-header {
    padding: 4px 1px;
}

.admit-slip-table {
    font-size: 7px;
}


.ui-icon-red {
    background-image: url("../../images/img/jquery/ui-icons_cd0a0a_256x240.png");
}

#legacyVisionAndColor .select-read-only {
    opacity: 1.2;
}

.centerContent {
    display: flex;
    justify-content: center;
}

.staffProfile{
   display: flex;
}

.alphaAlignment{
display:inline-block;
}

.table-element-text-align-start {
	text-align: start !important;
}

.table-element-text-align-end {
	text-align: end !important;
}

.table-element-text-align-center {
	text-align: center !important;
}
[dir=rtl] span .text-align-start {
    float : right;
    padding-inline-end: 5px;
}

.padding-end {
    padding-inline-end: 5px;
}
.display-flex {
  display: inline-flex;
}

/* term setup table */
table.term-setup-table {
    tr.term-setup-row {
      display: flex;
      td.term-setup-cell {
          display: flex;
          flex: 100%;
          justify-content: center;
          >div {
              display: flex;
              justify-content: center;
              flex-direction: column;
              >a {
                  display: flex;
                  justify-content: center;
              }
              >div {
                  text-align: center;
                  margin-block: 5px;
              }
          }
      }
    }
}

select.att-singleday {
    position: relative;
    margin-inline-start: 0px;
    top: 0px;
    inset-inline-start: 1px;
    z-index: 10;
    float: left;
}

.vertical-scrollbar {
    overflow-y: auto;
}

input[type="radio"],
input[type="checkbox"] {
  accent-color: #0075ff;
}

.data_no_wrap{
   white-space: nowrap;
}

.no-bottom-border {
    border-bottom: none;
}

.schedule-nav {
padding-bottom:inherit;
padding-inline-start: 21px;
}

#page-heading-alerts {
			>a {
				height: fit-content;
				width: fit-content;
				margin-inline-start: 10px;

				>pds-icon {
					height: 20px;
					width: 20px;
					display: inline-flex;

					&.wallet {
						fill: #3E8D21;
					}

					&.other {
						fill: #C84C09;
					}

					&.medical {
						fill: #CB1010;
					}

					&.birthday {
						fill: #774696;
					}

					&.discipline {
						fill: #0075DB;
					}

					&.guardian {
						fill: #DA2F6B
					}
				}
			}
}

.not-bold {
    font-weight: normal;
}
.alignment {
    margin: 20px;
    margin-inline-end: 129px;
}
[dir=rtl] .students_dropdown_button, .staff_dropdown_button {
		margin: 0 !important;
}

#termAbsTotal a,
#termTarTotal a {
    color: darken(@link-color, 10%);
}

.rcstoggle-teacher-portal {
    margin-right: 7px;
    display: inline-flex;
    position: relative;
    top: 12px;
}

#localeSelect {
    width: 450px;
    margin-inline-start: -8px;
    padding: 5px;
    border-radius: 4px;
    height: 32px;
}

#switchLocaleDiv {
    margin-inline-start: 20px;
}

#switchlocaleitalictext {
    font-size: 12px;
    font-style: italic;
    color: #4D5557;
}

.odor-align {
    margin-top:25px;
}

.odor-checkbox {
    margin-top:4px;
}

.center-align {
	text-align: center;
	display:block;
	width:auto;
}

.halfwidth {
    width : 50%;
}

span.teachers-student-header {
    max-width: 50ch;
    text-align: start;
}

span.health-monitoring-status {
    width : 50%;
}

td.tooltip-width {
    max-width:420px;
}

div.incident-width {
     @media screen and (min-width:1318px) {
         width: 90%;
         }
     @media screen and (max-width:1317px) {
        width: 80%;
        }
}

.name-word-break-all {
    word-break: break-all;
}

select#teacherSelector {
    max-width: 59%;
    text-overflow: ellipsis;
    width: fit-content;
}

li.header-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 58ch;
}

.name-word-break {
    word-break: break-word;
}

body.background-image-none {
    background-image: none;
}

.sub-header #branding-powerschool {
    background-image: none;
}

.sub-header header #branding-powerschool a {
    display: flex;
    align-items: center;
    color: @text-color;
    font-size: 24px;
    line-height: 27px;
    margin-inline-start: 26px;
    font-family: Karla, sans-serif;
}

.sub-header header #branding-powerschool svg {
    width: 28px;
    height: 70px;
}

.sub-header header #branding-powerschool pds-icon {
    margin-inline-start: 4px;
    margin-inline-end: 10px;
}

@media print {
    .sub-header header pds-icon {
    display:inline-block;
    }

    .sub-header header #branding-powerschool pds-icon {
         margin-inline-start: -26px;
         top:-18px;
         position:relative;
    }

    .sub-header header span {
     font-size: 20px;
     letter-spacing: -1.5px;
     word-spacing: -0.75px;
     top:-24px;
     position:relative;
     color: @text-color;
    }
}

@media (max-width: 768px) {
    #display_teacher_menu{
        display: inline-block;
        margin-right: 10px;
    }
    .teacher_menu_options{
        #pds-tippy-scope {
            >[id^=tippy][data-tippy-root].pds-user-menu>.tippy-box.popover-theme.pds-user-menu-theme.pds-user-menu{
                display: inline-block;
            }
        }
    }
}
.error_alignment{
        margin: 10px;
        text-align: start;
        padding-top: 3px;
        padding-bottom: 3px;
    }
.error_alignment ul {
    margin: 3px;
}
.error_alignment ul li::marker {
    content: '';
}

.limited-width-container {
    width: 42%;
    white-space: nowrap;
}

.flexible-input-wrapper {
    flex: 1;
    display: flex;
}

[dir=rtl] .flexible-input-wrapper {
    margin-inline-start: 149px;
    margin-top: -20px;
}

.flexible-input-wrapper + p#start-date-input_error,
.flexible-input-wrapper + p#end-date-input_error {
    margin-inline-start: 147px;
    white-space: nowrap;
}

#student-alerts {
    position: absolute;
    margin-inline-start: 10px;
    display: inline-block;
    z-index: 1000;

			>a {
				height: fit-content;
				width: fit-content;
				margin-inline-start: 5px;

				>pds-icon {
					height: 20px;
					width: 20px;
					display: inline-flex;

					&.wallet {
						fill: #3E8D21;
					}

					&.other {
						fill: #C84C09;
					}

					&.medical {
						fill: #CB1010;
					}

					&.birthday {
						fill: #774696;
					}

					&.discipline {
						fill: #0075DB;
					}

					&.guardian {
						fill: #DA2F6B
					}
				}
			}
}

.inline-margin {
    margin-inline-start: 10px;
}

.incident-alert {
    max-width: 450px;
}

.incident-message {
    max-width: 500px;
}