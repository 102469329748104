.incident-collapsible {
    .expanded {
        background-color: @light-med-blue;
    }
    .collapsed {
        background-color: @light-grey;
    }
}

.feedback-label {
    margin: 0px;
}

.feedback-container {
    background-color: #FFF !important;
}

.feedback-container  textarea {
    max-width: 465px;
    width: 465px;
    height: 90px;
}

.inline-container {
    display: flex;
    align-items: center;
}

.inline-container select,
.inline-container a {
    margin-inline-end: 10px;
}

.feedback-history-comment {
    font-weight: bold;
    line-height: 1.5em;
    word-break: break-word;
}

.feedback-history {
    margin-bottom: 8px;
}

a.red:hover {
    color: #990000;
}